/*****************************************************************************/
export const Player = Object.freeze({
  ZERO_PLAYER: { value: 0, toString: () => "ZERO" },
  WHITE_PLAYER: { value: 1, toString: () => "WHITE" },
  BLACK_PLAYER: { value: -1, toString: () => "BLACK" },

  getNextPlayer: function (from) {
    if (from === Player.WHITE_PLAYER) {
      return Player.BLACK_PLAYER;
    }
    if (from === Player.BLACK_PLAYER) {
      return Player.WHITE_PLAYER;
    }
    return Player.ZERO_PLAYER;
  },
});
/*****************************************************************************/
export default Player;
