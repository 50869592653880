export var RULocaleStringConsts = {
  FINISH_MOVE_STRING: "Завершить ход",
  START_GAME_STRING: "Начать игру",

  GAME_OVER_STRING: "Конец игры",
  AI_MOVE_STRING: "Ход ИИ",

  BLACK_STRING: "Черные: ",
  WHITE_STRING: "Белые: ",

  LEFT_TO_END_STRING: "Остаток: ",
  DICE_SUM_STRING: "  Сумма: ",
  STEPS_DONE_STRING: "  Сделано: ",
  STEPS_LOST_STRING: "  Потеряно: ",

  PLAY_MENU_STRING: "Играть",
  SPECATE_MENU_STRING: "Наблюдать",
  AI_NARDY_STRING: "Нарды с ИИ",

  PLAYER_HUMAN_STRING: "Человек",
  PLAYER_HARD_AI_STRING: "Сложный ИИ",
  PLAYER_MEDIUM_AI_STRING: "Средний ИИ",
  PLAYER_EASY_AI_STRING: "Легкий ИИ",

  AI_TIME_STRING: "Время хода ИИ",
};

export var ENLocaleStringConsts = {
  FINISH_MOVE_STRING: "Complete move",
  START_GAME_STRING: "Start game",

  GAME_OVER_STRING: "Game over",
  AI_MOVE_STRING: "AI move",

  BLACK_STRING: "Black: ",
  WHITE_STRING: "White: ",

  LEFT_TO_END_STRING: "Left: ",
  DICE_SUM_STRING: "  Sum: ",
  STEPS_DONE_STRING: "  Done: ",
  STEPS_LOST_STRING: "  Lost: ",

  PLAY_MENU_STRING: "Play",
  SPECATE_MENU_STRING: "Specate",
  AI_NARDY_STRING: "Nardy with AI",

  PLAYER_HUMAN_STRING: "Human",
  PLAYER_HARD_AI_STRING: "HardAI",
  PLAYER_MEDIUM_AI_STRING: "MediumAI",
  PLAYER_EASY_AI_STRING: "EasyAI",

  AI_TIME_STRING: "AI move time",
};

export var LocaleStringConsts = ENLocaleStringConsts;

export default LocaleStringConsts;
