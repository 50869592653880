import React, { useState, useEffect, useRef } from "react";
import { Circle, Group, Text, Line } from "react-konva";
import { FIELD_SIZE } from "../nardy/nardy";

export const Checker = ({
  data,
  dimensions,
  updatePosition,
  checkerSelected,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e) => {
    checkerSelected(data);
    setIsDragging(true);
    e.target.moveToTop();
  };

  const handleDragEnd = (e) => {
    const newPos = e.target.position();
    updatePosition(data, newPos);
    setIsDragging(false);
  };

  function clamp(val, min, max) {
    return val > max ? max : val < min ? min : val;
  }

  let scale = isDragging ? 1.1 : 1;
  if (data.draggable) scale *= 1.1;

  let front_arrow = data.draggable && Object.keys(data.targets).length !== 0;
  let back_arrow = data.draggable && Object.keys(data.backwards).length !== 0;

  if (data.posIndex >= 12 && !(!data.white && data.posIndex === FIELD_SIZE)) {
    [front_arrow, back_arrow] = [back_arrow, front_arrow];
  }

  let count = data.count ? data.count : null;

  const groupRef = useRef(null);

  useEffect(() => {
    if (groupRef.current !== null) {
      groupRef.current.cache({ pixelRatio: 2, imageSmoothingEnabled: false });
    }
  });

  return (
    <Group
      key={data.id}
      id={data.id}
      ref={groupRef}
      x={data.coord.x}
      y={data.coord.y}
      draggable={data.draggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      dragBoundFunc={(pos) => {
        return {
          x: clamp(pos.x, data.radius, dimensions.width - data.radius),
          y: clamp(pos.y, data.radius, dimensions.height - data.radius),
        };
      }}
      _useStrictMode
    >
      <Circle
        radius={data.radius}
        opacity={1.0}
        shadowColor="black"
        shadowBlur={7}
        shadowOpacity={0.4}
        shadowOffsetX={isDragging ? 5 : 1}
        shadowOffsetY={isDragging ? 5 : 1}
        scaleX={scale}
        scaleY={scale}
        fillLinearGradientStartPoint={{ x: 0, y: 0 }}
        fillLinearGradientEndPoint={{ x: 50, y: 50 }}
        fillLinearGradientColorStops={[
          0,
          data.color,
          1,
          data.white ? "gray" : "black",
        ]}
        stroke={data.selected ? "lightgreen" : "black"}
        strokeWidth={data.selected ? 3 : 0.5}
      />
      {back_arrow ? (
        <Line
          points={[
            data.radius * 0.1,
            -data.radius * 0.9,
            data.radius * 0.9,
            0,
            data.radius * 0.1,
            data.radius * 0.9,
          ]}
          stroke={data.white ? "black" : "white"}
          strokeWidth={1}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
          tension={0.7}
        ></Line>
      ) : null}
      {front_arrow ? (
        <Line
          points={[
            -data.radius * 0.1,
            -data.radius * 0.9,
            -data.radius * 0.9,
            0,
            -data.radius * 0.1,
            data.radius * 0.9,
          ]}
          stroke={data.white ? "black" : "white"}
          strokeWidth={1}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
          tension={0.7}
        ></Line>
      ) : null}
      {count !== 0 ? (
        <Group>
          <Text
            text={count}
            x={-19 + (count < 10 ? 7.5 : -2.5)}
            y={-15}
            fontSize={36}
            fill={data.white ? "black" : "white"}
          />
        </Group>
      ) : null}
    </Group>
  );
};

export default Checker;
