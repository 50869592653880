import LocaleStringConsts from "./components/LocaleStringConsts";

import "./App.css";
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  ButtonGroup,
  Paper,
  Menu,
  MenuItem,
  Box,
  Container,
  Link,
} from "@mui/material";

import background_image from "./background_image.jpg";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import styled from "styled-components";
import GameBoard from "./components/GameBoard";

import { GameMatch } from "./nardy/GameMatch";
import { NardsPlayers } from "./nardy/aiplayer";

// import { chooseBestMoveIndexNardyAI } from "./nardy/WebEasy";

const theme = createTheme({
  palette: {
    primary: {
      main: "#393939",
    },
    secondary: {
      main: "#A6A6A6",
    },
  },
});

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

let game_match = GameMatch.newGame(
  LocaleStringConsts.PLAYER_HUMAN_STRING,
  NardsPlayers.HUMAN,
  LocaleStringConsts.PLAYER_HARD_AI_STRING,
  NardsPlayers.HARD
);

const MIN_AI_TIMEOUT = 100;
const MAX_AI_TIMEOUT = 3000;
const DEFAULT_AI_TIMEOUT = 1000;

function App() {
  const [gameState, setGameState] = useState(game_match.getData());

  const [aiTimeOut, setAiTimeOut] = useState(DEFAULT_AI_TIMEOUT);

  useEffect(() => {
    const data = game_match.getData();
    setGameState(data);
  }, []);

  const handleNewGame = (player1_name, player1, player2_name, player2) => {
    setAnchorPlayMenu(null);
    setAnchorSpecateMenu(null);
    game_match = GameMatch.newGame(
      player1_name,
      player1,
      player2_name,
      player2
    );

    const data = game_match.getData();
    setGameState(data);
  };

  useEffect(() => {
    if (
      gameState.auto_move_run !== true &&
      gameState.current_player_is_ai &&
      !gameState.game_over &&
      game_match.current_dice !== null
    ) {
      gameState.auto_move_run = true;

      setTimeout(() => {
        if (
          gameState.auto_move_run &&
          game_match.current_dice !== null &&
          game_match.current_possible_moves.length > 0
        ) {
          // chooseBestMoveIndexNardyAI({
          //   player: game_match.getNardsPlayer(game_match.current_player),
          //   game: game_match,
          //   possible_moves: game_match.current_possible_moves,
          // }).then((chosen_moves_web) => {
          //   console.log("chosen_moves_web = " + chosen_moves_web);

          let chosen_moves = game_match
            .getNardsPlayer(game_match.current_player)
            .chooseMoves(game_match, game_match.current_possible_moves);

          // console.log("chosen_moves = " + chosen_moves);

          // if (chosen_moves_web !== chosen_moves) {
          //   console.log("Not the same");
          //   throw Error("Not the same");
          // } else {
          //   console.log("The same");
          // }

          if (game_match.current_dice !== null)
            game_match.doMoves(chosen_moves.moves);

          const data = game_match.getData();
          setGameState(data);
          // });
        } else {
          if (game_match.current_dice !== null) game_match.doMovesByIndex(0);
          const data = game_match.getData();
          setGameState(data);
        }
      }, aiTimeOut);
    }
  }, [gameState, aiTimeOut]);

  // if (!gameState) {
  //   return <div>Loading...</div>;
  // }

  const doMoves = (moves) => {
    game_match.doMoves(moves);
    const data = game_match.getData();
    setGameState(data);
  };

  const choosePlayersSide = () => {
    game_match.choosePlayersSides();
    game_match.turnOnAI();

    const data = game_match.getData();
    setGameState(data);
  };

  const changeAIMoveTime = (delta) => {
    let new_aiTimeOut = aiTimeOut + delta;
    if (new_aiTimeOut >= MIN_AI_TIMEOUT && new_aiTimeOut <= MAX_AI_TIMEOUT) {
      setAiTimeOut(new_aiTimeOut);
    }
  };

  const [anchorPlayMenu, setAnchorPlayMenu] = useState(null);
  const openPlayMenu = Boolean(anchorPlayMenu);
  const handlePlayMenuArchorClick = (event) => {
    setAnchorPlayMenu(event.currentTarget);
  };
  const handleClosePlayMenu = () => {
    setAnchorPlayMenu(null);
  };

  const [anchorSpecateMenu, setAnchorSpecateMenu] = useState(null);
  const openSpecateMenu = Boolean(anchorSpecateMenu);
  const handleSpecateMenuArchorClick = (event) => {
    setAnchorSpecateMenu(event.currentTarget);
  };
  const handleCloseSpecateMenu = () => {
    setAnchorSpecateMenu(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <AppBar
          position="static"
          sx={{ "& .MuiToolbar-root": { paddingLeft: 1, paddingRight: 1 } }}
        >
          <Toolbar sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <img src={"/favicon.ico"} className="App-logo" alt="logo" />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, paddingLeft: 1 }}
            >
              {LocaleStringConsts.AI_NARDY_STRING}
            </Typography>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button
                id="basic-button"
                variant="contained"
                color="primary"
                aria-controls={openPlayMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openPlayMenu ? "true" : undefined}
                onClick={handlePlayMenuArchorClick}
                sx={{ m: 1, p: 1 }}
              >
                <Typography variant="h7" component="div">
                  {LocaleStringConsts.PLAY_MENU_STRING}
                </Typography>
              </Button>
              <Button
                id="basic-button"
                variant="contained"
                color="primary"
                aria-controls={openSpecateMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSpecateMenu ? "true" : undefined}
                onClick={handleSpecateMenuArchorClick}
                sx={{ m: 1, p: 1 }}
              >
                <Typography variant="h7" component="div">
                  {LocaleStringConsts.SPECATE_MENU_STRING}
                </Typography>
              </Button>
            </ButtonGroup>

            <Menu
              id="basic-menu"
              anchorEl={anchorPlayMenu}
              open={openPlayMenu}
              onClose={handleClosePlayMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_HUMAN_STRING,
                    NardsPlayers.HUMAN,
                    LocaleStringConsts.PLAYER_HARD_AI_STRING,
                    NardsPlayers.HARD
                  )
                }
              >
                {LocaleStringConsts.PLAYER_HUMAN_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_HARD_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_HUMAN_STRING,
                    NardsPlayers.HUMAN,
                    LocaleStringConsts.PLAYER_MEDIUM_AI_STRING,
                    NardsPlayers.MEDIUM
                  )
                }
              >
                {LocaleStringConsts.PLAYER_HUMAN_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_MEDIUM_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_HUMAN_STRING,
                    NardsPlayers.HUMAN,
                    LocaleStringConsts.PLAYER_EASY_AI_STRING,
                    NardsPlayers.EASY
                  )
                }
              >
                {LocaleStringConsts.PLAYER_HUMAN_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_EASY_AI_STRING}
              </MenuItem>
            </Menu>

            <Menu
              id="basic-menu"
              anchorEl={anchorSpecateMenu}
              open={openSpecateMenu}
              onClose={handleCloseSpecateMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_HARD_AI_STRING,
                    NardsPlayers.HARD,
                    LocaleStringConsts.PLAYER_HARD_AI_STRING,
                    NardsPlayers.HARD
                  )
                }
              >
                {LocaleStringConsts.PLAYER_HARD_AI_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_HARD_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_HARD_AI_STRING,
                    NardsPlayers.HARD,
                    LocaleStringConsts.PLAYER_MEDIUM_AI_STRING,
                    NardsPlayers.MEDIUM
                  )
                }
              >
                {LocaleStringConsts.PLAYER_HARD_AI_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_MEDIUM_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_HARD_AI_STRING,
                    NardsPlayers.HARD,
                    LocaleStringConsts.PLAYER_EASY_AI_STRING,
                    NardsPlayers.EASY
                  )
                }
              >
                {LocaleStringConsts.PLAYER_HARD_AI_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_EASY_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_MEDIUM_AI_STRING,
                    NardsPlayers.MEDIUM,
                    LocaleStringConsts.PLAYER_MEDIUM_AI_STRING,
                    NardsPlayers.MEDIUM
                  )
                }
              >
                {LocaleStringConsts.PLAYER_MEDIUM_AI_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_MEDIUM_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_MEDIUM_AI_STRING,
                    NardsPlayers.MEDIUM,
                    LocaleStringConsts.PLAYER_EASY_AI_STRING,
                    NardsPlayers.EASY
                  )
                }
              >
                {LocaleStringConsts.PLAYER_MEDIUM_AI_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_EASY_AI_STRING}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleNewGame(
                    LocaleStringConsts.PLAYER_EASY_AI_STRING,
                    NardsPlayers.EASY,
                    LocaleStringConsts.PLAYER_EASY_AI_STRING,
                    NardsPlayers.EASY
                  )
                }
              >
                {LocaleStringConsts.PLAYER_EASY_AI_STRING} vs{" "}
                {LocaleStringConsts.PLAYER_EASY_AI_STRING}
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Paper
          square
          sx={{
            backgroundImage: `url(${background_image})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        >
          <GameBoard
            currentGameState={gameState}
            doMoves={doMoves}
            choosePlayersSide={choosePlayersSide}
            aiTimeOut={aiTimeOut}
            changeAIMoveTime={changeAIMoveTime}
          />
        </Paper>
        <Box>
          <Container maxWidth="sm">
            <Typography align="center">
              {"Copyright © "}
              <Link color="inherit" href="/">
                GSE
              </Link>{" "}
              {new Date().getFullYear()}
            </Typography>
          </Container>
        </Box>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
