import { Text, Group, Rect } from "react-konva";

export const FullBoardMessage = ({ message, onClick = null }) => {
  return (
    <Group>
      <Rect
        x={65}
        y={65}
        width={740}
        height={670}
        fill={"black"}
        opacity={0.0}
      />
      <Rect
        x={870 / 2 - 80}
        y={347.5}
        width={160}
        height={105}
        fill={"black"}
        opacity={0.7}
        cornerRadius={10}
      />
      <Text
        x={870 / 2 - 80}
        y={347.5}
        width={160}
        height={105}
        text={message}
        fontSize={30}
        fill={"white"}
        align={"center"}
        verticalAlign={"middle"}
        onClick={onClick}
        onTap={onClick}
      />
    </Group>
  );
};

export default FullBoardMessage;
