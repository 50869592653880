export const MediumAIPlayer_data = {
  A: [
    0.0024256579819875307, -0.0014025609154590894, -0.005172342530949427,
    -0.0021742864652100017, -0.00517044643228801, 0.002630276794601009,
    0.006886438235504911, 2.355448100991398e-4, -0.0015465810208895683,
    -0.0017264437846945477, -0.003707466253026817, -7.841588502829612e-4,
    -0.014378596473633428, -0.003133974712635811, 0.003835553832442955,
    -0.0014811796738468657, -4.011563558514168e-4, -0.004597280563360033,
    -7.620450013107601e-4, 0.002548160246110742, -0.005229701631063937,
    -0.0021451556887880424, 0.0014657209866944035, 0.004030522433779467,
    0.001992566798238675, 0.0040721252924734806, -0.0023889334463464253,
    4.259462083099371e-4, -0.004780915922070025, 2.009215893232433e-4,
    -0.003053694117862901, -0.005558626419616329, 0.0021038701583712665,
    1.566500796921145e-4, -0.008872638240627211, 2.6966976030018457e-4,
    0.001393680320529075, -0.002064329344099182, 0.0012811731459286323,
    0.0034528081992663663, -0.0017964951091303395, 0.003036362523700766,
    -0.002085207003497273, -2.64123735974586e-4, 0.010665834964159392,
    -0.004234074212194901, -0.004248249508592317, 0.0013882437667565957,
    -0.0017391144784012107, 2.832981580470059e-4, 0.004131876905572535,
    0.0011964837320937872, 0.004218007429951527, -0.0012021664754227161,
    -0.002152530975972312, -0.005229949241589756, -0.005304036772621886,
    -0.002971771631876223, 0.0045561444361136814, 0.006398157635147391,
    0.003599042877038035, -0.01221115742287203, 0.005749662649404935,
    -0.0017331348327349308, 0.001559857801423181, -9.12410490217159e-5,
    0.004821073308216723, 0.010877014523800401, 0.0021672383383103044,
    0.0020040620196089707, -6.604299132135511e-4, 6.181580922805957e-4,
    -0.0030709401669981964, -0.007953670674013265, -0.003967141337789324,
    5.976347203076719e-4, 0.0011710402918991918, 0.002444065131090343,
    -0.001030162957297436, 1.993390507429706e-4, -6.041657317936206e-4,
    -4.651492830665616e-4, -0.006504280554659852, -0.0018254752474358951,
    0.004797685830998898, 0.0010483837877251017, -0.013863127120796615,
    0.0017796310147323072, -0.004555912835941634, -0.002516054502438526,
    -0.003425344851723322, 7.932624022912367e-4, 6.603377143611096e-4,
    0.0010926150859637822, -0.0044851221120953124, 0.005246747868214698,
    -0.0021894281582666153, -0.001471058285018983, -2.2462660058311028e-4,
    -9.905553745711275e-4, -0.004593011233065927, 4.4741931886424675e-4,
    0.007932022938053257, 0.0031909478969317394, 4.825704728228632e-4,
    -0.0018536505374776168, -0.005995633738614479, 0.0018793752982845134,
    2.0548275095270762e-4, 5.074610202601218e-4, -0.0010344672406498133,
    0.005022036750035659, 0.0036247526103130503, -0.0033119586249757554,
    0.0027411391039801774, 9.528345341993195e-4, -0.002354244851205247,
    2.932465695156752e-4, -0.0011253292362236565, -4.559636591844186e-4,
  ],
  B: [
    0.0014316585046035678, 0.0021519573660047893, -0.004075078002228384,
    3.4657857958881103e-4, -2.0494810165915325e-4, 0.0030076064855900034,
    0.009423060405004402, -0.002623386049040742, 0.003282978537950292,
    0.0022182420986882976, -0.004047313646593463, -8.769543336397663e-4,
    -0.002193614869514679, -0.0010099151725187676, -0.002189566231565601,
    -5.366932707615109e-5, 0.0013896149465726802, -0.0031435132444755983,
    9.443069000806326e-4, 0.004307991699192688, -0.007402961169209911,
    0.002091739463792659, 0.003908704547918309, -0.0013300561305801403,
    -3.2070398611977913e-4, -0.0031337573641669683, -0.0010833543626553842,
    0.005253182363267467, 0.002260039410959922, 7.207551378476396e-4,
    3.7130365646502526e-4, -0.002028185308385934, 0.0026607208447562464,
    -2.947371632666482e-4, -9.801769466543956e-4, -0.0036046894808896925,
    0.003211499746511584, -0.001505521294258604, -0.001116535515403368,
    -2.753818212647565e-4, 9.53824024974015e-4, 0.0012868480502457235,
    0.0027370125811319364, -0.00213116557824225, 0.0021452778711667586,
    6.201742956657334e-4, 9.734185580988612e-4, 0.0029545585479443305,
    -7.603971079173336e-4, -0.004900967483013113, 0.003952102327938113,
    -1.8524003693429653e-4, 0.004684053120728849, -0.0013017621093944142,
    0.0035057120275680405, -0.005392488464658681, 0.0038469547161711993,
    0.002093219834743816, 3.2971712076340295e-4, 0.002908486733584672,
    -0.0038696038693291314, -0.005580253385331718, -0.0017479277067358402,
    -4.274121764526879e-4, 0.002145049386797905, 0.00801759002117007,
    -0.003224623551796809, 0.0015796339381269739, 8.749359374270243e-4,
    -0.0013287802598803316, -0.004856070223635161, -0.0016769980761914749,
    -0.0016726707556078504, -0.004270058617986823, -5.937888190826292e-4,
    -0.0021152862414346866, -0.001593015319029497, 0.0012721464100513727,
    0.001805424401734467, 0.002115859603318962, 0.0023040851614732835,
    -6.529033467233989e-4, 5.694190304312094e-4, 8.5634065692868e-4,
    0.0011572366778385933, 0.0031036182750647547, -0.004237019983175333,
    0.0028705445673043216, -0.0020159777902887784, 0.0013891313632827685,
    -0.0037388962966966094, -0.002104792199715941, 1.3900088130532032e-5,
    -0.0020992217439076847, 0.002005288075830702, 0.002439350050634391,
    0.0013160533305200256, 0.0011351674980144895, -9.497070422666979e-4,
    0.0034051378093964335, -0.0032932704229156756, -0.0010386272491391627,
    0.005715194785898794, 0.0011612068801991177, -5.966848308553436e-4,
    -0.006864364640892285, -9.271587466100278e-4, -0.0026646086641133574,
    -0.007345108244871501, -0.002259704140839342, -0.0023463524383628576,
    0.004772316174387137, -0.0031769143834788306, -0.002213700990058333,
    -0.0010712478488665517, 0.0010781303590890758, -0.0029618448258523934,
    -0.004214641964141702, -5.619556723195997e-4, -0.004842836742132784,
  ],
  HIDE_LEN: 120,
  INPUT_LEN: 24,
  S: [
    [
      -0.0021977834705468892, -0.005995622243307292, 3.251720942826429e-4,
      0.0017274883107939896, -0.0016191820787591134, -0.0012637699925169919,
      0.001385899602197928, 0.001121274563721364, 0.0034529720077559898,
      -0.005281827250835373, -0.0035499298107752562, 0.003655931051583389,
      -8.941064938863138e-5, 0.006540789095104615, -0.0019201949065341102,
      0.002417083700546198, -6.270935828343793e-4, -7.899319556403444e-4,
      2.9430124612533963e-4, -6.409037551278721e-4, -0.0052792887540140995,
      -0.0028915268199796204, -0.0022305644671144166, 0.008420019663640721,
      -0.006329159285802952, 9.186704620428397e-4, 0.003497273570466745,
      0.0030797907442667, 0.007106805480706909, -0.005234213239343879,
      0.0031112427876237463, -0.0016125388692900023, 0.003910026018276622,
      0.004673489160898363, -2.6733904206849437e-4, -0.004778408392342932,
      0.0044666806922392865, 0.001188618484764614, -0.0011525174930757894,
      0.001577832194277936, 0.0038298829973952564, 0.0037748536233271823,
      0.001411048194505468, 0.004189968629139331, -0.0024718177984680125,
      -0.004218579591417935, 0.0015865785608161801, -0.009279852149560067,
      -0.0027582101574601427, -0.0044241807438858815, -6.606336440827306e-4,
      -0.0012088434785830116, -0.001327064384084442, 0.004719167213516872,
      -0.009118118082831788, -0.002455924211756512, 0.005117411994543202,
      -1.3419186759170586e-4, 3.4599894614170527e-4, -0.002555039353774753,
      8.659636552234661e-5, 0.006556368826016496, 0.003659974397856323,
      -0.001853729785811563, -0.0031644206865766057, 0.003682405002158123,
      0.001060869882467575, 0.003945109429722291, 0.006914682613522519,
      -4.855568490679823e-4, -0.0032492592816144297, -0.0010531202609908373,
      -0.003650088407753457, -7.472186460603661e-4, 8.761464707029882e-4,
      -0.0029691598062585364, -0.001740724759004103, -4.611185968805765e-4,
      -5.6930714981207755e-5, -0.0029751719641527234, -0.0023792151312514117,
      0.0012510545598016058, -0.0018903307360793472, -8.306393526555276e-4,
      -6.732426321576138e-4, -8.181050296643417e-5, -0.001648131889489147,
      -0.004085252314721063, -0.007933598149889108, 0.0019730882924987167,
      -4.144306265468046e-4, -0.0011100988552611025, -0.004694872628924712,
      0.0026144192039858176, -0.001717036350935457, 0.005133326414887441,
      7.987810264725071e-4, 5.578406693841316e-4, 8.969327509948104e-4,
      0.0014326699172229644, -0.0058432920397665684, -0.0016092406069868682,
      0.0031537608276658194, 9.310835526321571e-4, -3.442597164557878e-4,
      -0.001631959975539615, -0.00692083095551962, -5.651428146175146e-4,
      0.004709563514931685, 0.002089318465515759, 0.006418604321491962,
      -0.002265120859621785, -0.0013862672131052197, -0.0034127418075415453,
      0.0037763782312388184, -8.981658038337496e-4, 0.0053134257718000285,
      -0.0025124980970695782, 0.0014185153949024109, -0.003683624277036259,
    ],
    [
      0.003209940710927104, -6.398056245504986e-4, 0.0011573377832495768,
      0.0010707131001923764, -2.094692063283741e-4, 0.005151397163411581,
      -0.007571853686182463, 0.004412824465354693, 0.0011634913921065894,
      -0.007136414686152277, 0.002822010283107919, -0.0029818792949869014,
      -0.004818361386578068, -2.0492916353940035e-4, 4.0044150260497693e-5,
      0.003074046464027747, -0.00639245237842569, -0.0035942369009445583,
      0.0018801639128090773, -6.861567918358595e-4, 0.001738486818139041,
      -0.007692079942535662, -0.0028045134312052584, -0.002503954240368411,
      0.002711643478900971, 0.0031051323002177457, -0.0014731557959357365,
      0.004200777389426487, -0.002225579072654256, 0.002235762973834751,
      0.006882804240308319, 0.008239017900958944, 0.00317699166479094,
      -0.005531131269490926, 0.004790171660850591, 0.0022705933786224147,
      -0.01156832267918477, 1.6254806300004286e-4, 0.002390888631500184,
      -0.0025418652829601934, 0.0021890433246433824, 0.0027750355376306157,
      -3.315211144917676e-4, 0.0014591439655848993, -0.0017388008354118408,
      -0.0012966853431355646, -0.0014508779017522433, -4.52056327041379e-4,
      -3.2697360967158865e-4, -0.0023704652884865927, 0.006699317615198128,
      -0.005608624019741646, 0.004296466461721741, 8.040866911294938e-4,
      0.005720734752030504, 0.0031353628039543016, -0.008026960895928976,
      -0.004946134610518274, 0.004039672459738192, 0.0032583162535522016,
      0.0018608354727506502, -5.00704934295022e-4, 1.7111331966342884e-4,
      8.732229809878644e-4, -0.004556710178771949, 0.0010882785274955288,
      0.0016142559563343407, 0.005922140020150817, 0.0032974270921415304,
      -0.0019249445435313628, 0.004114641147628346, 0.001889880384181321,
      0.005102967266866515, 2.8739408555324413e-4, 0.0012660395072148403,
      0.004463455825382538, -5.519690101423043e-4, -0.002096020549116221,
      -5.651379796506485e-4, 0.00686135727806174, 0.00809657419913007,
      0.0017528611655074301, 0.0030780275942909356, 0.0037689697057466864,
      -0.0044329438297866135, -0.001397631222641724, -0.0011830026022412686,
      -8.514460400821096e-4, 5.182282631764855e-4, -2.0652560063302413e-4,
      -0.0023275580732294485, 0.0025977165967439667, -0.003907934070193332,
      -3.0032978280576905e-4, -1.5930032842970677e-4, -0.006845043796031394,
      -0.003307966243135771, 0.0025912872882470303, -0.0032192613148119284,
      0.001972354553258982, -2.312212032766281e-4, -0.0020070608245768007,
      -0.0036434286096358923, 0.0019018540792571509, 0.0017052995424233388,
      5.044287814960832e-4, -0.007896067421354399, 9.472217510491498e-4,
      -0.0020966622663895823, 0.0103647262790617, -9.815786199830043e-4,
      0.0026646891857278364, -2.781731675954197e-4, -0.0015273083534019473,
      -0.0018485753298782333, -7.762420266717676e-5, 1.3400537492801865e-4,
      0.0012946524971524037, 7.632465573129813e-4, 0.0038088801286085623,
    ],
    [
      -0.005144748900820693, 0.003245591581470165, 0.004670435359343087,
      -0.003601377567886132, 0.0032203871579996025, 0.0032714214532602124,
      -8.178922183334557e-4, 8.217039004235335e-4, -0.0013889898810808038,
      0.0013206908573866207, 0.002259819191757493, -5.198351978055851e-4,
      0.0021618427535385985, -0.011781250749425331, -0.0015003876227330222,
      -0.006086532545737386, 0.0015479318088979415, -0.0012816033151743672,
      -7.343083456147895e-4, 0.003960872521634486, -7.276472416801428e-4,
      0.001829523495309379, -4.1517441556138606e-4, -0.007984393037557297,
      -0.0017520872407274799, 6.212431059578511e-4, -2.613851956630802e-4,
      0.0034718556653039636, -7.264247444330361e-4, 0.003419562361481659,
      0.007343613242564353, -0.001997699013794797, -1.13052391992143e-4,
      0.013495326559921882, 0.0034929634599643695, -0.003320764549144195,
      -0.007006474037461513, -0.0036751886225402583, 0.0024653620161073416,
      0.002625504837941741, 0.001614042895264553, 0.0021763936631594817,
      -4.583450043187062e-4, 0.002773590876306931, -0.004306074856318255,
      -0.0038207073727342163, -0.0033193091882044966, -0.0020133132268160083,
      0.0034148674689842744, 0.0027939411401807146, 8.692809269370814e-4,
      0.0012003959089845115, 0.003624119026374247, -0.0030603410470145384,
      -0.0018126158387896706, -0.0038990025140326497, -0.0030346583737827673,
      7.379208307525804e-4, 0.003998669376082151, 7.882906807147732e-4,
      3.9669007194256627e-4, -0.0018365484455705517, -0.0029111454950096535,
      0.0025148052959572567, -5.608220267710285e-4, -0.005666891466822897,
      0.0025083567822549, -1.561326388814545e-4, 0.00273779207133121,
      0.0025729794303932106, -0.0013182629341259725, 0.001978350934673637,
      -0.005734660071440063, 0.009690161292934919, 0.0019383235520202921,
      -0.012832087110105849, 0.004301006803559552, -0.0026166516717005687,
      -0.009287354668947624, -0.004208718600056144, 0.0027917817649661756,
      -0.007037239619151644, -6.518903962603344e-4, -0.004293607825512413,
      -0.0034687730732463258, -4.4703822353770644e-4, -0.0013119508881829266,
      2.551713909074148e-4, 0.0016889214667875623, -0.004502041457871408,
      -0.00125739054171989, 0.003209344903979022, 0.0012917678145708872,
      -0.005107096865932841, -0.001426210934400911, 0.0029217660772879375,
      1.9087059234494238e-4, -0.004687845890815547, -0.0045881771655101825,
      -0.00377225642613373, 2.7923707498887486e-4, -0.0015758732489899886,
      -0.005042138803338662, 0.0011136698052876205, 0.0016548015147397743,
      0.003701666583519696, -0.0024964836752875614, -9.97984485691495e-4,
      0.0022652392877870377, 0.004567028871709643, 7.476390352842711e-4,
      0.008662350332062788, -0.0032604396005395785, 0.0015792744861914919,
      0.0014205349437678874, -6.531213495324812e-5, 4.5492184494762907e-4,
      0.0039903212252008855, -0.0022040791905931834, 0.0036644520711829488,
    ],
    [
      -0.005715760153598793, 2.8231100749577916e-4, -0.0027352537256976265,
      -0.002344109417376169, -0.0016888174350695741, 0.0021661799969274376,
      0.005771050691078765, -0.00814142813500095, -0.00853857004498151,
      -0.0021757507496877955, 0.006566673685833337, -0.001387639789080019,
      0.003843033085005119, -0.0037963780912559208, -0.0033522338019440715,
      -5.422572629474617e-4, -0.0011181016765185812, 0.0016462464549968133,
      0.0023182891687383066, 0.005555223781485413, 0.0026475109907043944,
      0.0016991413261689694, 0.002736759604765718, 0.002576617766790466,
      -0.001712799284314879, -0.0025990055409084506, 0.0013461888181650265,
      0.00283313763862603, 7.872946998887197e-4, -6.444938586354548e-4,
      0.0010038907466582694, -8.521541133545495e-4, -0.0010989368029434964,
      -0.0019093117588795286, -0.0036726327255786687, 1.342409548033931e-4,
      -0.0012604717835826705, -0.003945325095982436, 0.004794452622468729,
      -0.0060059012505684125, 0.006436412944501298, 5.843642864458826e-4,
      -3.371789215245555e-5, 0.005055777432958811, 0.006047693989543128,
      3.6547751144277454e-4, -2.2641736819331457e-4, -0.001963856078302825,
      8.324630708458149e-5, 1.2158189203602657e-4, 0.006944825720965983,
      -0.0037420045784045093, -0.004296633614556863, 0.0010945754042702453,
      5.103968914747611e-4, -0.0016295632455831993, -0.00221478579730367,
      -1.457447264961795e-5, -0.003798574072281608, 0.004173835478743192,
      -0.003690492983994301, -0.004248974310275008, -9.443175384317688e-4,
      -0.005036434520466662, -0.0013733917795647344, 0.0032637706815100026,
      0.0026788834110337344, -4.912989323695435e-4, 0.006991899675166743,
      0.0026077523142752157, 0.002125404594105807, -0.0017577570967699685,
      0.0015868931428386392, 0.0062550770061699425, -5.638483772738975e-4,
      -0.0019080005202397128, -1.679445274091107e-4, -0.002137808596647531,
      -0.008046566118529464, -0.0015824959672596129, 0.0025322769007658586,
      0.0010758218327787286, -0.002490912673404248, 8.285315366738882e-5,
      -0.0038380565403172717, 0.001715495826943748, 0.003201345969680797,
      -0.003074031748729273, -0.0071424231122538765, 0.004182905632596752,
      -0.002862118114582518, -0.0020220549865314773, -0.005008982321961917,
      0.005233719916967915, 0.003037335404366321, -0.002137130216032066,
      -0.0012171706613276596, -0.004224521118236055, 0.0013645076066669754,
      -1.9497455237226633e-4, -0.0030709365852368197, -7.165551082789338e-4,
      -0.0015508175887859114, 0.0022069131576140646, 0.003418417878328725,
      0.0032300361740550815, 2.0613180958117456e-4, -0.004302748575996673,
      -0.0013440015972381394, 0.003280452197368818, -0.004508396459781971,
      0.00151363945102983, 0.0050068186033002185, -0.00232833013878366,
      -2.764000343846944e-4, -0.0025470911815853926, -0.005866068439814407,
      0.0048280613994979675, -6.589732844412777e-4, -0.0034274409055714107,
    ],
    [
      -0.0012330230878650622, -0.003062474646260913, 0.0010957593036285992,
      -9.728700627966861e-4, 0.008461099501199328, -0.0018262197806800592,
      -0.0037953240012848118, -8.96919182306185e-4, -0.002461943228882825,
      -0.001784120082681754, -0.0012472325301920361, 0.0043333497656188715,
      0.007960269807363776, 5.668390501810897e-4, -0.0019519629200971356,
      -0.0011721018321837967, -0.0016880681762752767, 0.005715290418891872,
      0.007101220610614356, -0.0015463054615548377, 0.0032317993894921864,
      -0.0012690386023778638, -0.002689561745194251, 0.0017960992608142636,
      -0.002971175358045532, 4.754739190553006e-5, -0.006439647417608316,
      0.0010155126076304803, -0.003452855213010224, -0.001034825555920066,
      4.8697337821842666e-4, -0.0028911038910306047, -5.596891020737255e-4,
      -4.539788825911122e-4, 5.056343211349013e-4, 0.004086400848244758,
      6.893735880453213e-4, 0.0015927915254118257, 0.0017638034590562778,
      -0.0010101707495737372, 6.078814561282506e-4, 0.005312928880680082,
      0.0012861553819617025, 0.0038636261227728633, -0.004901268671644506,
      -0.0020227135283297825, -0.004148622926351757, -0.0010131164397283402,
      -0.0017456981407973403, 0.0027767225829874818, 3.0759823296855565e-4,
      -0.0010801122360571827, 0.007507810894608523, -2.680862333429528e-4,
      8.678122400394919e-4, -0.001083003783019676, 0.005160909702556636,
      0.005142434753818372, 0.0034490906007114326, 0.0022263329620921695,
      -0.0020902829320226806, 5.942479645233964e-4, 0.0013823471181397427,
      -0.002505162259233816, 0.0034229095030307894, 0.0038905980391638582,
      0.004291417411831792, 0.0029382802001213853, 0.0015781636077651833,
      0.002627168240386553, 0.001510914706183775, -0.001577056903040103,
      0.00148694846401633, 0.002481231981082641, 0.003442118427966503,
      3.7347985343711714e-5, -4.66012629790024e-4, -1.7995361259011628e-4,
      -0.007426457116460153, -7.128168051137106e-4, -9.081216000684101e-4,
      0.0032152105250026477, 0.0022204871322423865, -9.38375896540849e-4,
      -0.005172976178135746, -0.0020699398968467755, 0.004281998621484103,
      0.004265210499892482, 5.695663777716343e-4, 0.006965329072825379,
      -0.0023666057543602955, -0.001621608989030256, 0.002834559168505372,
      0.008502974191228986, 0.0010700725375120796, -0.0024029883722043455,
      6.838177558025885e-4, -5.725798200190886e-4, 0.008318107878258643,
      -0.009567752385092568, 0.006402516355835917, 0.0014643301548763748,
      0.0021381178822156913, -0.006614445938534522, 0.0014043493060772504,
      0.002681557310039032, 0.004023025569751143, 0.003713674074571337,
      -0.0024793799376287232, 0.004879686169979294, -0.007361369191812826,
      -0.003046535675677781, -0.006325864975350337, -0.003925155015831169,
      -0.0027344602932885664, 7.64655418741328e-4, -0.004451835677138608,
      -4.570210980088335e-4, 0.0017167331085002793, -6.649117198481709e-4,
    ],
    [
      -0.0030624634142613883, 0.0014634939769860096, -7.822393636592217e-4,
      -0.0035154899288545494, 0.004733989106000798, -0.0022081446315350643,
      0.004500900840319953, 2.3867612092588842e-4, 1.509576444374541e-4,
      -0.005124213672792142, 0.0026766331444725133, 0.0028093313121339623,
      -0.0015738914735864223, -0.001534666200419036, -0.006404113684373623,
      -0.0013441478139482274, 0.004590885717332984, -0.0017619164284150154,
      0.0016755507215323573, 7.947063033896409e-4, 0.0018144002047990508,
      5.5330228814296544e-5, -0.0032625965949708126, 0.002848923158559635,
      -7.630567656446135e-4, 0.0015730481835910456, 0.002336983630198347,
      -0.003213012023842294, 0.0036681111348394937, 5.177515783672579e-6,
      0.004264264939782966, 4.7029769606994187e-4, 0.003015015332664543,
      0.0010024083361546067, 0.0018268216254412315, -3.2001559064638314e-4,
      -0.0010307264931923533, 1.5696295228732643e-4, -0.0028214238132487947,
      0.005069185650398492, 0.003394623927964254, -0.004123814091276416,
      0.001997706767972456, -0.0019809812031790384, -0.0025024099850359544,
      0.010257732430207136, -1.1206871237023916e-4, 9.265538000684981e-4,
      0.0042104600993316844, -9.567650709129496e-4, -0.0024296092485203025,
      -4.5668448130407164e-4, 0.005358488337699146, -0.002376078598130528,
      0.0019690543657040517, 5.065911744969087e-4, -5.123574915982299e-4,
      0.003907810465690123, 4.8976797113564574e-5, -0.0019978484683565558,
      8.942028768692243e-4, 0.0033495259449304155, -0.003117420267615526,
      -0.0010501051569400078, -1.6254230618385924e-5, -0.0034587979271763596,
      -0.0013846824024848696, 3.89844869254351e-4, -0.001789977493654447,
      -0.006995728692043035, 9.746877407028555e-4, 0.008573322478192943,
      -6.362065703120598e-4, 5.238201628690051e-5, -0.0038023091850857105,
      0.001118496734094462, 0.003622120483982656, -0.007025941932393669,
      0.00674621967130181, -0.003583129213390607, 0.002427677933914279,
      0.003272652630019448, 4.267427355925261e-4, -0.0014683598762591206,
      -4.1487592531277566e-4, -0.0019004592037351825, 0.002407218984583918,
      -0.0037886783072080986, -0.0016695803000648566, -0.0014145274579709995,
      4.473111289164042e-4, -2.331571515821501e-4, 0.0010839338184798339,
      3.557670484428597e-4, 0.0028636034811609333, -0.0031098070492036607,
      -0.004876733611706341, -0.0028674971104567853, -0.00234843087056554,
      -0.00496787243341268, 0.0010334418079025365, 0.00429905900622345,
      -0.001196826390490195, 9.670269130510874e-4, -0.0026659045742887006,
      8.723451770442625e-4, -0.001510622828127341, 0.002454506867674254,
      -0.003428439012772083, 0.0011331578672305667, -7.647624388932243e-4,
      7.748679855162888e-4, 0.0032010909651590436, -0.0030731679149896994,
      0.0020329612086902437, 3.0044817045512043e-4, -0.004548404768225907,
      -0.0021454005333316835, 0.002976718312952995, -0.0021907735222529154,
    ],
    [
      -0.002659839221986832, 1.7028198641125638e-4, -0.003092578033935876,
      -0.005625254983081609, -0.0031339078563142595, 0.00221002945086041,
      7.694629401490321e-4, -0.0026021636692782615, 0.0010829813807676906,
      -0.004816529514684242, -0.001470961267942092, -5.856817312698287e-4,
      0.004852351837196556, -0.0033276008880600266, 0.005659027871676579,
      0.0024381830248147674, -0.00224422881750472, 0.00366660393579491,
      4.3955556739804224e-7, 4.3985512719767975e-5, -0.011878434347234512,
      0.0025226995617595406, 0.002741556869845211, -0.0030131365556422107,
      0.0012575674110341642, 0.0010362223285854584, -0.0016282637793491627,
      0.0015703002433448516, 0.002316920401808492, -0.0015768068858474987,
      -2.5315592568057437e-4, -0.004033379873538896, -0.001883186532504637,
      -0.005721452938211689, -0.0024919168972455824, 0.0011965582700415914,
      0.004072541276504574, -0.001211063853825903, 6.598920724554789e-5,
      -3.62463699887554e-4, -0.0019184576124370945, 0.0014488788808808648,
      -0.0018895907866964714, -0.0028460735318309163, -2.7846565002652884e-4,
      0.003966718169217091, -5.246203018191715e-5, -0.0012863549177652435,
      -4.76692679266725e-5, -0.0016708105558041974, 5.662707873633635e-4,
      0.0050656468890400794, -8.602185928637656e-4, -9.470074038391947e-4,
      0.004624262603289572, 0.003529765533157725, 8.567986239565388e-4,
      0.002485824656448147, 0.002986457695970355, -0.006808828748449932,
      6.369747410752353e-4, 0.004360716064721105, 0.002554466963191518,
      -0.0019443539837183702, 0.003161312089759529, -0.004973885317653691,
      -0.0017242445506428878, 0.0068315747257669136, 0.0016468708520386152,
      0.0025978668864053527, -0.005648653846566728, -0.003305395958758262,
      0.00498049814321808, 0.003227397630298566, -0.0010387460834878138,
      6.987008327872907e-4, -0.0032811746090879055, 0.00258215750911447,
      0.0038227950449684486, -0.0016585282033190307, -0.0025108102509927652,
      0.006827685082057331, -0.0016782498819239533, 0.0011053012672144033,
      4.210998208716719e-4, 1.949974362424871e-4, -0.0027137079137309443,
      -0.005599379996569506, -0.002555715074602543, 0.007404147690089716,
      -0.0011486266752154927, 0.0027713206051433715, -0.0051654412453410155,
      0.0015406900441355322, 0.003016114009986919, -0.0026645880485171945,
      3.16659583038609e-4, -3.6758080364091597e-4, -0.0017857211230518537,
      -0.0023947673314338516, -0.0013510278315820787, -0.006268839230729949,
      0.0014033389262953365, -0.00134155544026591, -0.00406755580874967,
      0.00527952204333712, 0.0039934218798072085, 0.002347105846212766,
      -0.0014259887155340751, -2.9281069069759727e-4, 0.0020289461429029397,
      -0.004919188569498769, 0.0030864362503523553, 0.0012731537924604215,
      0.0018380314156861014, 0.002746561208020506, 0.003898144754305598,
      9.401158916687079e-4, 8.178732782797665e-4, -0.004782213340462928,
    ],
    [
      -0.002724782486530252, 8.865964041242886e-4, 0.011573096814037849,
      0.0013182607684909743, -0.004506155262239471, -4.2817721122061984e-4,
      -8.371155129116879e-4, -0.005003542903332608, 0.008148256913881994,
      0.004449507328000496, 9.918263666214294e-5, -0.0051310034275919205,
      -4.019572234860898e-4, -9.294955041608194e-4, 4.7513215387298853e-4,
      -0.004202877668376958, -2.6489745251584304e-4, 8.594447726581296e-4,
      -0.0013349485650132257, 0.00163179508453551, -0.001897432950101092,
      0.002954020455104317, -0.007074986402438839, -9.735144083963172e-4,
      -0.0012081332039578414, -0.0016930625289167107, 0.0012674847613873014,
      0.0013368773065602245, -8.875835804009208e-4, 0.006291689100710943,
      8.999007552104917e-4, -0.0011383294208370661, -0.002546302613967995,
      -0.001354222554843131, 0.00695778015369892, -1.2767661805698237e-4,
      0.0053968075274026675, -0.002061599456614149, -0.005005358622467046,
      0.0022217159833348072, -0.0057371616773126185, -0.006254996337558353,
      0.0041524661851266385, 0.003008156703729727, 0.002548662311905464,
      -0.006601936040280245, -5.33187079759261e-4, -0.0030209629989941967,
      -0.00487413202216711, -5.589829901193319e-4, 0.003829936666522407,
      -0.0024506508667205503, 0.005063080919023993, -0.005762072175676727,
      -3.3860163915439717e-4, -0.003437233816228773, 2.493569533730676e-4,
      -0.005081206546034357, -0.004504339834946249, 0.0018363926554587934,
      -0.0033443796217287394, -0.0010106753047445412, 6.150888939520349e-5,
      -0.003950675997503254, 0.003917968359137152, 0.0010221932942334464,
      -7.766616814053977e-4, -9.761754356679894e-4, 0.0036291677481035027,
      -9.923288331481452e-5, -3.4265931257810884e-4, -0.0014970424941519243,
      -5.157714241107965e-4, -0.0042760322991554234, 0.001625996380567458,
      -0.007292184243626071, 0.0024992931026119177, 0.008651071550832577,
      -0.0014370765141633387, -1.3173652766807414e-4, -0.0029527412211661037,
      0.004079866563610875, 0.0022799796017996406, 0.0021978576322189422,
      -6.596124934388757e-4, -0.0016370566623932351, -0.0022854791867104457,
      0.003031260141204575, -3.844889232990688e-4, 0.0038959559404353012,
      -0.0030271745314297094, -5.057506866826336e-4, -0.0026314542515338112,
      0.0015586290751428479, -0.0020330204535464773, 7.249610593356673e-4,
      -3.332615455751116e-4, -0.0014199696464884833, -1.9762374771561546e-4,
      0.005026854767139868, -5.050592870587397e-4, 0.0023465353508833873,
      0.0034560139868143698, -0.0028573671082588238, 2.314408262265055e-4,
      -0.0011954144585439414, -0.002942163889732575, -0.0011935444964637784,
      0.0068722997132208315, -0.008572305472634238, 0.007340170251546992,
      0.0043393920388363014, -0.00467768158666847, 0.0024454222059844223,
      -0.00772605670174544, 0.008984760944273418, -0.003499117961227936,
      -1.6234015255655694e-5, 0.005337908748229268, -0.0015823643345080365,
    ],
    [
      -0.0013542290421389908, -0.0014294496739074474, -0.0017622262500619598,
      0.0030870860239890657, -0.00564964452452121, 9.148349723995062e-4,
      0.0032202511047272207, -0.003066862466258256, 0.0016260013976481594,
      4.3913699619534406e-4, -0.005526969054611101, 0.0011733538526212841,
      -0.0027955753822533785, -0.0014638590585915308, -0.003120054552126276,
      -8.021928315791571e-4, 0.001289227559808887, 0.0041090724053468065,
      5.615184460439795e-5, 0.0010932306338823842, 0.0019414618360429484,
      0.0041473548334926835, -0.0031738955854568613, -0.004633213881920183,
      0.0020610573665777007, 0.003195563976319323, -5.069281291644162e-4,
      -0.005080999192478017, 0.005817887480846413, 0.010798026531021375,
      -0.004666956669824782, 4.6801906040063845e-4, 5.002977581849699e-4,
      -0.004132895357563249, 0.0015157458062110237, 3.9408196745808024e-4,
      0.0010722112836922754, -0.004746939060162589, -0.0012420660420518238,
      0.002296093452857556, -5.793327429421402e-4, -0.0022386262065550813,
      6.587753923016845e-4, -0.0011599454433001628, -2.3892060686470562e-4,
      -0.004897638765382472, -0.005898038104792986, 0.0026495526668432945,
      0.0012915816077995281, -0.002372802045658259, -0.0053633861958625,
      -0.0017259464141988626, -3.2678242466494987e-4, -7.746809080237674e-4,
      -0.0012422011314923783, 1.3530538129414261e-4, 0.006262461096432454,
      0.004281819158715882, 0.0026583954655688413, 0.002610053103938352,
      -9.920141658070207e-4, -0.006676833500255764, 0.004590937187126373,
      0.003233543375971021, 0.0063019128586080195, -0.00144108632710752,
      0.0019432229781632532, 0.0043814492575788965, -0.001856864984974201,
      0.0013344636852157437, -4.7454652997129467e-4, 0.0012147258241570278,
      0.0027626257252441147, -0.005153194421191532, -0.003094174983781498,
      0.0018798218545806014, -0.001898285561818972, 0.0013660365384137955,
      -0.0015202622286481443, 0.0022899053184474204, -2.7934252244325724e-4,
      -0.0022927242755748376, -0.003899863871971286, -7.512411703197647e-4,
      -6.85289487981971e-4, -0.002918547053903876, -0.0015679227549519428,
      0.0016164392742699565, -0.0017074391789220658, 2.7997328038707357e-4,
      -0.0010764423106858806, 6.097348827356424e-4, 6.031117602707262e-4,
      0.007632159836418128, -0.0030449203274715295, -8.824182624608661e-4,
      0.0016494577655123107, 0.002235536579154736, 8.948133152878791e-4,
      -0.0026743826631782487, -0.003914606176139423, -0.010055735693027269,
      0.005701548216619931, -6.876750280969321e-4, -0.007774831879816247,
      -0.0022830664663191936, 0.0013722566679489246, -0.006432482297028466,
      -0.0010577040325720085, -0.0022360205697440363, 0.004240261165620526,
      6.52686732064929e-4, -0.0015713030891711716, -0.005304155693888729,
      0.0022610988992318405, 0.0011446481901407882, -0.0036354960588260115,
      0.0011587465551337376, -0.0010652473079148217, 0.003780620731192256,
    ],
    [
      -0.0036360245807342774, -3.4074438676159695e-4, -7.159598457170025e-4,
      -0.0037145680836872, -0.005168964410267048, -0.0018713013697324065,
      -0.0010686917392211327, 8.985590583918759e-4, -0.0011681854936801254,
      -0.0017263128337117349, -0.003916113927570935, -0.004015881553480137,
      0.0039820582318065255, 0.0038234682273422206, 0.003661316177143688,
      -0.005556527318836968, -4.7950430055695466e-4, 0.004545141585413514,
      6.864773006278007e-4, 0.0019550521198884235, 7.373458290051905e-4,
      5.333486194560893e-4, 0.008503559885326594, 0.0033283037424803232,
      0.004364722198160588, -0.0017727253142882328, -0.005518790007581302,
      -8.108123978579629e-4, 0.004121118122524239, -0.0038865274188439186,
      -0.0078023344944329305, 0.004290983179962444, -0.0011621884444677657,
      -0.003441785165194342, 1.6005840617823905e-5, 0.003452619942110524,
      0.002095813382256299, 0.003469966470896586, -0.009807377529612684,
      -0.008284428320447932, -0.005510861907732948, -0.006171007767578399,
      -0.004165011129528844, -0.0015418023559725744, -0.002006565704578933,
      -0.0030038728073549592, 0.0062761344852914295, -0.0027717833731503456,
      0.00625958230507101, -0.0031681066285322674, -9.975808381056384e-4,
      -0.0010013701342932713, -0.004090333731027555, -4.5924804297899237e-4,
      0.001538031467482871, -0.0022387050630694842, 0.001106214374675203,
      0.0020250765087818936, -0.004385007307142818, 3.1639409221078865e-4,
      0.011292433012155434, -0.0054643907405936745, 0.004930232789114832,
      -0.006914927133145753, -4.2301231496827396e-4, -0.002273466632169697,
      2.104146941522805e-4, -0.0029412418491306875, -0.013039945881378353,
      0.0022202194966158464, 0.0022322281341841106, -0.0036930024596329943,
      -0.0036891126958181527, 0.0020598617593736196, 0.0027878065324360756,
      6.437240682551388e-4, 0.0016898366000504918, -0.0038242128635572045,
      -0.0028326219681053047, -7.289061802428587e-4, -0.00260269259049697,
      -0.0017930423548190235, 0.0023282726657709862, 7.761721429411216e-4,
      -0.001038249341390392, -0.003824501953375168, -1.647887461859703e-5,
      -0.001704745887568508, -0.0015416140384996208, 6.509823943419881e-4,
      -0.0049839865057590945, -3.502345387163618e-4, -0.010830277684153318,
      0.003289907850206445, 0.00545334238893897, 0.0013570297100595915,
      0.004623555104852014, 9.33598703872769e-4, -5.117014988578043e-4,
      -0.005478764048598395, -1.0102177346804129e-4, -0.0017098689270890126,
      0.00118359794241944, 0.009064114637645011, 0.0013160451097457594,
      0.0025920998638739806, 2.658229380596098e-4, -3.3214790828011716e-5,
      -0.004184577461531183, -0.005880214572750007, -0.004341123441992238,
      9.271256491489401e-4, 5.546695242099251e-4, 0.004652200638256079,
      -0.005303186739241997, 0.004255456284651868, 8.778400274140067e-4,
      -0.0029100667806462115, -0.00656309390966672, 5.081882454907639e-5,
    ],
    [
      -0.006751831242823627, 4.143477712398067e-4, -0.005181091721439407,
      0.001587565325672529, 7.498278805850207e-4, -0.0018504105664922743,
      0.001040120801707635, -0.0020569702634180144, -0.0019017174859467052,
      -4.4354831672224917e-4, -0.0033350206513765197, -5.366522338359962e-4,
      -0.0031684616769136866, 2.146596959045381e-5, 4.797369442155118e-5,
      0.0017050140864845508, 0.004688553529214764, 0.002294714846046951,
      0.0014802876247973886, 0.002431670292648557, 0.00173407083119899,
      -6.586590742457181e-4, -0.002622670245223357, 0.001869601282937579,
      -0.006364273445633671, -3.7946667151742756e-4, 0.002363363845595761,
      0.004309028385817357, -0.00663214220979465, 3.5726655501763425e-4,
      -0.002276452657611628, -0.003650510227916727, 0.002580592494536244,
      0.003712058231704329, 0.007519213034895569, -0.002233850001108417,
      -0.002134918272813424, -0.0021399396348838135, 9.211660079333072e-4,
      0.0034656791970332198, 0.002274790779549671, -0.0035967928096525756,
      -3.965401055231796e-4, 0.010008328083800797, 0.0019454229199287745,
      -0.0061089001311048306, -6.694773186867434e-4, -0.005488827477234641,
      0.0015629349212385978, -0.0036347861203523844, -0.0012588810178768835,
      -0.0041226138359160246, 0.0011385441485967794, -0.002372598596845488,
      -1.6769890882751076e-5, -0.0032274481442092643, 0.0019389234241436624,
      -6.464262450416374e-4, -9.400879328945297e-4, -9.626883875432375e-4,
      -0.005409387768198685, -5.056478165113252e-4, -0.004167711686410034,
      -0.004962769027924433, -4.411058696059334e-4, -0.004097689055434837,
      -8.668788747062432e-4, 0.002139251026258309, 0.003465524613104958,
      4.047871090802057e-5, 0.0010659614935227281, -0.008457345617011846,
      4.1558517399344454e-4, 0.0023274117399767827, 0.0010084535642236369,
      7.798813973929377e-4, -0.0023823263957007497, 0.0036764352956297442,
      0.004245167634905715, -0.007071362398220516, -0.0020913556312195814,
      -0.002056856842264647, 0.004043633431886728, -0.0021234417100359127,
      0.008088612849642442, -0.0038687367852104555, -0.001410322643363209,
      7.3736228070301e-4, -0.003989842104912481, 0.0013723863931594184,
      0.00379269641358549, 0.0045578551587372226, -4.679149366991805e-4,
      0.0044222438867383175, 0.0014534778310395736, -0.0023490620994674003,
      -0.005737717080644474, 0.0029374888044724165, 4.952168693225879e-4,
      0.004636821009395105, -0.002596464752143913, -0.002259722225249999,
      -0.002520507268687785, -0.0028106101469715563, 0.0031973361072526523,
      3.4811205596561325e-4, -0.0054011843264035264, -0.0025693911301292952,
      -0.0025892017303275018, -0.0016700374744349437, 0.0022599530844968413,
      0.001934529884444193, -0.0030750953906236132, 0.004732233460157893,
      -0.003107512881211145, 0.007639586817812202, 0.0023200775331488326,
      -7.455722111358665e-4, -0.0011454914530611708, 2.7869436212535066e-4,
    ],
    [
      -0.0030093843245258527, 0.006399839301010229, -5.11482853074962e-4,
      0.006108464728384352, 0.0025561676769310514, -0.002725971845571654,
      -0.0016228923657014985, -0.0027412282223130005, 6.561877917494574e-4,
      9.069181851017411e-4, 0.004667352212372067, 0.0027001497821750237,
      -0.002638453240696947, 0.003006410445887543, 0.00911437510432471,
      -0.002550910285491429, 0.005541099851210049, -0.007173241690877326,
      -0.002232429924933479, 0.0015777914118713556, -0.0015372080993742276,
      -3.561437173936016e-4, -0.008633740748787484, 0.005265978645687178,
      -0.0038326921287061937, 0.0017303882603208712, -5.824520227942872e-4,
      6.798645786460788e-4, 0.0031806643999981133, -0.0015514552773568352,
      -0.00366875251285939, -0.00619617103947961, -0.0018685869264270308,
      0.0030939547244498047, -0.0030476554354281525, 0.0021130981118239354,
      0.0019527177694098603, -0.002487033751792418, -3.32445403248244e-4,
      0.007825931513290394, -2.2226721537480662e-4, -6.906343864091456e-4,
      8.275185187713354e-4, 0.0020671774151162898, 0.007175675152925778,
      -0.005562194474338091, -0.0019335954627158214, -0.0015036543661385393,
      -0.0020282023827725743, -0.0028132755046563623, -0.00298166492753046,
      0.0020426505129110227, -0.006245600948971516, 0.004294166214465475,
      0.004560106270381846, 0.0014535341788852307, -0.0012079811502673898,
      -3.344784534057873e-4, 4.761104425042951e-4, 0.0036178469033260044,
      -0.006392901343516327, 1.558732242302967e-4, -0.003499337090160333,
      0.0029657354784440814, -0.0025127303893431926, -0.0011970096665719033,
      2.937150489607175e-4, -0.0011252957379504248, -0.0055478161882275365,
      -0.001896337660472148, 0.0017372756080650553, -0.0031623097317948837,
      -0.0023421388317771165, 0.0013655642932597102, -0.0036490928774868467,
      -0.002514078447745199, -0.005388469727121408, 0.006695066835706094,
      0.004900948722671181, -0.002968243037265855, 2.805920112190449e-4,
      0.004400622431492364, -0.0023415469085106967, -3.916432866377524e-4,
      -0.0013143380360793248, 0.001852031331721698, -0.0025600320371590305,
      0.0018699792435538534, -0.0013969356154867928, 0.0021310543741192914,
      -0.005876238508733691, -3.928722322585347e-4, 0.007934047482351483,
      0.0028161926115825484, 0.0010955674189174302, 0.0029588870629521356,
      -0.002362462357239533, -2.2878071906954595e-4, 0.0013174587017835736,
      0.001496573040571792, 2.5335371316149784e-4, -0.001004666128940249,
      1.3424988137194263e-4, 0.00464387697947982, 0.0018687176321761285,
      0.0035575106614066706, 0.009334202579600884, 0.005020147429695322,
      -0.012936948739516008, -8.372688635372907e-4, 0.0012052468403323633,
      -2.852263077601006e-4, -0.00419323026114768, 0.002146585147889293,
      6.817820737661577e-4, 5.168201572989092e-4, -0.002278676787802509,
      -0.0023958892812227335, 0.006746112272209715, 0.00255368146474023,
    ],
    [
      9.162401345739575e-4, -0.0028752422726762436, -0.00275724807966921,
      -0.003680278894452499, -4.979815713122352e-4, 0.0012821678614038838,
      0.010344070453472139, -0.0034124901199605404, -0.0030624929888850598,
      0.0015100550593966115, 0.0013475089109792064, 0.002196772970911725,
      0.00384391559973425, -0.0022489006981119717, -0.0026637405015716214,
      -0.0025055483642566294, -0.006476101217540138, -0.0013808513116472763,
      0.003290390273389136, -0.002084130408337319, 4.58575889087848e-4,
      0.0014207841268338139, -0.002108014319562505, -0.0030782604503100343,
      -0.0010061331461552827, -0.002406500771178121, -1.1028618348955606e-4,
      0.002492516347701476, -0.003948103785267014, -0.0015353392666572288,
      -0.004674143389315254, -0.002859030537616267, 0.0010521824340685398,
      0.001926137263028313, -0.0017874423437751466, 7.689538172257597e-4,
      -0.0034147971767240033, 3.6857368941702826e-4, -0.0039525576508609985,
      -9.064459452617462e-5, 0.002967621673879402, -0.007525026710031538,
      -0.0026865155004168056, -0.0038487946964855632, 5.266183472529371e-4,
      -0.004004033410786461, -2.615009079297554e-4, -5.097433988775924e-4,
      0.0036264261393028547, 0.004854444904678909, -1.226176850313257e-4,
      -0.0015922604668392197, -5.79556878425149e-4, 1.3409503933399885e-4,
      -6.563495302086865e-4, -6.034241962250387e-4, 0.003408340021036971,
      0.0014757723043311711, 0.0015936449934922617, 6.275830103912876e-4,
      -6.63757067969282e-4, -0.003965302316770265, -0.00274034064328717,
      -0.00694996188072991, -0.007769155489191392, -6.435768050805298e-4,
      -0.0024910047810308223, 8.073085656255836e-4, -0.002582457672987386,
      -7.284745944063278e-4, 0.0011929007660977414, -5.374892126472644e-4,
      -0.0024334672294210593, 0.0025883904656722563, 0.003059175903483254,
      -0.0018439502467838145, 0.006310965064741117, -0.001884323560261918,
      0.0039035078182156468, -0.002888739054350593, -0.0014464887091122447,
      8.467075245723075e-4, 0.0018891834724290416, 0.0017584196250348223,
      -7.291181508377294e-4, -0.0013906616027046298, 0.0010512483011020659,
      -0.0018576901939143437, 6.557762927485303e-4, 0.001105004954584433,
      -6.850921544732905e-4, 0.003390436951091577, 0.002345435740340833,
      -0.0029913175660442274, 0.008565794585717988, 0.0013995891685309185,
      -2.0613094585062545e-4, 0.0052093895368682495, 4.993973210649335e-4,
      -2.6840738261427763e-4, 0.0021620227067376792, -0.005326898438318169,
      0.007965530152800873, -7.60646809263173e-4, 3.899932801580549e-5,
      0.0030829196057936626, 0.002101517977832252, 0.00381890910510059,
      -7.877132586582567e-4, 0.001652609594219754, 0.007665718220323304,
      -0.0023973313943687477, -0.004594425344027759, -0.0010827725516284522,
      0.001772764336454057, 0.0026119207296571483, -0.0010226231852453168,
      9.94349760684009e-5, 0.004112547644854091, -0.009152247718668684,
    ],
    [
      -0.001089553292908357, -0.0031058421570714046, -3.2018813757349633e-4,
      0.002754709496179175, 0.004643679485537872, 0.0010210778445291118,
      0.006759860034650997, -0.006081640410513324, -8.927572933098538e-4,
      0.001752132709174751, -0.004609365561388013, 0.002505144539556493,
      0.0011705806678758324, -0.00488947196813877, 0.003992542891452653,
      -0.004126500904946442, -0.0041932357240700695, 0.0018402140545326355,
      -5.570220770562775e-4, -0.004518332186568882, -0.005552617407363012,
      0.00532043808907132, -0.0025062727855226195, 0.0013058033242899342,
      -2.808467322809509e-4, 0.006779918944206735, 0.002120261714851212,
      -0.0023409887706500954, -0.002552593298095929, -2.3139535651165195e-4,
      0.0034167220384391794, 0.0026903697585486295, -0.001925117144196436,
      0.006748666988181298, -0.0013608835629144742, 0.008618245402458202,
      9.790092374734696e-4, 2.4962290421820467e-4, 8.563706166318274e-4,
      -0.004529109209965784, -0.003273855974131676, -0.0021521468242849456,
      0.0025795711498586755, -4.7047511738170146e-4, 0.0010666550028594138,
      -0.0014695726097441039, 9.598637586435439e-4, 0.004980367584680039,
      -0.008844980114695535, -0.002441050761889786, 7.677694315418805e-4,
      -0.0050102531337028735, 1.24175484077491e-4, 0.0027458135425150066,
      0.00416566548985669, 0.0036659596737661296, -0.003766804894470064,
      -2.8455549833809646e-4, 4.824791552034516e-4, 0.005096510903673678,
      -5.966535856757151e-4, 2.694991227975101e-4, 0.008000684780459856,
      1.6294468377422782e-4, -0.0069939394129525136, 5.2008756141577677e-5,
      -0.002066633205884864, 0.0023092640594317452, 9.205779938198706e-4,
      -0.0038742570694929373, -4.746245292472309e-4, -0.003659410572044987,
      0.0013509573653459387, 0.0025717147147246095, -0.005614663084880605,
      6.942343642897259e-4, 1.1213088071849935e-4, -0.0013651424041924653,
      -5.220857389793027e-4, 4.6328685253158925e-4, 6.664129327878211e-4,
      -0.003212441889489206, 0.004459982991953624, 7.327007068582004e-4,
      0.0033031518254802695, 0.003312320092309803, -2.8315977740852433e-4,
      0.005522002605238674, -0.001692439207787232, 0.004728854398852922,
      0.005187818558120462, 0.004264020285656575, 0.002909007017715391,
      -0.0010451729459427204, -0.0022923604399001877, -0.0010650230306067452,
      0.0015068639889920113, -0.0036278108817392723, -5.519168122309443e-4,
      0.0032218840935733435, -0.004676834496462792, 0.0017147860826851147,
      0.0035924447281741913, 0.00876067360168823, 0.0048418096201881915,
      -0.003895164707194516, 3.6641708766304535e-4, -0.00573425518419585,
      0.004247033770141542, -0.002544611124485039, -0.003467443695038308,
      0.00402550823605089, -0.0013208931846458065, -9.787596320678814e-4,
      0.002223367312349734, 0.0018050640801660746, -0.005102265556524006,
      -0.007261974266326492, -0.006597446844480667, -0.001512247380962367,
    ],
    [
      -0.0031227982145963194, -0.001790417440448098, -0.007280539764074915,
      -0.002785742180021003, 9.85573524878781e-4, -0.004671478575439425,
      -0.003450809588887751, 0.006247701596946639, -9.661550945823209e-4,
      -0.00219607339060892, 9.556215233412478e-4, -0.00259463994198377,
      -0.006867308595345999, 2.2871590589200092e-4, -0.005892486072361237,
      0.0032093321848712733, -0.0055522750873906995, 3.3200966140020714e-4,
      0.0035969277556690696, 0.0063034873150255135, -0.005500671139313663,
      -0.004082540666524545, 2.2110900827887172e-4, 0.0031700247860250437,
      0.0011903828015912628, 5.963800327752046e-4, -9.664465527288025e-4,
      0.004013248195227149, 0.0017778703504445015, 8.470944612376088e-5,
      0.001687968828942365, -0.0030374954177570827, 0.0015306259164552419,
      0.0013011812772998456, -0.0023060589315057186, 0.0017614810494924525,
      -3.653429828009057e-4, -0.0033618696662731313, 3.8053380616163664e-4,
      -0.003931944430721743, -0.0021774211215639288, -0.005090478160805941,
      0.0033610529705158866, -0.0012105593610426532, 0.0030176072820899355,
      -0.002351249156344501, -0.0022465433114247126, -0.0021812094880905982,
      0.002275351608081417, -0.006651641384617827, -0.0017403609816918188,
      3.23997850067677e-6, 7.42372233049767e-4, 2.2031587581402247e-4,
      0.002016777205414643, -8.950248457860688e-4, 0.0010468744344626325,
      -0.0019678802213358056, 0.0017976060353574934, 4.180430520092089e-4,
      -0.00220139222253188, -0.001337254369802521, 0.00373120882413185,
      0.0014831998686224077, -0.0023809256393688733, -0.0017020401167579069,
      0.0036586448727646565, 0.0012926822384927011, 0.0013852898290428676,
      0.004930403496841318, -0.005429124539098716, -0.003271645915247964,
      0.001339597795547598, -0.00424026805391323, -2.3737269441543928e-4,
      0.0038411356687882755, -0.005007413400683643, -0.003891893078136051,
      0.005198246803553646, -0.0011933736664264617, 6.07126929752121e-4,
      1.1934241022429852e-4, -0.002173094160803005, 0.005660028493722224,
      0.0011538337597380545, 0.005258997911406285, -1.9394150912378243e-4,
      0.0026606047232602837, 0.00535566287243827, 0.002486284522521807,
      0.008626997352096679, 0.0029135044906679953, 3.035271917002174e-4,
      0.0015476850559140768, 0.002103528893394306, 0.001516993758615625,
      0.0014722059124910112, -0.0010023887560602594, -0.002206804821109182,
      2.37987852179921e-4, -0.002695619032071048, -0.002140680752849637,
      -0.002367422278281752, -0.0032275929155018597, 0.0029645995992411073,
      -0.0012606571928264173, -0.0011335216694234385, 0.003472038077457229,
      -0.009488823250401433, -3.482571952261561e-5, -0.001976639828350733,
      -8.193326279009349e-4, 0.0034699711403325785, 2.994602751228504e-5,
      0.00350921279103258, -2.9826175195786e-4, -0.007636748836897786,
      0.0019701625199729476, -0.006259157981080933, -1.7535013810445832e-4,
    ],
    [
      0.0035861356713961182, 0.0029595935848337777, -0.0017054087354555487,
      -8.134011297618344e-4, -0.00439491957092108, 0.0025953987593103486,
      0.006254512522897256, -0.006883741214070921, 7.877814098109114e-4,
      0.003877548619976112, 0.001050341838652262, 0.0012838474093317198,
      -0.0011674459605631252, -0.0015411642889748775, 5.075515371111729e-4,
      0.0014470678972562576, 1.660051139107021e-4, 0.005683125597149901,
      0.0018190645327481481, 0.003059442908565021, -0.006149530349835388,
      -0.003287886904969963, -0.004910496062782968, -0.011817323538811833,
      1.7765491047250952e-4, -2.1727167016879947e-4, 0.002629913951933057,
      -0.002961111219793409, 4.3195071885063056e-4, -9.033017304339161e-4,
      0.009180969980292483, 5.793070876709849e-4, 0.0016615278275489069,
      -0.0027494226556498283, -2.950035498143406e-4, 7.637466115878638e-4,
      -0.0020050709604442453, 0.0020090322715854954, -0.006589064881347237,
      2.801625747614994e-4, 0.005267470517947419, -0.002459944467620664,
      0.004127336920824487, 0.0015570522021842166, -0.001650221380174508,
      -4.7217656149791564e-4, -0.004366995067575215, -7.04100098379901e-4,
      0.001943731632879401, 2.123959139861484e-4, -0.006660994502679089,
      -0.004799698388346731, 0.002599857613845823, -0.0014225125933099562,
      0.0019242941203353094, 0.0012392389547818416, 0.00116543218349009,
      -0.0015352936272380832, 1.6171011373517733e-5, 0.002095946206224388,
      -0.001232740718729673, 0.001426997547859684, -0.002516579453162313,
      4.987009187754389e-4, 4.657168703756661e-4, -0.0047705180611763865,
      -0.003841511631912989, -0.003451535821514329, 0.004829968192447391,
      0.0025791615317985314, -0.004368458758024828, 0.0020686209755546173,
      0.0026839980673510405, -0.006487963267796661, 0.005749887016201123,
      -3.8009502973954644e-4, -0.007948397571425786, 4.623413779851571e-5,
      0.006148816723089278, -1.0840215319286468e-5, -0.00796845008123787,
      -0.0022808124512336634, 5.289323216973007e-4, 0.001098457174522748,
      0.0076005491060750306, 8.46184633135726e-5, -1.7638525022604698e-4,
      -0.0012121237465764604, -0.006442135056083433, 0.004727709791687568,
      0.001816251499444347, -0.0022504568723728576, 0.00999630698315429,
      -0.007572363478126665, -3.897611712664731e-4, 0.0032595128908316645,
      -0.004939615890237902, -0.0011877124371028843, -0.001193182546841631,
      0.0019864855602507365, 0.00551521159311053, 7.763918547109477e-4,
      1.0841034345512098e-4, 3.442793775274601e-4, -0.001297308696107835,
      0.004099889541792424, 2.6962720690904026e-5, -0.005204503521119821,
      0.0029108133097635724, 0.002640907556883031, 2.3284645970058867e-4,
      -0.0030551737576815755, -9.606982141487069e-4, -0.00914021749639267,
      -0.0028875411969183536, -8.030688359546784e-4, -0.0029992500502769998,
      0.00499180485195152, 0.003722688424697063, 3.0479822719148227e-4,
    ],
    [
      0.004486818158441177, 6.067312305835164e-4, 0.001989139326142891,
      0.0011484419510344635, 1.109562957226201e-4, 0.0014506610219450408,
      0.0018752958789715513, -0.005358729674363643, 0.005582256151979942,
      -0.003584532044584172, 0.005284224903515871, -7.806649857562183e-4,
      0.00195430628750946, 0.005084693058907317, 5.209803009616173e-4,
      0.0014757448019630899, 0.005002686134825672, -9.256233223306853e-4,
      0.002484547274985585, 5.494634786987781e-4, -0.0010352393153084051,
      -0.00348879649036637, 0.0037723565068105416, 1.5117976530058547e-4,
      0.002067409108541, 0.004620572595555638, 0.005940894042149397,
      0.004954607037348157, 0.0017414045434313765, 5.6883305495914e-4,
      9.387771896100518e-4, -0.002681335617224491, -0.006075914117153472,
      1.2311149894498653e-4, 0.002561839984200973, 0.0036231474067133294,
      -0.001656271424317878, -9.217212928162616e-4, 0.004589989756180723,
      -0.0021718955433865732, -0.0058508543726021964, -0.0012994748053784498,
      -0.001184847278116294, 0.005468420296403294, 9.986472595541532e-4,
      -6.974058617202569e-4, -0.0018956653418302724, -7.912208461891177e-4,
      0.0032181061547284164, -0.010662240111276925, -0.00488854179943324,
      -0.004008904605222337, -0.0020595336373090225, 7.218542569294877e-4,
      -0.001445823772074814, 7.088926808490214e-6, 0.005515454490778735,
      0.0018550344409908963, -0.0034841715315798065, 8.373259925217963e-5,
      0.0024909849046300106, -0.004323698050503627, 0.010234017273027491,
      0.002746118209962992, -5.307319367112031e-4, -0.0022660331003781483,
      0.0023838026214196423, -0.012449763801896788, 1.9723545008917852e-4,
      0.0023085241508997954, 4.1190892786725525e-4, 3.156807174720534e-4,
      -0.003576618253147777, 0.001986086384128573, -0.004470271795107683,
      3.145748001375354e-5, 0.002214298325002943, -0.0045760015663384185,
      0.003018990037245051, 3.19964461994832e-4, 0.0025575076598957883,
      -0.005629984497515379, -0.0010443993435347578, 0.0010068522667884944,
      -0.004229966950160686, -2.666134402946342e-4, 0.0011489084021249236,
      0.00237381570597542, -0.0010505000048231345, 0.0012641672234880322,
      -0.004206128445019346, -0.0032054931929025848, 0.002004138332668853,
      0.005053874927012333, 3.433515156063255e-4, 0.004601959157041199,
      6.18790332604432e-4, 2.8336867701487666e-4, -0.002725915662900005,
      0.0044859118724627604, 0.0064657286206365955, -0.005729914772304808,
      -0.004135966443016307, -0.00540227687985655, 0.0012691824983582665,
      0.0011116332390619411, -0.0014857225890407535, 0.004610025419010316,
      0.0037912870185069277, -0.001005584982562399, -0.01062682877785637,
      0.0017265269985679535, -8.665727217863875e-4, -0.0011666927824805643,
      0.005516328862343633, -0.0027110800790414763, 3.869578084358509e-6,
      0.0035254266677521855, -0.003252029096277508, 0.0011941113267487208,
    ],
    [
      0.0013570504627341217, -6.821793025203009e-4, -0.005828927343241615,
      0.0027204395881777234, 0.002686016367055787, -3.872320022884052e-4,
      0.00981618006595656, 0.0023982889384769994, -0.002476280228474378,
      2.1070342067493898e-5, -0.004314246466282197, 0.002333370457172595,
      -0.0015449244358937584, -0.0019225559775953409, 7.978499289592925e-4,
      3.187708234120336e-4, -0.0010404213295726699, 0.0038013054053745604,
      -0.006749184189164521, -0.003918056838168083, -0.0017294616553497823,
      0.0026175749146388166, -0.0016747069928880805, -0.0029213283271079817,
      -0.003465744254382719, 0.0019037877800071618, 0.0043594120663668304,
      -0.00956795170023016, 1.591309340867823e-4, -0.004054654068247016,
      0.008483217474316383, -0.004820238355227229, -0.004041208401408444,
      0.0012728249616159703, 0.004132159278606829, -0.001738759339760109,
      -0.0020432289157436946, 0.011362928635594578, 0.002217631528785075,
      0.0032844916875745286, 0.001567424837380855, -0.005453129721574645,
      1.92626652509036e-5, 0.0014701510194153281, 0.0042188641832677344,
      0.0017862586316269697, 5.81513775157326e-4, -0.0017218907301373787,
      -0.0027832834858199595, -0.0019262532248193152, 0.00274616524720794,
      -0.0045764080747189035, -0.0035329965169445567, -0.0013085701778515724,
      -0.005692241721726981, 0.007275972710653441, -0.006619290633509749,
      0.004369029200348806, -2.558830999976441e-4, 0.0017838487939228487,
      0.0017483332931145057, 0.005929030879026787, 0.004995261021171599,
      -0.005864175138412831, -0.001930453148550098, 0.002162218650459478,
      -5.46496473665367e-4, -0.005978190387605473, -0.002918596213635279,
      -6.975163800205509e-4, -0.0031019806893068914, -0.0033721945540593616,
      0.007593465266165283, -0.011346616857268746, -9.81539518982156e-4,
      0.006849880748120873, 0.002739590909887702, -0.0019731816493087076,
      -1.7509083954282158e-4, 0.004150885012947567, -8.645995118960696e-4,
      -4.81040968916242e-4, -0.0014817355355216748, 9.959139402765365e-5,
      2.0137566868572826e-4, 1.68409357634673e-4, -0.004491520141072612,
      5.647119304997912e-4, 0.0021035783437439027, 9.487192460683613e-4,
      -4.2674771413655187e-4, -0.002790097099813922, 0.001094791640603635,
      0.003647171388690585, -0.00539923090649117, -2.2249790155267615e-5,
      -7.98667973074195e-4, -0.004191953598179964, 0.005671369900589196,
      0.003259558034174759, -4.796587912241758e-4, 0.001335382331812342,
      3.034987939806885e-4, 0.001542399656114471, 0.0028577266565882357,
      0.0028650926498216423, -4.2639450860054164e-4, -0.003984811590740591,
      6.267378023239211e-4, 0.004823094746740782, 0.005519662596952964,
      -7.799022887613645e-4, -0.004784313796245642, -0.0011854306083127193,
      -0.002458298607581116, 7.931335722551535e-4, 0.0036547789916081858,
      0.00196526549525484, 2.587061942789101e-4, -2.2799633379268756e-4,
    ],
    [
      -7.629336321174924e-4, -0.002629036984163303, 0.002690527416474131,
      0.0020807104683011847, 5.011393006328078e-4, 0.006558832844926112,
      0.005631315068071395, -0.003706586215981841, -0.005597544870310101,
      5.928119824153429e-4, 0.0021305831751598685, 0.002221229138947786,
      9.437500246435687e-4, -9.501762383203121e-4, -0.0019354993954881724,
      0.00248173645116854, 3.6813656204842175e-5, -0.006290031404755734,
      0.005897783383795702, 0.009107149254940161, 0.005519675186685589,
      -2.7974331149042394e-4, 0.0014284707725024351, 0.0011444024846708357,
      -0.0044765376500047, 0.001269486560922265, -0.004065626814875739,
      -0.0036709578142344766, -0.0029111538709565826, -3.89058674654065e-5,
      0.0041308042740138134, -0.003660674110984654, 0.0014115328356780082,
      -0.0026021913981996627, -0.005460185033061771, -0.0011361374932544648,
      0.010110009845795078, -0.003304178428536298, -0.003988308266912398,
      -0.0020001320407644772, 0.003919844269731731, 0.0035476168165869885,
      0.002866796131104496, -1.2574415589853e-4, -1.0633903563027819e-4,
      -0.0024466949029329083, -0.00600002530273089, -1.9683842199777688e-4,
      0.002007661702152222, 0.004205123146863485, 0.0028291017882387695,
      4.430762098863937e-4, -0.003697355420469333, 0.005930479649729902,
      0.003645320714209527, 0.0034661741238884813, 0.004543550774759587,
      0.004919605922709187, 9.672115648546006e-4, -0.003765621913318611,
      -1.1003563349349194e-5, 0.0025173219747737408, -0.00439479339197991,
      0.0033706306439128452, 3.734976493314752e-4, 0.001116193085990559,
      -4.814723157430191e-4, 0.0038550258210879344, -0.004176900668467865,
      0.004297695811842223, 0.010514288204714083, 0.003349649926117364,
      -0.008039100224901789, 0.009824527801608751, -2.689048658258454e-4,
      0.0034518622530121325, -0.003798668356725307, 7.995439007152745e-4,
      0.002116694050721941, 0.0010702264361807116, -6.77899351946678e-5,
      0.0012203791855329856, 8.628469887790372e-4, -7.479221298313566e-4,
      -5.336789002352429e-5, -0.0014474235807373876, 0.006761161696877592,
      8.527826229416551e-4, -0.002703583821407668, 7.791751804669743e-4,
      0.002237523226880492, 0.00231424801317309, -0.007240741325522158,
      0.0013881923361637638, 0.0026279210338209956, -0.0022027058915468443,
      0.004104822679025736, -0.003068649861802436, 0.004175750091344982,
      -0.004761689306289405, -6.542095061995896e-4, -0.002939701185042897,
      -0.003173514072988014, 0.003580693641809455, 0.0039102481387909595,
      -0.0015994430593115074, 0.002521137533202927, 5.30422727822892e-4,
      -0.002282015112880788, 0.0017883742222338257, -0.0050601851700965116,
      -0.002603438572968062, -0.003066851412259314, -0.002063479393015954,
      0.0021280744887670682, 2.829110190527913e-4, -0.0017125378713005296,
      -0.002534281252697259, -5.211119402322677e-4, 0.00247169571933072,
    ],
    [
      -5.693421389366003e-4, 0.0029369043761346635, -0.0020968931131226678,
      6.075048393710158e-4, 0.003694578820385256, 0.005036807343210433,
      -0.003860278276538243, 2.155309052347723e-5, 0.0024824289999191545,
      -0.00271901860084615, 0.003009618016471865, -0.0046786941362664475,
      -0.0031071943947452094, -0.003362756519872998, -0.0018040950604672167,
      7.991326848643046e-4, 8.314597343696444e-4, 0.006471606418630345,
      -0.0022039754656487465, 6.498827007350788e-4, 0.0018580386852821487,
      -0.0026096020052666456, -0.005517107125144104, 0.004647516146612692,
      -0.0015155012830324562, 0.0016289175518463446, -0.001257659650214928,
      9.9251300279306e-4, 3.5250827118328337e-4, -0.0027668427352499064,
      0.0021237993735355436, -5.663921591879456e-4, -0.004072210182841115,
      0.00791780300653071, 0.0016803352135120833, 0.0017208367134793619,
      -9.057020397236424e-4, -0.0037661850216768565, -3.479163282852581e-4,
      -0.0011643573346886724, 0.0034727596121657237, -0.001983506024325802,
      0.0012651027611445401, -0.0010465177049075727, -0.002552577070351612,
      0.004130123635037286, -0.006583079267392019, -0.008070338016395343,
      0.0029969504663198304, -0.0037139420520362516, 0.0016773170768613738,
      0.004001006461918024, -0.004913802436099293, 0.001225331394431981,
      7.474458272829774e-4, 0.003367136326724242, -2.7483814502284e-4,
      -0.006719818701529601, 0.0069500739941631175, 0.005410567074508615,
      -0.00851664789132057, -0.00413409506698882, 9.771777711493586e-4,
      -0.0036435534641991174, 0.00843169691258697, 0.005787179317063601,
      0.001078060612169094, -2.495872463217476e-4, -0.001497536229693847,
      0.0032901762045384284, -7.708743021751785e-5, 5.607821235522829e-4,
      5.182038444539637e-4, 0.004628536798492074, -0.0027833565254398035,
      0.008250528023111142, 2.8858293424108708e-5, -0.005308679221227821,
      0.0025336282949496216, 0.0020300521973949334, -7.462481074226705e-4,
      0.0010371637400016774, -9.114569482286893e-4, 0.003237277609700268,
      -3.6306186289561545e-4, 0.007593692390820016, -3.872871641270807e-4,
      -8.558567492380599e-4, -3.4506628135626554e-4, -0.002620697924176665,
      -0.0036932624721491055, 0.002219427826581721, -0.001660736951361168,
      -0.002168961392299748, -0.0027297067098317716, -0.0031627234196529744,
      -0.0010552471737493739, 0.007898279148091438, -9.49205764829135e-4,
      -0.002828691985484231, -0.0010819124253207118, -4.6917415288779427e-4,
      -0.001689265131139715, 0.004805160717917003, 3.7619522906000345e-4,
      -3.7613901598365076e-4, 0.001460129663321665, -0.0023643123506736914,
      -0.003964169406639087, -0.0020735144530990676, 4.1757689678178426e-4,
      0.004382762296877478, -0.0020209612370508793, -0.0018493612870507197,
      -0.002865297062104007, 3.3028718649194034e-4, 7.085547987665319e-4,
      0.0015527034461607194, -0.006391909196204763, -0.0015473111472405954,
    ],
    [
      0.002456980601891607, -3.60577915454677e-4, 0.009035461634680244,
      0.006494276674342417, 0.008164972980776366, 0.005199887939109603,
      0.00386123227707604, -0.0017637967779733149, -0.010942525994041202,
      0.0022516729667059975, -0.004253420590469488, -0.005426154580893426,
      0.004480790696629029, 0.0029869464991360345, 0.0024972994388550874,
      -0.0060830304553118365, 0.001607599874584962, -0.0013162487639583873,
      0.0015317893639044313, -2.0386955282322667e-4, -0.001629811518479731,
      -0.0025870413139497837, -0.006199203650687105, -0.0017423406062576801,
      -0.003825590074700877, 0.0038032226517531525, 0.00759277491378929,
      0.0023439403534582007, -0.002304630694505075, -0.002619937844460715,
      -7.93711405457444e-4, 0.007059751890263512, -0.002154064014957054,
      1.0088129178073175e-4, -3.76850588707504e-4, 8.463969662463101e-4,
      -0.0014903774071042342, 9.28306581742142e-4, -0.003207660763587816,
      0.005830157776774415, -0.0036852022060467737, -0.0016341830895330312,
      -0.0035468447240156013, -0.001505232953392309, 0.005546285042778074,
      4.465274022116311e-4, -0.0018325507948470515, -0.0028018737294680292,
      -0.0018051838684770664, 0.008798549478307334, 0.005674138837895169,
      0.0018787632810760636, 3.1109454305922946e-4, 0.00198615172501752,
      -0.0038454022768926155, -0.0014008414378391609, 0.003639821845133586,
      -0.0020779272984708884, 1.7849378901063764e-4, -0.0060720178061911085,
      -0.0030820736916290477, -0.001774070444474716, 3.2843162307769334e-5,
      0.005803056469349914, 4.2809129767238036e-4, -0.002214578028263532,
      -3.3084706564706347e-4, 2.2710619926531497e-4, -7.427936402117082e-4,
      -0.002878448049876063, -0.0034882417276027597, 0.0013950678474042673,
      -0.0033405120797994183, 6.173659224416449e-4, -0.001330344766763871,
      0.009244945320050182, 6.083247335520699e-4, 0.0019711887267655935,
      -0.0011397631685856311, -0.004649555949827391, 0.0038898208883251235,
      -0.0038145081454118617, 9.703480172973757e-4, -0.0020990506532637216,
      -0.005584428260747031, 0.0013159821591959024, 0.0026238119926064053,
      9.35534625953448e-4, 0.0030996776704248583, -0.001673936998575415,
      0.004977839438294046, 0.0015794133621530877, -0.002976297316424274,
      0.0013170087041006928, -0.0030087104534928474, 0.004708404904908172,
      4.92857541800729e-4, 0.0016761801401010987, 0.002768898212340595,
      0.005141630104896053, -0.003796901399273107, -0.0023135243892923674,
      -0.0017132515530777523, -0.0018739234413398787, 0.0010957874352974344,
      -0.0034861544102238, -1.1585262904230246e-4, -0.002385239927752892,
      -9.080324036056819e-4, -0.0038907755415055817, 0.004302236855973734,
      0.0059869106213047705, -6.420567541215554e-4, 0.004309206154403793,
      -8.4486433457836e-4, 0.0032453181808515755, 0.002713839129369362,
      9.880087362708115e-4, -0.0024006378246552045, -0.011256759521823263,
    ],
    [
      0.0019241890385431329, 0.0028301378591698775, 5.333249725021968e-4,
      2.444691363538605e-4, 0.002912570964558297, 0.00808260757376637,
      -0.007510001938088354, -0.007896893667040255, -0.005273387312725071,
      -0.002104506478962935, 0.0026315565556556874, 0.002652208691031493,
      -0.0034736691791937236, -4.94600163886965e-4, -0.0034379392811378206,
      0.001551214938988856, -0.002962995031872149, -8.165431533027454e-4,
      0.0020571256274324267, -0.0015474879879026767, 0.001021979813328972,
      0.012397589152602646, 0.0017215772813352374, -0.001295136241585538,
      1.5824430931461668e-4, 0.0021385812240021416, -0.0024116470610798715,
      -0.004014039332121798, 0.0015382763774158654, 0.0018852433284286567,
      -0.0016527469055565352, 0.002174945249551763, 0.004278053814512658,
      0.0042859931204610735, -0.004380999723402707, -0.00889835021668871,
      -6.287660200469546e-4, -0.004459328970251232, -0.0012667876492927106,
      -0.0019879490722925185, -0.0010069481622075453, -3.839896383583248e-4,
      -0.0017534789866345735, 4.366268082925328e-4, -0.0023262763946462197,
      -0.003411913076620005, -9.104232250093304e-4, -0.0016745952130183188,
      -0.0010060471501012484, -0.0030824659607894458, 0.0039812498129525534,
      9.118721517698561e-5, -0.009134129605572174, 3.4727977229947365e-4,
      -8.021257286497946e-4, 0.005117586851906889, -8.403522726354608e-5,
      -0.001886326424516325, 0.0033746905247947602, -2.340996765496164e-4,
      2.378868659422678e-5, -0.0026617432321612523, -2.1010760064086957e-4,
      0.0013004199349983005, 0.002522075711238276, -0.0013440208233733435,
      -4.94853607428574e-4, -0.0014091092580576026, 0.002629553881767413,
      -0.005298159648703669, 0.001925280717042554, -0.0017650746777139593,
      -8.075145243000425e-4, 0.002094306400631139, -0.0024292484809026547,
      0.0010423249918452022, -0.0013741467768121798, -0.006778659761395961,
      -0.002749002112703575, -9.204103770159903e-4, 0.0038143498156524517,
      -0.0016352051395551754, -0.0018956672062175363, -0.007277626821528639,
      0.00350291404443794, -0.01035704739311765, -0.005739700001437741,
      0.0014407236671008634, -0.011680594385312795, -0.0027434270309842973,
      2.8658941187863197e-4, -4.122036608808529e-4, 0.001320259245959679,
      1.0637452188631507e-4, -3.7592995803660144e-4, -3.1617179211381494e-4,
      0.001469873044107028, -0.001085212047911668, 0.0015498449539766107,
      -0.0026232612228494524, 0.002816647972783566, 0.003047877265516694,
      0.00229826198256956, 5.855647293870765e-4, -0.005335842576780592,
      -0.0012120000114151236, -6.750830010086756e-5, 0.0011415942520980546,
      0.0035052674914853995, 0.003645417199555273, 4.003892334161851e-4,
      0.0012086585397485762, 0.0011789041768363916, -0.0025904625424918197,
      -0.005691745857980796, 1.0458477927315784e-4, 0.001003209915589587,
      -0.00546513778371643, -0.0018991120944143241, -4.1466225124720733e-4,
    ],
    [
      0.006713654398842531, -0.0028093983620194377, -0.001511592733428056,
      1.4169727440451027e-4, -0.0032375430758036982, 0.0029511166601398214,
      -9.897568858638853e-4, -0.0012945439161418935, 9.924501180057986e-5,
      -0.0028023199879603153, -0.002803928275887072, -0.004800593014555751,
      -0.00430304633807143, 0.0032236039960814537, 0.003027143981912324,
      0.0011001860762860282, -0.0020894971536311296, -0.002010213145175816,
      -0.0013562235754200833, -0.004336578851389691, -7.183771635920261e-4,
      0.0012408673362640148, 0.0013922737894160276, -0.002644747761919073,
      -0.0026529352354462766, 0.007116444615348786, -0.0022903861232217116,
      0.004164344223246989, -0.003961343594910178, 0.005008069536123604,
      0.004801138125907807, 7.895791748956957e-5, 1.732497630405988e-6,
      -0.0021523786513776514, -9.289721784485304e-4, 0.004043383455311562,
      -0.004950796007810639, 0.004534789034244776, 1.509055369055833e-4,
      -0.004663256091841695, -0.0017736765158087898, 0.003929242308593829,
      2.946598256223248e-4, 0.0018268638951940113, 0.0016175743244069081,
      5.883288717937886e-4, 0.0023913679371436077, 0.0012011682213836099,
      0.006256047658339154, -0.0016453599225104885, 0.0043612290025227506,
      -0.002140250087174068, -2.3648731195398062e-4, -9.447995776281969e-4,
      -0.0030443726715400927, -5.184758886049529e-4, -0.005288187824001093,
      -0.0011276492770045158, -0.0047977444716539985, -0.0014000124077423069,
      -0.001478174393857377, 0.0015063274821218282, -0.0042228754225923735,
      -0.00107040190805021, -0.0013344618422380496, 0.004163907525209221,
      -0.0011211498738796265, -0.0038378079531723778, -6.989897074470043e-4,
      0.0026541613264892973, 0.0011315413784281764, -1.6968100267417892e-4,
      -0.0015123135615405098, -0.004426865063147051, -0.003703483585105039,
      0.0011218335923026549, -9.13093390316838e-4, -0.005962982073775311,
      1.3139763435580614e-4, 0.006363240177783037, 0.004881194084078046,
      -6.193140709603596e-4, -0.0018100688008101207, -8.008455365517563e-4,
      0.0010719737715188789, 0.0026059270705525196, 0.006646036206046008,
      -8.468606127765104e-4, -0.0020365124195394846, -8.892374109399986e-4,
      4.8342510629926903e-4, -0.006950802494714876, -2.713541619364489e-4,
      -0.0030624138906384132, 0.0025326437115383055, 6.533089436489469e-4,
      0.001372310426274245, 0.004054377132656788, -8.881399090663836e-4,
      4.6510234189391515e-4, 0.005354874467551233, -0.004838763290451459,
      0.007754640514811542, 0.0021708256216346642, 7.716223227867743e-4,
      -0.001105533205112211, 0.005030452697808758, -0.002365977065277967,
      0.001652752773403867, -0.0020010848681236756, 0.0018397472651487377,
      4.554224407450525e-4, -0.0012415098210265367, -0.006287675937528848,
      7.91267621252678e-5, -0.0031184263238317796, 0.003980843434485152,
      0.0014455516456121755, 0.0018193270244926335, 3.649834595306771e-4,
    ],
    [
      -1.385556364822116e-4, -0.0023392425745897517, -0.0010435640789131914,
      0.0034625023992687783, 0.0012092515173159363, 0.0010010011120387854,
      -0.003614163640591565, -0.005171072661314051, 4.0003636512896476e-4,
      0.0029944756439314853, -0.0033701043165365588, -0.006307714368352366,
      -0.004509132582673091, 0.0019498164875807926, 0.0014550535171745744,
      -7.193607213739034e-4, -0.0011233235405878742, 0.0019559481228906546,
      0.0023955619995543265, 0.007490663183878504, -0.006358284869153728,
      0.0013607713973960447, 0.0020391709641060136, -0.0035820008761287547,
      -0.0018038783224525465, -0.0031564331277450985, 0.001839183259665062,
      0.0011415719555275703, -0.006437565630027143, -0.0021097404145275786,
      -0.008406572341421305, 0.00644023363336364, -7.783131717368631e-4,
      -0.0068203145585169645, 0.005712951453557215, 0.0032261537018128195,
      0.001063205346943964, 0.0014190439133993267, -0.0010743938690269085,
      -0.0018983198165594267, 0.0016424719271483051, -3.591794017156131e-4,
      0.0012523962045790683, 0.0013749163492681586, 0.004670716106648596,
      -0.0024862543451690555, -8.493998740962363e-4, -0.002755349694264133,
      -0.001789244652045002, 0.006108453741024228, 0.0010171513180790644,
      9.381976409310326e-4, 9.510488001190602e-4, 9.359920418130258e-4,
      4.5959899104735526e-4, 5.40872659421709e-4, 0.002183117227139619,
      0.003107652478276655, -3.4314688788524597e-4, -0.0014974178697880346,
      0.0010238268864443184, -8.562068327095813e-4, -2.7214478409413363e-4,
      0.005521574433167695, 0.003311214219184293, 0.004826637167596005,
      -0.002617943136999916, 0.0011085805510737206, -8.977752988546211e-4,
      0.001227620863844068, 6.43321516807505e-4, 2.0983402582372586e-4,
      0.001281059629043263, 0.001068797251000255, 1.947933959050644e-4,
      -2.1008313558190864e-4, -0.005229648055327241, 0.00246731186679518,
      0.0031080852160874886, 0.002093400914253382, 0.0024256453173800187,
      0.003610431418871322, -0.0035913950610843336, 0.005178231847697243,
      0.002415351870724245, -0.0025938496241273935, -0.0012114668700042163,
      -0.009310807527642587, 0.0057141029138370394, 0.002187880196023773,
      0.0013394519452933373, 8.30545031037854e-6, 0.00993543182917708,
      -0.001809626601076021, -1.562002361064104e-4, -0.0012398442089790913,
      -0.004653418531715524, 0.0021599334619276, 0.001639347947284275,
      -0.001640347340530501, 0.0032393784588613, 0.0013615824506947455,
      -0.002479629586237166, -0.0021275608710878222, 0.0012796976032023017,
      0.0032196253375509935, -0.002437440187577675, -0.005973761501240217,
      -1.8262435615009161e-4, 0.0024116722374736025, 0.0018906888542678428,
      -0.0012007144547188787, -0.0012722311130424265, 0.0013927352200652178,
      -1.2792394969676795e-4, -9.675514171180114e-4, -0.0014646689883666237,
      0.0023932339353991503, 0.001276999423091772, 0.0035366992449569878,
    ],
  ],
  S_M: [
    [
      0.96983945524726, 1.0270688238275394, 1.0002901540563904,
      1.0009227468910638, 1.002006284511301, 0.9982555145370164,
      0.9922487480720219, 1.0167391959514245, 1.0423468670906666,
      1.0272056016758717, 1.0358521326090449, 1.0143323631850827,
      0.9776231391942107, 1.02109740785783, 0.9857742135287448,
      1.005316461982881, 0.9996370635571179, 0.9866100821265005,
      0.9906908544366764, 1.0062044539433979, 0.9859617316184832,
      0.9924741382037409, 0.9955730783085341, 1.021750681688421,
      0.9993707393657336, 0.9966855851118883, 1.0202492857177954,
      1.0146092781547713, 1.004635305332615, 0.9843541314007445,
      1.0261462673000388, 0.990941340276672, 1.0019473188747292,
      1.0245350070194301, 1.003556429356113, 0.9953737628124804,
      0.9767620025000796, 1.039086715120828, 1.0200339971645147,
      0.9772858768640721, 1.0093002879157391, 0.9967969542649089,
      1.0101497280645024, 0.9968228477225825, 0.9924083987286936,
      0.9814308509257895, 0.9942525362898431, 1.0089605044433365,
      1.0278705802659545, 0.9801319925975505, 0.9757507866867052,
      1.0284991920882778, 0.9854119748696156, 1.0046935994191526,
      0.9900639631150303, 1.0061185794704035, 0.9831662478414114,
      1.0117229928525089, 0.999222206273923, 1.027830980117102,
      1.0071315001680974, 1.0224396292931321, 0.9465729437861756,
      0.9855837653468649, 1.0081713833187886, 0.9818191512334788,
      1.012718291292178, 1.0119204052091664, 0.9732838747309756,
      1.0108177252508992, 1.0140762148392337, 1.0110977501881988,
      1.0096091558349023, 0.9872630830990864, 1.0041803841299148,
      0.9871193649309772, 0.9759384640775541, 1.0151956817351229,
      0.9989563299888722, 0.9846916732710081, 1.01357888837701,
      0.9786793459949665, 0.9938747522700332, 0.9999202234924396,
      0.9902704449381766, 1.0258834844928504, 1.0023995027137917,
      1.003156826054632, 1.0152669877204312, 1.0182013326284078,
      1.0017012805666525, 1.030867804480068, 0.9943730846558052,
      1.001266566145028, 0.9811640421992889, 1.0375553977652552,
      0.9875285375852849, 0.988951156458268, 1.03277238552841,
      1.0244548833668743, 1.0056336466951465, 0.9813739759662856,
      1.0123603986198983, 0.9835160218191932, 0.9944571047823194,
      0.9811264916537241, 1.0030471708975464, 1.017210145751572,
      0.9917571146695193, 1.0000801832375856, 1.009783795760887,
      0.990918285511228, 1.0079516937415418, 0.996687491433577,
      1.028761258244777, 0.9971337812860264, 1.0151258006220345,
      0.9692525195633106, 0.9887380675339121, 0.9850000875346558,
    ],
    [
      1.0065672881849081, 0.9834080446787539, 0.9617348709897653,
      0.9925326296092278, 1.0290188121811992, 1.0259930112310898,
      1.0537679770357373, 1.0193162891010827, 1.0003849908431128,
      0.992409409492242, 0.9898237338089371, 1.0086178863671846,
      1.0182473148632991, 1.0071985093614146, 0.9913779068977393,
      1.0226719974487595, 1.0175996735056265, 1.0004477846806061,
      1.0096220757528074, 1.018759163964952, 0.9984360630970769,
      1.0317627018807907, 0.976750703033141, 0.9894430929933589,
      0.9729344955029389, 1.0027601753915267, 0.9955878549937536,
      1.049930008107515, 0.9891576680241676, 0.9881735907750999,
      0.9912487764030693, 0.9924592745496235, 0.9745394542912966,
      0.9938372837245987, 0.980777177737233, 1.0220580758961646,
      1.007979646526628, 0.996787271229207, 0.9844258679993025,
      0.9912551139233843, 1.001891591328333, 0.9655491077907038,
      0.9667717000913593, 0.9995908232425934, 1.0023685904266766,
      0.9562887317940458, 0.9972204299191755, 1.007836311939995,
      1.0298772124024025, 0.9914880624196345, 1.0169541554884667,
      0.9901367677115, 0.9959489285119021, 0.9853767000632607,
      0.9870249013855823, 0.9646817502343334, 0.9760099596260547,
      0.970910580872119, 1.0010344815989227, 0.9876238989953001,
      1.0061237370491274, 0.9928182157029335, 1.0350017166434196,
      0.986953779903361, 1.0008770435681644, 1.0036330331748744,
      0.9890486713272311, 1.0083175713790486, 0.9928099397713699,
      0.9946531511624739, 1.005939438931174, 1.010496132073804,
      0.9774283596712854, 0.9831124564324851, 0.9961273389395631,
      1.0125387326213426, 1.0335115214126764, 0.9770874023172301,
      1.003016212159816, 1.016227243964277, 0.9869621839374485,
      1.0111863346463723, 1.0171474272264365, 0.9515113924436043,
      0.998721991287266, 1.0458918575581369, 0.9930182683913689,
      0.9878767758966631, 1.0096052751213667, 0.9812407778919165,
      1.006544481956452, 0.9895720015071365, 0.9685021748030246,
      1.0290764812651458, 0.9995877886823772, 0.9833485471871346,
      1.0182480047251241, 1.0151429221023163, 1.015538425581369,
      0.9852478821199909, 1.000833200009398, 1.0743027006929151,
      1.019994973296427, 1.0172111174106213, 0.9960544974349642,
      0.9959094817038636, 1.0340271446077054, 0.9716892896562174,
      1.002403904531319, 1.0022339071110071, 0.9789089289931815,
      1.003488341242601, 1.0020989805587792, 0.9951330404879561,
      1.0256246286230901, 0.9692736486097361, 0.9949223525395083,
      1.013811464665499, 1.0079954117788033, 1.0361884251760705,
    ],
    [
      0.9880054284894161, 1.0094864140014903, 1.0271364310762383,
      1.0019007663854742, 0.99934164133065, 1.003489580438271,
      1.005035831176449, 1.0227035980836665, 0.9866895317800299,
      0.9928485935825422, 1.0037344889232875, 1.001917488163342,
      1.0367292483647788, 0.9908057015828021, 0.9970326359313131,
      0.9997570418633814, 1.007258855394366, 1.0007590870555354,
      0.9823476157774521, 0.9989027709895049, 1.0016674582505125,
      0.9720735014968394, 0.9942870926316799, 1.0107322377674643,
      1.0038554824886559, 1.0046254281023232, 1.0219492337222778,
      0.968544046761254, 1.0017104842918994, 0.9792369173583397,
      0.9895252219884441, 0.9942044744231344, 0.9940131523967772,
      0.9962735144357604, 1.0001873750935237, 1.0081866153701684,
      1.002706334004908, 1.0228905255387746, 0.9784338252176233,
      1.0081685855875135, 1.0041768933476722, 0.9615236803609059,
      0.9912731170814391, 1.017436781150837, 1.0151248576235494,
      1.0063166791407396, 0.9930306419551036, 0.9997737002587403,
      1.0404706088978957, 0.994170741046788, 1.006822103869632,
      1.0112859964552885, 1.008646008427687, 0.9862116716248157,
      1.0029153660097014, 0.9925377127451755, 0.9928034053113348,
      1.008133118618298, 1.0121830419496163, 0.9920569633385076,
      0.9852796035497549, 0.9898326232397838, 0.978896441274015,
      1.0097708746319838, 1.0108570237443946, 0.9992490748576113,
      1.0143920982376748, 0.9921370732499688, 1.000000414919341,
      0.9855920947262785, 0.9837959440321064, 1.0094253290775455,
      0.9919981035058276, 1.0267043295533405, 1.027028791002011,
      0.9834184751811752, 0.9872567434010221, 1.0180187277297594,
      1.0154670653581719, 1.0138279641440615, 0.9830657419425237,
      1.0145314197708948, 1.0042223961535615, 0.9846559896106521,
      1.0059052342750157, 1.0089225220929194, 0.9561313856655547,
      1.0150761328130233, 1.0000901682136603, 1.0079646637831288,
      1.0096758324386716, 1.0164915872665345, 0.9875475250365336,
      1.0063053801173343, 0.9735568199982436, 0.9912611698867585,
      0.9823907487811341, 0.9936777224970498, 0.9978388267329253,
      1.0032033633421475, 0.9959198818595991, 0.9697232021315598,
      0.9879385672896166, 1.0159912566730078, 0.994738672732617,
      1.0091130584970407, 1.002570285890208, 1.0072780098521898,
      1.006152121686239, 1.030300052335824, 0.9736390917281597,
      0.9910965131609284, 1.003518180845596, 0.9615358251636859,
      0.9754273423709321, 1.008014630048895, 1.0049186169941233,
      0.9964449717489214, 1.0035517477781275, 0.9873219859421762,
    ],
    [
      1.031403608576861, 1.0313721090977226, 0.9944446983622459,
      1.000709266877889, 0.9836121420216153, 0.9921260654380675,
      1.0184821704503058, 1.0129879966539552, 1.0389340435333718,
      1.0091900129804707, 1.0096538370121422, 1.0033654765595577,
      1.0128203821722501, 1.0048825814708442, 0.9873256929637333,
      0.9919243286567327, 1.0211308804065031, 0.983633370744716,
      1.0179423719254623, 1.0133937981382257, 0.9874844080124129,
      1.0205348051373169, 1.0088317533059055, 1.0017721015164618,
      0.994279218428979, 0.9839242869086617, 0.9966792017615811,
      1.0146148752459045, 1.0151137497516434, 0.9976631105988931,
      1.0061540403686857, 0.9979046782919212, 0.9964270131834578,
      1.0101294535406784, 1.009373703613074, 0.9956876266666204,
      0.9846789371642704, 0.9757404051837034, 0.9887420560324678,
      1.010942146022364, 0.9830632914446591, 0.9798581749200773,
      1.0014281849905635, 0.9739819641551809, 1.0117448859135278,
      0.9764666946548752, 0.9982202109885392, 1.0011278610519743,
      1.0280029097167036, 1.0003007581133407, 0.9984368471626868,
      1.0256262346226173, 1.023124632180891, 0.9994693462948159,
      0.9736661757411686, 0.975934667811424, 0.9999124354594419,
      1.0206013553111783, 0.9953886349866341, 0.9917232050362046,
      0.9955866810902159, 1.011382646563053, 0.9990814691041631,
      0.9919281219809732, 1.016633136656323, 0.9694911749599513,
      1.001470935992895, 0.9965366605915058, 0.9953282507323287,
      0.9358978399149027, 1.020188763534519, 0.9869189729290763,
      1.0273815453988062, 0.9908672519914393, 0.9856996716265771,
      1.0223543129832122, 0.9698933352590786, 1.010510456044967,
      1.0021661143187548, 1.0193296005984973, 0.9821507595679966,
      1.0055411671680181, 0.9987966427904189, 0.9743582304167325,
      0.9942285330861756, 0.977088995585816, 1.0059139511943898,
      1.0125981461734832, 0.985556889345558, 1.033066580213929,
      0.993389622474318, 1.0094515145371625, 0.9978025445726864,
      1.027111378728926, 0.9852097614525959, 0.9939776162311463,
      0.9950913789997158, 1.004333079594864, 1.0311251996882085,
      1.0071258458223995, 1.00203129563875, 0.9994074218875315,
      1.0052376423919869, 0.9835019656954715, 0.9973688105509784,
      1.0086125235937364, 0.9966132497324943, 1.0184143774151062,
      1.0172079656603588, 0.9946786989445631, 0.9970008324887063,
      1.0062057268405453, 0.9953676778815412, 1.0041493153115408,
      1.0410367355136638, 1.0100355841857338, 1.0121862708833298,
      1.0272566902362994, 1.0157675338422716, 0.9945727458422517,
    ],
    [
      1.037415585831254, 0.9904874980184317, 1.009105863126835,
      1.014283800024309, 1.0175723332338058, 0.9895479005138578,
      1.0308449961593769, 0.9976124786397602, 0.9977830006467812,
      1.0015303144787144, 0.9999197224387864, 0.9966159706167361,
      0.9725545615077544, 0.9993290637857353, 0.9972446124914227,
      1.0231542663709448, 1.0046705956682924, 0.9945930850907145,
      1.0215525824969798, 0.9939321613880021, 1.038607768182111,
      1.0045082554872513, 1.0102884334522704, 0.9942885712849505,
      1.0158395418907977, 1.000646116387549, 0.9714560110128758,
      0.9914203352132593, 1.0147279971341594, 0.9952966054897232,
      1.0157801188540252, 1.0190237848065715, 0.9819378109808317,
      0.9889928601529706, 0.9937051720446655, 1.0058464323525107,
      0.9934370184248578, 1.0214601133030283, 0.9928757427661624,
      0.9940565016012429, 1.00041170591739, 1.0182450786959052,
      0.9926806666721428, 1.034926907006469, 0.9865212666723242,
      1.017422462283736, 0.9769667949193983, 1.006890046085816,
      0.9974260202231638, 0.9872053765344351, 1.0150006048361186,
      1.0078007142652616, 0.9700663959912672, 0.9964070734976862,
      1.0046096070769184, 1.0046279018794522, 1.0083182190603932,
      1.0245012001481757, 1.013051577085901, 1.0086326493249667,
      1.0067696474853665, 0.9900241921128949, 1.0107609622009934,
      1.009218645601631, 1.000940949842811, 1.0301473822755336,
      0.9884589583717645, 0.9921025323670145, 0.9606110654526676,
      1.0169023725376083, 0.9979090731602767, 1.0007486478779726,
      1.0090845249696194, 0.9840402214036132, 0.9897809339303345,
      0.9920678875230087, 1.034892803532606, 1.0073462823239598,
      1.0018502917353538, 0.9919650073086521, 1.0309131305670263,
      0.9902690201613848, 1.0192074438743215, 1.011335328438674,
      1.0075091547597181, 0.9910561665240212, 1.0342152203206503,
      1.006458640510765, 1.0193166590068246, 0.9788769874726851,
      0.9812011860729697, 0.9868353757027348, 1.014911480280021,
      1.001171384979537, 1.0060791258370365, 1.033661644104976,
      0.9847845707751177, 0.993961924005855, 1.0116403746607703,
      1.0103481721327, 0.9897247486894485, 0.9878674299678972,
      0.9894426320368421, 0.978308694146095, 1.001720269127964,
      0.9966911993659266, 0.9994702173905256, 0.996499744860811,
      0.9789187285820378, 0.9868781273533371, 1.0836909443048437,
      1.005627738589635, 0.9794816553762784, 0.9998257346295344,
      1.0099762060235875, 1.0053126808173403, 1.000236897497442,
      0.9963055951381692, 0.9694128714321395, 1.0064594645742642,
    ],
    [
      0.9911961580861544, 0.9701325079932381, 1.0028271289453308,
      1.0190158242174245, 0.975282305940101, 0.9878917544662081,
      0.9973302481757179, 0.9742297719336792, 1.0098649646197542,
      1.0237881665173667, 1.0129158639528941, 0.9802901685710634,
      1.0264142747870357, 0.97517263958975, 0.983952282773602,
      1.0073579883759578, 1.0036330843735752, 0.9985938477904815,
      1.0266604996772002, 1.0265379699931967, 1.0130131673875629,
      0.968911137907503, 1.0182616728713678, 0.9776170110901421,
      0.9816309048120958, 1.0083536695634039, 0.9966476454369306,
      0.9787885219748923, 0.9937331620037744, 1.001132416794587,
      1.00827304648764, 1.011259191520417, 1.0148890516382996,
      1.0107581716920857, 1.0096946066541213, 1.0157918260811338,
      0.987559192726133, 1.0107512861351178, 1.0144018074206573,
      0.9891037739134798, 1.0372034500124976, 1.0149607773839657,
      0.9878572552894749, 1.0065551852426071, 0.9853015178796368,
      0.9730090345815462, 0.9950948619289773, 1.0240005439668636,
      0.9734794778567764, 1.005288561226558, 0.9934660757740204,
      1.0046726227568217, 0.9912495444131217, 1.00212847323048,
      1.0160082093753875, 0.9949745642011423, 0.9844988188967521,
      0.9904606940432276, 1.0332025344682496, 0.9964456502814236,
      0.9991847577540292, 1.0013439616005138, 0.9740037785411702,
      1.0072144408654395, 1.018615184435559, 1.016371855215326,
      1.0286076535046922, 0.9346327737445448, 1.002521207830847,
      0.9790821099389158, 0.9890693862959272, 0.9957312731996664,
      0.9969074881662572, 1.0139133718417266, 0.9979872350206125,
      0.9989177629788847, 1.0412728740759678, 0.9862283096008989,
      1.033640519355893, 0.9853426684030735, 0.9767972263328787,
      1.0142059569646245, 1.016032479461326, 1.0047306202471198,
      1.0020096703729293, 1.005106232440808, 0.974667128391437,
      0.9901921852701003, 1.0019176929626792, 1.0196438457626165,
      1.0178328142489572, 1.0054266671333374, 0.9868772128038322,
      0.9816022282525373, 1.0029534966900022, 0.9908067297923853,
      0.9938960457748647, 1.0030551842075484, 0.9910038390175213,
      1.0086377027550377, 1.0118038110729652, 0.9889570102708247,
      1.001662293858811, 0.9875275751978908, 0.9600041072653109,
      0.971502259260971, 0.9811716612793819, 0.9857682304508362,
      1.001456044838607, 1.0163286137766674, 1.003285370520034,
      1.0018964917792252, 1.0171534158820557, 0.996847168544722,
      0.994757796990693, 1.0191826957764554, 0.9443180705530582,
      0.9859675743540832, 1.0019682807303993, 0.980569218431627,
    ],
    [
      0.9958002820255043, 0.9956687377034463, 1.0054268280517353,
      1.0154574319261345, 0.9771265412011336, 1.0123009611499332,
      0.9920494445122272, 1.003556727148062, 1.0081051201983895,
      0.9804112260792108, 0.9907527188212013, 0.976599548777006,
      0.9864554381787418, 0.9805839394054092, 0.9881499534242566,
      0.986878517575829, 1.0045532262313464, 1.0420346864844197,
      0.9975332375978104, 1.0088119721915185, 0.9966906180811588,
      1.000382465559897, 0.9647791855162062, 1.0091868496878076,
      1.0067081429432871, 1.017909066137106, 1.005113048165808,
      1.0227693128936897, 1.0575413669844222, 0.9811192000177643,
      0.9958685584866718, 0.9931194396136085, 0.9818899843976742,
      0.984888847053247, 1.0287763189474433, 1.0088342900763858,
      0.9961406185701438, 1.0225424902262594, 1.010542766987386,
      1.0125064392029306, 1.0062253404480384, 0.9845367543532586,
      1.0028041798106062, 1.0506242339016367, 1.0020660296832267,
      1.0102894200829762, 0.9951209116740287, 1.005920286700174,
      1.0251443706238519, 0.9911556649440947, 0.9803024508360421,
      1.0107043754442986, 0.9911605763473279, 1.0149916275948982,
      1.0320076196405061, 1.0039760205141675, 1.0002746310390807,
      0.9823425194376866, 0.9931423875477351, 0.9848921395422964,
      1.0040704672899163, 0.9982422981721226, 0.9866969498415501,
      1.055679937825554, 1.0160538174730607, 1.0039090060330413,
      1.0215847519291303, 0.9793589227102768, 1.0021487995565908,
      1.0188685372079056, 1.0044104835073335, 1.0151907177045876,
      0.9864171915086934, 0.9956513413096607, 1.0069982074620154,
      0.9757787225124617, 1.0052565594284129, 0.9962745723290227,
      0.9907430362998915, 1.0167599415425306, 0.9892818552060094,
      0.9825689451054664, 1.004842479113874, 1.0031794947181683,
      0.9836382335760009, 1.005022792839232, 1.003620509781808,
      0.9909927166638828, 0.9892489083580336, 0.9968285770921728,
      1.0034892058751215, 0.9875811396591914, 1.001199786335993,
      1.006671446371099, 0.9982039550615197, 1.0019960173088929,
      1.0180167199359658, 0.9841818676775741, 0.9889343595568494,
      0.9695554472704309, 1.0055890322117953, 0.9920653458982911,
      1.0075127559841857, 0.9809346797662106, 1.0210312382756772,
      0.992695721165384, 1.0263319055825333, 0.9758581255784273,
      0.9938531374718861, 1.0130723307147096, 0.9655368142974667,
      0.9841527068032155, 1.0101977902929764, 0.9996996353866031,
      0.99172345367934, 0.9683892914983846, 1.0100419325206058,
      1.0128434433153986, 1.019491686469025, 0.9568073864124382,
    ],
    [
      1.0057698225938365, 0.9961438649041674, 1.003874408598921,
      1.003543805019295, 1.03527351057118, 1.0244890612090287,
      0.9892686320177162, 1.0141657281823075, 1.0070823885423712,
      0.9962451501947002, 0.995238370119336, 1.0368811187018323,
      0.9850149091509661, 0.9572720460687917, 0.9909657847401627,
      0.9936621494518817, 0.9995079496653346, 1.0124164823247979,
      0.9884573268136081, 0.9977995160965987, 1.0265750285984414,
      1.0036097830846265, 1.0160523101436607, 0.9941511139707291,
      0.9982340328894885, 0.9885002838055624, 0.9926883875767232,
      0.97236062092246, 1.0044654896933691, 1.010693133004114,
      1.0242992499714423, 0.9935128128588149, 0.9790922270968269,
      0.9966750972980969, 0.972585629109317, 0.996367882157766,
      1.0072066921351108, 0.9896726610658897, 1.0108609007107394,
      0.9987855090772042, 0.9986304609005814, 1.0055253228814145,
      1.0399920273831347, 0.9903226806875078, 1.0127139727936285,
      1.0475350148435174, 1.0116892874807712, 1.000639142681551,
      0.9887674301486351, 0.9987332474394992, 1.033617007343742,
      0.9864870291761849, 0.9995677627210096, 0.9798146133840647,
      0.9705082471219395, 0.999940858439235, 1.000131320511561,
      1.02123039712522, 1.0032876082866997, 1.0123652807021781,
      0.9939617087886194, 0.9862541299001533, 0.9991830670347017,
      1.0267798911992316, 1.002949858353559, 0.9978330741467234,
      0.9912738783796171, 0.9993859561818821, 0.9932229213861463,
      1.007596424487585, 0.9816144192965963, 1.005782019836853,
      1.0102435378086825, 0.9938175874077657, 1.0016946046706525,
      0.9898645383584925, 0.9858211808313069, 1.0102897804896487,
      1.014790752717197, 0.9822583785898098, 1.002333499206756,
      1.011121095770952, 0.9991741791407043, 0.9833083706174228,
      1.0075614472680194, 1.0002569102212702, 1.0385202950294077,
      0.9862311220959505, 0.9788169276785221, 0.9609999125308349,
      0.981817235015974, 0.9825031357847102, 1.0164537222254135,
      0.9773271274382884, 0.9972255321125836, 0.9912195174205587,
      0.9946309482618056, 1.0085116457699912, 1.0064358674101572,
      0.9887973039990827, 1.0035334844481234, 0.9979258392786864,
      0.9919167956660047, 0.9980759791550391, 0.9663059945973205,
      1.0160365457625211, 1.0114039152261451, 0.9795437842494116,
      1.0070497339782762, 0.9974938578431637, 1.0148828609071758,
      0.9848811668788815, 1.0022875022661286, 0.9966098727411894,
      1.0398230948825629, 1.0153903982770975, 1.0217972335564636,
      1.0007629649385332, 0.9844944257200952, 1.0041789938513035,
    ],
    [
      1.0078727725265406, 0.9781765020204782, 0.9685535315928088,
      1.0254582235527552, 1.017298016724114, 0.9923968168020922,
      1.0108279620036547, 0.97170365390305, 1.0009004021493162,
      0.998269313103747, 0.9894603744136985, 1.0086565264479,
      0.9818997453308977, 0.9810367087284515, 0.9913410262084317,
      1.013805480944884, 1.0016911531732668, 1.0379436533590423,
      0.9558593567576228, 0.9997196467372047, 0.9919650817544888,
      1.0156414902708597, 1.0157139771266765, 0.9793013657898727,
      1.0318019558122957, 0.9930261475819123, 1.0181121405546634,
      1.0081958611009905, 0.9913532384732384, 0.9821275270921109,
      1.0037639136879664, 1.0360850737725582, 1.0046660556860103,
      1.0103206236606486, 0.9936966061368449, 1.0190344718161368,
      1.0019774454717476, 1.0258513599817063, 1.0097094414280776,
      0.9860293493772896, 1.008677970893427, 0.9826424135012857,
      1.002263105887684, 0.9842012453581759, 1.0119437910332043,
      1.0149916970645856, 1.0489508730577715, 0.9977987003717304,
      1.0008483895699176, 1.015255749064677, 0.9907603099684589,
      1.0070605563205648, 1.0022178320415316, 0.995844824553543,
      0.9823921881224904, 0.9942410086560568, 0.9656185295626485,
      0.9721989061781928, 1.0020060225128664, 0.9874035249435964,
      1.0230949915943013, 0.9953252626557322, 0.9974228704320091,
      0.9925033371550336, 0.9746135591206125, 0.991212331358261,
      1.012982464321111, 0.9985380022758676, 0.9815822943894743,
      0.9977689496943647, 0.9985295159854796, 0.9877407588961666,
      0.9712135712460637, 1.0134857866736207, 0.9940289471232577,
      0.9961251953394201, 1.025285968842877, 0.9937521806675457,
      0.9797231836907694, 0.9751008862747406, 1.008159057098214,
      0.9963990824403326, 0.9873870440787519, 0.9980256528706679,
      0.9981104344064873, 1.0301147098874317, 1.0153088418153806,
      0.9884504263059117, 0.9932407456239581, 0.9981481715564617,
      1.0125566472574496, 0.9889832077049431, 0.9774151551855494,
      0.984507967012229, 1.0066775938912178, 0.9959955170660988,
      1.000491365728968, 1.0050270222671134, 0.9755493496379373,
      1.0070789263597881, 0.9912370744433812, 0.9436456902609108,
      1.0018986639457308, 0.9833115602905902, 1.0165002824273346,
      1.0158434430341998, 0.9964223885351179, 0.9886507586309001,
      1.0144541500579334, 0.9851530847326839, 1.0010856872288956,
      1.0350940484217075, 1.0338929771572603, 1.0198176898790021,
      0.982355233398306, 1.014044636626192, 0.9943258530505, 0.9860922411604474,
      0.965823741043691, 1.0039861882886827,
    ],
    [
      0.9983591222497197, 1.0052564429523798, 0.9864448454478735,
      0.9980600404726923, 0.9992290298300971, 1.0046412857448828,
      0.9883002889014785, 1.0269454271608565, 0.9732512639343538,
      0.9999770982435819, 1.009253063438546, 0.9972614504813839,
      0.9916775784320275, 0.9875431161152802, 1.0338830358212174,
      0.9815188460608938, 1.0061313043069835, 1.0341498138733956,
      0.9908728494350225, 0.9736778665268144, 1.0018319029092169,
      1.0277644825687835, 0.9569438573666809, 1.0222345038797132,
      1.0006824118983073, 1.002047151545647, 0.9839052099202479,
      0.997051651336528, 1.0077178335416739, 0.9926313294147924,
      1.0009166506097347, 1.0194982577108065, 1.0009388824434287,
      0.9993058461393067, 0.9645994500048981, 0.9980801366680563,
      1.0052630436469843, 1.025991103797418, 0.9987428723412672,
      1.005170918129307, 1.0009884972189618, 0.9800604096702781,
      0.939275029095329, 0.9719383996491786, 0.9999439408059487,
      1.0089153105994733, 0.9764641415730271, 1.0059213601558237,
      0.9935739403182478, 1.0169737086707895, 1.0071520710838466,
      1.0043598251585097, 0.9754358647557286, 0.9894941058131989,
      1.006337486862038, 0.9915880693710322, 0.9765218490419261,
      1.00247330066759, 1.0167068189166681, 1.0018397989491858,
      0.9921173116978654, 1.0026492161166596, 0.9972959470258966,
      1.0113285364614586, 1.008254269008908, 0.9983798912891213,
      0.9850240523829488, 1.0398447628866314, 0.9977189110045276,
      1.005869930120998, 0.9922243397478787, 0.9824465014329562,
      0.9539856218103678, 1.0147760830549215, 0.9827719614480879,
      1.0185440852192973, 0.9980764207866616, 1.021771910824066,
      0.9898176144355008, 1.0151429120905666, 1.021188029162556,
      1.011699289363258, 1.007762013428577, 1.016744318154633,
      1.0041164967057727, 0.9857222823207603, 0.9807015785116807,
      0.9704113659292066, 0.9894760093415551, 1.003331413737009,
      0.9637136231526656, 0.992385529051308, 0.9918032430614607,
      1.053998191869777, 1.012264026055037, 1.003793357455624,
      0.9976204768933808, 1.024968268473257, 1.0090322861653558,
      1.0073433352998467, 0.99626354989194, 1.003547395281558,
      1.0073916917727568, 1.0053958469904622, 0.976266670057486,
      0.9967557034088845, 0.9993196839974567, 1.0041939521726166,
      0.9864454421629063, 1.0017920024355706, 1.006690342633929,
      1.0282373268024316, 0.9905798758513518, 0.9838066775324357,
      0.9911622783323903, 0.9744075754052668, 1.0044765682363623,
      1.0177366973161628, 1.0202060427414725, 0.9798377312627271,
    ],
    [
      1.0078905270784273, 0.9936943954959578, 0.9886085297042755,
      1.0162587264984708, 0.9747634478479366, 1.0205635160068307,
      1.0370809583074339, 1.0177800126637901, 0.9901710518459993,
      1.0076009102610155, 1.0188262743518255, 0.9862533955444148,
      1.0442472010890138, 0.9979441649120877, 1.0001218915153507,
      0.9931977372955803, 0.9899413792102362, 1.0314633187878535,
      0.9819847657226504, 1.0240605657560766, 1.0199373134779364,
      1.0120244867989736, 0.966558692390977, 0.969679455338209,
      0.985847324302128, 1.0220231752987943, 0.9573952805252723,
      1.0526794803793207, 1.0147552062570733, 1.0021498578782175,
      1.004391758021463, 1.018238064650129, 0.9805180324326888,
      1.0034083308010988, 0.9858142586100016, 1.002389427568541,
      1.0149496275430163, 0.9811302965123458, 0.9779124585478094,
      0.9973204024604081, 1.00894036043297, 0.9769441841042248,
      0.9790834623093352, 0.9749198755699798, 1.0032270797338125,
      0.9920198064087098, 1.0091600733768988, 1.0047399331814622,
      0.9943871121318344, 0.996649878368755, 1.0024045669677322,
      1.0396795807797392, 0.9899493790532363, 0.9885002576721152,
      0.9849912718317267, 1.013570595704794, 0.9823267831101937,
      0.9819812279623994, 0.9887729754539439, 1.008283031436447,
      1.0289308266543036, 1.0017807907956575, 1.0187623243742543,
      0.9698386225276298, 0.9971425331739057, 1.0356723639344279,
      0.993410088717598, 1.0078804683306213, 1.003216729673091,
      0.998420297398623, 0.9555779209097939, 1.0135008125723162,
      0.9956841471094702, 1.006425190807457, 1.0417876253426739,
      1.0242477242677412, 1.0032390359864574, 0.9999873272543066,
      1.0289342652129725, 0.9697979827756227, 1.002700799488871,
      1.003879248116749, 0.9923768102212573, 1.0112665405283288,
      1.0122347784832044, 1.0029076818906906, 1.0119497344071786,
      0.9728652412791916, 0.9894966858657408, 0.9653340612992756,
      0.9696553608197246, 0.9799793327567301, 0.9944966399879382,
      1.0003933097780182, 0.9670742370719073, 0.9906629742236459,
      0.9837395524114723, 1.0070588563326028, 1.0023250293541333,
      1.0126755928020614, 1.0543575860483958, 0.9904186866751822,
      0.9993027613270979, 0.9980727675221635, 0.98982042155903,
      1.0151845300262372, 1.0124715707906766, 1.0071063527291781,
      0.96297153037103, 0.9773827676648918, 1.0002462198600575,
      0.9882251618081899, 0.9966771838347968, 0.9868130948683577,
      0.9823627405941209, 1.0157132784197547, 1.0096407887599976,
      1.0123449077776479, 0.9972121112178084, 0.9921748154268606,
    ],
    [
      0.9447631791237656, 1.0087969518468898, 1.011046118011154,
      1.0258973780922682, 1.0009282928859191, 1.0050943618916177,
      0.986748121851625, 1.0166441056473778, 0.9642619654110411,
      0.9868941581225651, 0.9969797417296409, 0.9861589376169771,
      1.001222266336142, 0.9818194781813233, 0.9970253293587295,
      1.0051803685308796, 1.0215502941919614, 0.9788960470638145,
      1.017583453325687, 0.986486556981129, 1.0150332165578297,
      0.9679584812434654, 1.0258333628650493, 1.0208207993194123,
      1.0102208822246297, 1.0101112591485975, 1.018078573634889,
      1.0140948168006607, 0.9855174551359652, 1.0285921616076161,
      0.9820524622694157, 1.0003336234897628, 1.0202875936470144,
      0.9759654931003396, 1.0058762202233558, 1.0002280123718486,
      0.9871703469085085, 0.9690594956836374, 1.0167748354566104,
      0.9920760140161977, 1.0092790804295402, 1.0107307217113717,
      0.9882283288509204, 0.9910798895160624, 1.0278076639709308,
      0.9911297328384298, 1.0295176685871386, 1.015923964132621,
      0.9954965716708032, 0.9982041067557479, 0.9733488766858926,
      0.989618276418742, 1.0050697661944943, 1.0201939466152419,
      0.9833710859557654, 0.9566377713199847, 1.0204291504502767,
      0.980942920796007, 0.9910084306883162, 1.0427778547617876,
      1.0069706099257543, 0.9401490217741522, 0.986198719349185,
      1.001051669277457, 0.9855089161018216, 1.0056074867298879,
      1.0182934272163962, 1.0023713962991607, 1.0190237269446072,
      1.0012468958378387, 0.9968643903097254, 1.0156937244952844,
      1.0095920233632454, 0.9975458300963084, 1.0042986921294257,
      0.9547044911070779, 1.0523087787356014, 1.0251123998132985,
      1.0272920511662127, 1.021490826036631, 0.9861267503913584,
      1.0083382963395537, 0.9703851518752029, 0.9785339303548859,
      0.9941853969154691, 1.0018764801020197, 0.9783054379289693,
      1.0124068825631476, 1.0043959229542558, 1.0008479658473997,
      1.019077807468551, 1.001999405968005, 0.9816564954044086,
      0.9767128082091443, 0.9438785083271388, 1.0165922565491157,
      0.9855510062376114, 0.9809050467000775, 0.969455029460184,
      0.9811747550639115, 0.9775364550884355, 1.0437000766772824,
      0.9993506416062125, 1.0222064343155763, 0.9910855666740643,
      1.0063203127345992, 1.013211234083164, 0.9880264423965168,
      1.0180060577772374, 0.9740432369493827, 0.9847770432203592,
      0.9845124903537341, 0.9993432617375837, 0.9903802570348323,
      0.9820518841787875, 0.9942566324923494, 0.9785557221148141,
      1.0187207079663694, 1.0301644956432643, 0.9857427274412649,
    ],
    [
      0.9859747935983961, 0.9963746793151758, 0.9892607368660352,
      0.9917069184549148, 0.9874851302057104, 1.014007914219453,
      0.9962703481663421, 0.9910720588512443, 1.0035522079982722,
      0.9889126965821431, 0.986902906455507, 1.0058619887142566,
      0.9942718760094609, 1.0099747198747693, 0.9807130480157463,
      0.9822952366015587, 0.9948526312287298, 1.017567748222034,
      0.973507823399584, 1.0052205264639538, 0.9709722687841342,
      0.9594941098587114, 1.0342808145478806, 1.0046101735299287,
      1.0094337731871694, 0.9974855461689531, 0.9958100114218118,
      0.9924147894443173, 1.034440860858496, 1.016919920176971,
      0.9945096657923557, 0.9852578761227809, 1.0121505932655885,
      0.9605422105632802, 0.9905453844804548, 0.994501233929741,
      1.006182147869512, 0.9965277294232862, 1.032565935324608,
      0.99948339564023, 1.0147453043169725, 0.9507825524170502,
      1.0151882607799128, 1.0370652903202813, 0.9918230216375529,
      0.9908990526445518, 1.008233062337282, 0.9854233322887128,
      1.0079203873928906, 0.9893109186620991, 0.9720017023104666,
      0.9782677606406043, 1.0141597776214344, 1.0120180831522303,
      0.9954060579507031, 1.0373729198627961, 0.9982306133304262,
      0.9740675107349844, 0.9836333424745158, 1.031722582150051,
      1.0283868583239353, 0.9992126854001814, 1.031501324521804,
      1.0623303944444822, 1.0048732335557018, 1.0108883979879337,
      0.9933188501008647, 1.003302180062123, 0.9974757579097127,
      1.0186766335119302, 0.9857465420006235, 1.0254608946396166,
      0.9940466731762516, 0.9968142294428922, 0.9902537129077316,
      1.0280711291040654, 1.0009672574769077, 0.9912309437058596,
      1.0183138931488462, 0.9684283967723375, 0.9610772324678315,
      1.0170094312111666, 0.9855027249818988, 0.9964924255163407,
      1.0129410500857423, 0.9894913968943682, 0.9962405392904444,
      0.993101568615893, 0.9696300416979591, 1.0023776080548645,
      0.9611564639132877, 1.0635908149970619, 0.9903317966121609,
      1.0080062453466732, 1.0143805700746502, 0.9781281569963883,
      0.9872956542181561, 1.0042913352603444, 0.995755238689317,
      1.0414034249657065, 1.0031386677311966, 0.9889708058954696,
      0.9972407138004235, 1.0074080837296475, 1.020871681591005,
      1.0311574514298383, 1.0002698635745166, 0.9966003236021257,
      0.9983529979012316, 1.0021726224164433, 0.9987390279453479,
      1.0036613093123976, 0.9960031598384704, 0.9698054206981248,
      0.9983113590956763, 0.9976375625193457, 0.9962437891157088,
      0.9911605127808594, 1.0106403608128498, 0.9955554789139678,
    ],
    [
      0.9872665792258941, 0.9524717883702758, 1.0139903324050654,
      1.0069256445939827, 0.9960049872388195, 1.0332834439519196,
      0.988738886075599, 0.9711950149487647, 1.008539713192386,
      1.016875500853566, 0.9797157266352641, 0.9870417211721121,
      0.968220025796735, 1.0006562513503963, 1.0226586585976465,
      0.9998859162135103, 1.0057707795364288, 1.0204479563794426,
      0.9934357510673835, 1.0476284710254542, 0.9922752880692209,
      1.0216731557925438, 0.9937934038882292, 0.9822672507809003,
      1.0058878397282789, 1.051659440095555, 0.9890240587653158,
      0.9832471028604995, 1.0028731514924776, 0.9977627574617394,
      1.0003304052366513, 1.0054718493328048, 1.0013022166641576,
      0.9951757704127671, 0.9963692064498462, 1.0018225258698623,
      0.9818270936332878, 1.0085169168029586, 0.9697217672558429,
      1.00417829169742, 1.0089403726516974, 1.007732206796601,
      1.0005305655244698, 1.0071903363269854, 0.9896630263059998,
      1.0194527138477678, 0.9992394316626194, 0.9969120671625822,
      0.9886381927086696, 1.0073643768763059, 1.0033835920308996,
      0.9832524996362946, 0.9942693777646681, 1.00046180308019,
      0.9980812058170418, 0.9743448266504366, 0.9795407213198324,
      1.0204864782931846, 1.0001021114989646, 0.9790720985087836,
      1.0352205004569566, 0.9785907536417262, 0.9875715158571576,
      1.013212127709839, 0.9888240195511641, 0.9996724994211357,
      1.0110551741142366, 0.9914054534227024, 0.968371291762472,
      1.0183623537520443, 1.0006876841207606, 1.010472351105144,
      1.0265858344486487, 0.9897973243017868, 0.9861560862096782,
      0.9983567921653096, 1.0174670264547903, 0.9994350429178452,
      1.0039064241734483, 0.982834181875485, 0.9906498516092499,
      0.9817199595298702, 1.021442769670675, 0.9765802094959573,
      1.006150058326922, 0.9994754827982044, 1.0059111794035083,
      0.9792872188733845, 1.0270450395494994, 0.9938896243933709,
      1.0270078938293388, 1.0003658209815642, 1.0029411478225874,
      1.006538571574675, 1.0190800004105967, 1.0021296461154698,
      1.0086401864287282, 1.0097904476987982, 0.9827647404559172,
      0.9789538969142366, 0.980766726312005, 1.0068952475559294,
      0.994468980839418, 1.0320848065096273, 0.9677696492520754,
      0.9829442326930182, 0.9920131392223324, 0.9845760836231996,
      0.9725949093111421, 0.9840439054074424, 1.011331545951966,
      1.0064572643085716, 0.9832036189735047, 1.002585323724975,
      0.9984751463052588, 1.0112392657826885, 0.9903472515477696,
      0.9846803055186396, 1.0227609575146661, 1.0390281191057882,
    ],
    [
      1.0295830175383145, 1.0189169271831395, 0.9975683392068015,
      1.0264218952217492, 0.9978550173223344, 1.0032196690845883,
      0.9802400106662634, 0.9898913366919506, 0.9782436140889054,
      1.0191225175985048, 1.015012013431981, 0.9975478841951446,
      1.0175788184625105, 1.016472693229009, 0.9833291138625131,
      0.9749105717849491, 0.9932691093692603, 1.0062743561649454,
      0.9872397913011104, 1.0155378389313667, 0.9824236995976454,
      0.9956769497351541, 0.98583154792443, 0.9875761559625598,
      1.0204816420556595, 1.0232935684553675, 0.9887142994076668,
      0.9953385070996752, 1.0145924991008557, 0.9705902504813184,
      1.0042240339348698, 1.0074059008263945, 1.0079873567493993,
      1.0147647845441967, 1.0170860981734582, 1.0075801934529223,
      1.0126689317467337, 1.0018522614967686, 1.0192894084985236,
      0.9872944523856116, 0.9874727179695405, 1.0008792477773074,
      1.015437542552172, 1.0089193445444993, 1.0021468413842252,
      0.9965771920272068, 1.0421711103720628, 1.0045094500070362,
      1.0263890027016223, 0.9830429300617552, 1.0019078408235746,
      1.0026401828755396, 1.0127905534646344, 0.986141513590407,
      1.016122775839999, 0.995433686148717, 0.9731214919066871,
      0.9950989342961947, 1.0356190493408335, 1.024180956098438,
      0.9945494122979885, 0.9950467941620469, 0.9957623591945362,
      1.0110780938910175, 1.0162928644337303, 0.9908030005936084,
      0.995667944010092, 1.0172893375325804, 0.9741508582254228,
      0.9973574563331534, 0.9853946702098133, 0.9903149354762583,
      1.0203500134369528, 1.002607807085821, 1.0084198447284378,
      1.0084978402356888, 0.997111993971866, 1.006744875681872,
      1.001003289142556, 0.9800218202279048, 0.9990552153546403,
      0.9999245853478063, 0.963192267254262, 1.0148619985137426,
      1.0143136553626362, 1.0420226282428273, 0.992370881616202,
      1.023459772280524, 1.0014509284684916, 0.9801072362670977,
      0.9979524528625527, 0.9771526477830283, 1.0047522972326552,
      0.9837053883620372, 0.9978038510584432, 1.0074359095790837,
      0.974202148011796, 0.989115662789344, 0.9901619620186003,
      0.9925585711519994, 1.00154246892811, 0.9651377408399462,
      0.9947089099847543, 1.019713222153353, 1.0334436268575289,
      0.9816412812838411, 1.0216764635030122, 1.0338102990024778,
      1.002355124301034, 1.0060281342094772, 1.0322346236084523,
      1.009257263709998, 0.9823153880913369, 1.0085907715803448,
      1.005116771256942, 1.0037744581887693, 1.0021626380281496,
      0.982860755384547, 1.0090924817539069, 0.9859370973407278,
    ],
    [
      0.9948383003584782, 0.9951298693568345, 0.9976727644879184,
      1.0180747816782798, 0.9977083101075215, 0.9667185755030927,
      0.9921920675846859, 0.9903456815114449, 1.0039596042556955,
      1.014073680965987, 0.9898662841307495, 1.0108462863603753,
      1.0072890514146762, 1.006063644754043, 0.993392748533334,
      0.9739815654606259, 0.9828995813125675, 0.9643481464152763,
      0.9858334622696396, 1.0328683728099475, 0.9868334978881261,
      1.023274528159568, 1.0178264978491907, 1.0013479927883395,
      1.0223883580761168, 0.9798226407833917, 1.0175199608875143,
      0.997089156461304, 1.0010892597852439, 1.0119545503393308,
      1.0222366220872368, 0.9911443309468853, 1.0111479596486501,
      1.0029352358961892, 1.0186813476812837, 1.0044445253413428,
      0.9674275444283909, 1.015665207497046, 0.9982329339782459,
      0.9876950402596535, 1.020025286393175, 1.0124816148270703,
      1.0090613904473136, 1.0151521056490969, 0.9826605064952386,
      0.9743228387265007, 0.978023677781289, 0.9710705089609946,
      0.9833121518668267, 0.9651938597608886, 1.0146445747679729,
      1.0066053010221463, 0.9960499514087346, 0.9549450040116559,
      0.9671316821459084, 1.003937144085878, 0.9778618584752266,
      1.0141375393340282, 0.9942018362208314, 0.9982366710327395,
      0.9895073499490967, 0.9924474513983538, 1.0066215516507513,
      1.0184136723865396, 1.0022627389343015, 0.9954535210703996,
      1.0072215359655377, 1.0709818379681861, 1.0350011882081995,
      1.0070844118743878, 1.026299408887307, 1.002485221186423,
      0.9954624204718134, 0.9820286828541158, 0.98807255483501,
      0.9534369771043087, 0.968285740036635, 1.000505986034575,
      1.0006791474797498, 0.9825540896798964, 1.0067645505027338,
      1.006749485472821, 0.9989904618847014, 1.0096515219958955,
      0.966222424582929, 1.0117289979082764, 0.9915573202921124,
      1.010604526034129, 0.9924177186014843, 0.9952045444691013,
      0.9978013650241373, 1.0282909520697596, 1.0059694332820475,
      1.007933031530423, 0.9914716814102487, 0.9872203380425537,
      0.9976414252557415, 1.0085592244574262, 1.0139178320055149,
      1.0334143984255797, 0.9791539057254321, 1.0034709580559273,
      1.0005505001656898, 0.9793609675789281, 0.9853695396487525,
      1.0264483244575304, 0.9980360367884837, 0.9941348776056451,
      1.0270764767299276, 0.990290556921554, 0.999949050626294,
      1.005636237471823, 0.9940136916197653, 0.9955673171172194,
      1.0253610605316126, 0.9869663619378874, 0.9883818424560233,
      0.989989292524845, 1.0049800802891444, 1.0157003618397695,
    ],
    [
      0.9835172867215836, 0.9836658626495767, 0.9819603117253275,
      0.9979532217155882, 1.0048158462466876, 0.984268326338602,
      0.9908530310708294, 0.9725879708386147, 0.9717056097525698,
      1.0121485121144236, 0.9807894214704168, 1.0023715341738741,
      0.9635567983139505, 1.0054660004185907, 0.9919500862785641,
      1.046779057499241, 0.9856730296971122, 0.9938219171325283,
      1.009216300529164, 0.9978192999700445, 1.0249446024470386,
      0.986220920364441, 0.9809801152801451, 1.0070473392812973,
      1.0095789422746737, 0.9793139479894982, 0.9927729939130929,
      1.0022628463381364, 1.0174818603501934, 0.9924160576964888,
      1.005965422034096, 0.9887319584336292, 1.0130999459783803,
      1.0115493487353437, 0.9546847563771976, 1.00789753491904,
      0.9783726442783096, 1.010944900482266, 0.9952502932703413,
      1.0243398385755444, 0.9993412934173486, 0.9993639032107184,
      1.0246554780103097, 1.0055749907269649, 0.9867174551141638,
      1.015129803345915, 1.016456762960071, 1.0204528301990496,
      0.9999071668001598, 0.9589522047310537, 1.0039732006806874,
      1.0110736435579506, 1.0026106919635576, 1.0020173469131033,
      0.9836409757887548, 1.0060506269465037, 1.0117271555988991,
      0.9946980774491818, 1.034553627339903, 0.9764918020613518,
      1.0433593115253206, 1.008215052855399, 1.0047508420280296,
      1.043606169908001, 1.000991986088815, 1.040232094479514,
      1.0006502482785034, 0.973630359264594, 1.0006811882942095,
      0.9699953521765233, 0.9765080210193265, 0.9636745635344248,
      0.9666163127158129, 0.9876457212716523, 0.9864610727670529,
      0.9872325549808607, 1.02227952855029, 1.0416251081182557,
      1.006144931213023, 1.0011466755145588, 0.9917712621794872,
      1.0118910158073153, 0.9542499869554972, 1.0083341369092094,
      1.0033320981536193, 1.0276501289310596, 1.0105350031353257,
      0.9947131971024187, 0.9962827462065347, 0.9904792060730503,
      1.007769648593022, 0.9591409487155257, 1.0088428030858951,
      0.9955756681381173, 0.9702599666651557, 0.9993229623990257,
      1.0066703034696176, 1.0009607633260875, 0.9799600822739365,
      0.996333735037163, 0.977601239012839, 1.012049977412599,
      1.0056097795780934, 0.9654591317170808, 1.0354653171374975,
      0.9710390168647587, 0.9911070353129986, 1.0151148910661698,
      0.9934883141884578, 1.0127714578271627, 1.0106997633027555,
      0.9752636452237, 1.00596153324933, 1.0111215170245638, 0.9878086816572218,
      1.0033720330716585, 0.9909910237703597, 0.9978726195690021,
      1.018546093227179, 0.990392052620428,
    ],
    [
      1.0034328712477336, 0.998418102257737, 1.005581654686513,
      0.991843975408478, 0.9837640755694821, 0.9800854479486774,
      0.9844454476929353, 0.9985091245837718, 0.9744360022460188,
      0.9749021680716219, 0.9988507666274995, 0.9928946756092808,
      0.9989569342037139, 1.0130421273663752, 0.9986654154591779,
      0.9785180387408263, 0.9655837312795116, 1.0033511091119847,
      0.9984198251489806, 0.9910826707403128, 1.052198636261878,
      1.007025394866775, 0.9810333519189481, 1.0370614891104004,
      1.0218978153697127, 0.9996360063885129, 1.007573211488567,
      0.9870397459361329, 0.9980480211630243, 1.0289339978208107,
      0.9959349553555172, 1.0043162898160443, 1.0106758926038335,
      0.9817923829064478, 0.9703391106743944, 0.9923039373568523,
      0.9629491281321031, 1.0039949821329461, 0.9851704634612566,
      1.001250675603608, 0.990409313676436, 0.9867403852555159,
      0.9766966765143212, 0.9777166826645904, 0.9972015327223305,
      0.9974595591762765, 0.968671944151333, 1.0081007457219844,
      0.9881891439600249, 0.9981498163663882, 0.9968116091020424,
      0.9796836964461767, 1.003492450758841, 1.0043040673079078,
      0.9923958103189571, 1.00340205181376, 0.9826584108456304,
      0.9900386445762643, 0.9912366109360997, 1.022797842304543,
      1.018554978688254, 0.9889139824893796, 1.004989488438979,
      1.0312636655970455, 0.9809719471064853, 1.0155702025819306,
      0.9935296040032162, 0.997249491712324, 1.0017627986746218,
      1.0122277928624002, 0.9902202825899462, 1.010298114806,
      0.9714363085081679, 0.9831033773142646, 1.0085222654504926,
      1.0125934328310089, 0.9918872101169528, 1.0057651218677877,
      1.0247037434852642, 1.0085120481846994, 0.9799920600806123,
      0.9818944152438176, 0.9902454210563285, 1.021072948980419,
      1.0098621098345646, 0.9673662931358264, 1.0261965277936738,
      1.0293473684463275, 1.010265595554316, 1.01249580045726,
      0.9819013298809307, 0.9931866208273843, 1.0273003820249085,
      0.9825634114998684, 0.9990298358196417, 1.0127410990063055,
      0.9856895713232348, 1.0105153278624623, 0.9917053384677972,
      1.002907876990641, 0.9875351683296999, 0.9827632137480112,
      1.0042970909263844, 1.0008029790277386, 1.001032025340556,
      1.0150915126638251, 1.0201264963085452, 1.0026543133391967,
      0.99605579701215, 1.0324514961751912, 0.9813268293162447,
      0.9706352058255447, 1.01529172794829, 0.9657926865601969,
      0.9926934226174936, 1.0001460470004164, 1.0097996492188686,
      0.9921939860578901, 0.9949477740066062, 1.03635034006806,
    ],
    [
      1.0061095232710104, 0.9910449406194104, 0.9754721752914148,
      0.9794266522089295, 1.014394936064001, 1.003646409984046,
      0.9942735443697215, 1.0154307510116742, 1.005066038782965,
      1.0516115722675274, 0.9886039574048012, 1.007230129339745,
      1.0056461791314901, 1.0075898885290155, 1.0020876880584362,
      0.9773852600575239, 1.0141235804853141, 0.9868717864241684,
      1.0235666715800824, 1.0089931924756583, 1.0007875950861926,
      1.0068289450765657, 0.9793892260785726, 1.0412062982704606,
      0.9939909953798163, 1.0014242541129468, 1.0021892204060074,
      0.9746316354387482, 1.0164227696937584, 1.0079901001372922,
      0.9972923514155346, 0.9962480128099983, 0.9742040949883681,
      0.9955621309646239, 1.0299852194413255, 1.0232639384882631,
      0.9965036688139924, 1.001528337177621, 0.9972957675869543,
      0.9792166927321302, 0.9994003991095408, 1.0469083472295377,
      1.0245101668602254, 0.9912516879039579, 0.9677655074832461,
      0.9613711184493983, 1.0679215759650926, 1.010060225042908,
      1.0004518031049798, 1.0048427865735619, 1.013703228060243,
      1.0012338732739592, 0.996295938758704, 1.0133310919182796,
      0.9817412003936082, 1.0268135325270302, 0.9825184164425929,
      0.9970627114825995, 0.9999731357103252, 0.9872571582440521,
      1.0200164313819204, 0.9877545661186775, 0.9982762701090813,
      1.0515110445857043, 0.9998914624514884, 0.9924930942873834,
      0.9864820950117312, 0.9569830039836842, 1.0088904944540174,
      1.0042555872108818, 0.9958668599265627, 0.9821075342218225,
      1.011824315045454, 1.0193694429118947, 0.9970048439508976,
      1.0246859224914033, 1.000963305005222, 1.0022910344589417,
      0.9999826666379449, 0.9873557708777806, 1.0183316106451357,
      0.9966110027963959, 0.9816153361244804, 0.9886329744534782,
      0.9873276138445142, 0.9630675764121956, 0.9941539070140086,
      1.0018586262443334, 0.9901706054164907, 1.0091847710139916,
      0.996814896394596, 1.032048216388111, 1.0159768134158824,
      1.0001636758088397, 0.9873117798404315, 0.9821248197860619,
      1.0069133469588565, 1.0192956233723796, 1.0060184682194635,
      0.9985424878036477, 1.0075586501337148, 1.0029710449727072,
      1.0010020801262718, 0.9980199959873388, 0.9959484556756943,
      0.972288949804629, 0.9949779341989564, 1.0364146336822673,
      1.0037319213922247, 1.0076083112454608, 1.008931881276959,
      0.9827391180861784, 0.9947218644352155, 1.0152910742262824,
      1.0093791027961623, 1.00455074581344, 0.9819064385755891,
      0.9654798118909327, 1.0383892830148915, 1.0017912544362033,
    ],
    [
      0.9586051917294058, 0.9937121632956637, 0.9555597806939318,
      1.0046369039973642, 1.0027101485389676, 1.0217916982113995,
      0.9693544713622696, 0.9840720151046298, 1.0062250658412508,
      0.9924557654408126, 0.9885985192193649, 1.014078606281692,
      1.0017478979285426, 1.0013543678311996, 0.9907632581060157,
      0.9674095602377192, 0.9710750325935275, 0.9981571907653457,
      1.0259120405369704, 0.9968924037610912, 1.0107988467951792,
      1.000658427353476, 1.0215728947258238, 1.015502014813658,
      1.007204196183834, 1.018957059091929, 1.019413424673914,
      1.0061995015743317, 0.9997270440611356, 0.9991440674808909,
      0.9700688672815131, 0.9821813155653016, 0.9611589168303544,
      0.9984566177281415, 1.004652626515798, 0.9687833777279637,
      0.998749876130218, 1.016832681326607, 1.0243512965531785,
      1.035821812117307, 1.0255081144420073, 0.9993435002308375,
      1.0248793466298767, 1.0312149709802376, 1.0113332329907108,
      1.0089794686996725, 0.9972887214469983, 0.9723431961356201,
      0.9915224766676665, 1.0099034791699544, 0.9797521451278048,
      1.0080935175183292, 0.9981857307931135, 0.9992399300084537,
      0.9719599953554856, 0.9955252354194277, 1.0014521714600058,
      0.9932697833034279, 0.9998937868924348, 0.9802218990613332,
      1.0059099433552685, 0.9603760960152423, 0.9955415528840228,
      0.9947576018701023, 0.9932610116094548, 0.9805163073638934,
      0.9967795740981442, 0.9970779117632776, 1.055371627404603,
      1.010607942730858, 0.9898635979401691, 1.0045660189779104,
      1.005412069589307, 1.0105497826622902, 1.0020209402964866,
      1.015094301656146, 1.0085472552329542, 0.9425628032883957,
      1.0131927451598597, 0.9859491390584558, 0.9825966724033584,
      0.9854065005500937, 1.0069698314233584, 1.0279555732007877,
      0.9957949106124592, 0.9869176165713126, 0.9669515926639487,
      0.9709633440429267, 1.0235496588479012, 0.9883798927389963,
      0.9603353068558749, 1.0082887098136366, 1.0011696139515707,
      1.0024109120507951, 1.0037161262246947, 1.0114471600635144,
      0.969269736683106, 1.013094488597957, 1.0190740028107113,
      1.0122069024176819, 1.0136855825139424, 0.9949824664369489,
      1.0211476085099918, 0.9780638057551825, 0.9983436944881545,
      0.9970376282852162, 1.014765610380047, 0.9668183748575423,
      1.0081323570816307, 1.0263110156667576, 0.9961923806744395,
      0.994804365621142, 1.0399717647103577, 0.9798536424977575,
      0.9674656522626066, 1.008129906498152, 0.9788401926239889,
      1.0422763522679959, 0.9917070730312019, 1.0190054785645546,
    ],
    [
      1.0291386441402217, 0.9686741682381044, 0.9950349627114727,
      0.9903207862278546, 1.019023117202956, 0.9935967088682599,
      0.9769534386936023, 1.0155743105550488, 1.0318641686874903,
      0.9977734388484939, 0.9926669803361898, 1.0119955369143225,
      0.9886125609214489, 1.0033118698696906, 1.002394137156105,
      0.9801594520475483, 1.0049024544882643, 0.9962557048468144,
      0.9865071560068819, 0.9894815954173939, 0.9786929638081603,
      0.9816023333402918, 1.0023961139187691, 1.004239478518293,
      1.02221778146852, 0.9909509175870058, 0.9743056284102249,
      1.0058102248069147, 0.9972314109504044, 1.0182633084453852,
      1.0062163042998133, 1.0332013032832634, 1.001380874627952,
      1.0053631943944548, 1.0091420258527366, 0.9979744966279356,
      0.9995339277105982, 0.9979749438169245, 0.9999181863573638,
      0.9833913208761806, 1.0135772685717992, 0.9872018129427927,
      0.9818942281579861, 0.9987335078032521, 0.9765864596598097,
      0.9688857098164658, 0.9725947951518186, 0.9976596551499934,
      0.9740649444447309, 1.017730176404319, 1.0225282421079624,
      0.9871351281978562, 1.0187376487191102, 1.023761211136924,
      0.9927307301529598, 0.975014385529523, 1.0079589057866958,
      0.9784173643292846, 1.016026071317625, 0.9958796980001405,
      1.0319387657130354, 0.9725824772431585, 0.9726817435534367,
      1.0222490989944095, 0.9664254342536145, 0.9858493516256156,
      0.9679744233951013, 0.9778931657444546, 0.9805071543237294,
      0.988384464279466, 1.0040944643615206, 0.9723769182012523,
      0.9511094493257624, 1.013621227743108, 1.0077380056594336,
      0.9930829233408177, 0.9800829492935419, 0.9970967717253785,
      1.0040132234089898, 0.99670552966454, 0.9805081427973062,
      0.9998607495321093, 1.0069799057952513, 1.003345212362104,
      0.9702239353486657, 1.0058091869761605, 0.9718386699160206,
      0.9813487301281223, 0.9894556742847, 1.0064686000390362, 0.98804298051475,
      0.9821163705869218, 0.9807391733296745, 0.9857319607843491,
      1.0098459348124504, 0.9906390641372036, 0.9995717871322941,
      1.0214745974334831, 0.9977177863311488, 0.9633988359652141,
      1.002961577285327, 0.9970426343817373, 0.9866248766896564,
      1.0007951501698178, 1.022597486476091, 0.9926481014211403,
      1.0123715389814718, 1.0184458493503885, 0.9856173276498907,
      1.0025684537084825, 1.0090026023903946, 0.9804705200154953,
      1.0043378607961402, 1.0385534990897756, 0.9936134475416807,
      1.017255168407695, 0.9813777095779253, 1.002717183823953,
      0.9662822179608399, 1.0086668630381683,
    ],
    [
      1.0194903030461147, 0.9618406252023324, 1.013876331372458,
      0.9974798663078293, 1.0023111946931467, 1.0052009004838613,
      0.9959594575495097, 1.0003986053618945, 1.005300828423614,
      1.0279917988289917, 1.0429485729752117, 0.988180985013934,
      0.9945296300982275, 1.00783676227727, 0.9983167489547818,
      0.9797718240768365, 0.9857222172448494, 1.032972690143736,
      0.9889783856933687, 1.000239005194092, 0.9871202553944943,
      1.0059714436285931, 0.9904452773872185, 1.0175760857364802,
      0.9942703550987884, 0.9788433875976164, 0.9902512006328476,
      1.0144455178581258, 0.9956461449887903, 1.005065857845978,
      1.044337263662391, 0.9913332175194784, 1.0143257350668087,
      1.0078750868596882, 1.0267228040441327, 1.0233010325727083,
      1.0064082156095941, 1.0087237994552685, 1.005521723478382,
      0.9843147932154173, 0.9572012262442231, 0.9706881511938052,
      1.0066848762197882, 0.988475866960244, 0.9547043533544374,
      0.9954815086361157, 1.0008808414616857, 1.0171835507266813,
      0.9949120586856766, 1.0278567676579486, 1.0209698942465946,
      1.0347654249472036, 0.9927225901816301, 0.9961346595997487,
      1.031331866864185, 1.0078332467507403, 1.011225681381346,
      0.9789094913382894, 1.0178072799201268, 1.0056073784859616,
      1.0014127753824515, 0.9964725963938037, 1.0013566946912071,
      1.0117879562106697, 0.9764824105299694, 1.0344828179979437,
      0.9856435452408623, 0.9736060758895664, 1.0167379521693647,
      1.0012103143740463, 0.9773466839624543, 0.992632837080939,
      0.9979209497231039, 0.9871224886218279, 0.9960744849216009,
      0.9877230100402328, 0.9762798081561107, 0.9786319437833022,
      1.0259659852812941, 1.0131217135919366, 1.0099960750228714,
      0.9918767627520628, 0.9831672344180739, 1.0255718423526987,
      1.0128362611995056, 1.0050881544870793, 1.000014844577576,
      0.993256776072586, 1.0007243359118616, 0.9950639940282544,
      1.0184510041251418, 1.0076978227386224, 1.0044523751420704,
      1.0249435156472182, 1.005070554356078, 1.0004717796016669,
      0.9913947358954418, 0.9976108614955668, 1.019336138313879,
      0.9786318625815593, 0.998941728087077, 0.9771244428001902,
      0.9757917463883341, 0.9934012007332644, 0.9872151995146072,
      0.987716722403483, 0.9868859645288031, 1.0018887276718078,
      1.0199489661910173, 0.9928114027254201, 1.0354205236637792,
      1.035513860570464, 0.9918693435979481, 1.00349815917232,
      1.010007712842671, 1.0153247011107986, 1.000616507154734,
      1.0177731273066324, 0.995099699128112, 0.9883967093614523,
    ],
    [
      1.0286152611723733, 1.0160011795237418, 1.0068114026998283,
      1.003470317805799, 0.9954684329184245, 0.9893571692070211,
      1.028829033205171, 1.0019100652263284, 0.9907061418486927,
      1.0197622497353693, 0.9973569211946629, 0.9668839952897609,
      0.9993802010423498, 1.0150873800547386, 1.008272766983494,
      0.9833595765980558, 1.021247571733042, 0.9870086656878388,
      1.015247922646197, 0.994711852487639, 0.9801350440186241,
      0.998273561932423, 1.0133706835293284, 1.0069807669515165,
      0.9926800724033946, 0.9950284300102421, 1.0262170704920868,
      0.9903704387348704, 0.9984991392430183, 1.0104196208327605,
      1.0112580207489081, 0.9942275368886756, 1.0419875605905489,
      1.0131491188271426, 1.0121211737910312, 0.9970927395416671,
      0.9833679821061225, 1.0220041330776772, 0.9854045046158633,
      1.0134455720008972, 1.0198259562861114, 0.9959025444228787,
      1.004576724977929, 0.9963144113133204, 0.9940253951735389,
      0.9778076855971796, 1.0004700397559574, 1.0485753760882055,
      1.0140192843068765, 0.9907387161435237, 0.9576957580252156,
      1.0055708520203548, 1.017912107615468, 0.9968085883630076,
      1.0050608941563839, 1.0045594469901515, 0.9788842765312312,
      0.9824181818271132, 0.973041422571306, 0.9893497099391431,
      1.0148752241939913, 0.9929348197221572, 0.9907789735389123,
      1.0156535959889197, 0.9903976403339203, 0.99119739173251,
      1.0075421925269137, 1.0277717651310445, 0.9941599446969575,
      0.9624764132122946, 1.0252410565510974, 0.9885519761665067,
      1.0008585676227133, 1.03206622879541, 1.005398127658058,
      0.997613309159503, 1.009699158541563, 0.9883926034705772,
      1.0215869767870673, 0.9922681318931847, 1.0377979462829265,
      0.9996536795694777, 1.0124996055863655, 1.0303527977660272,
      1.013001175696569, 0.9982354701900436, 1.0141091454370803,
      1.0244897595548612, 0.9976045923282242, 0.9812009087224923,
      1.004312853174016, 1.01724144792969, 0.9839474025499668,
      0.9811083871727186, 0.9911551178087026, 1.02161056445202,
      0.9934330146034769, 0.9780838730670335, 0.9594060341669197,
      1.0369874661349439, 1.0195496375540327, 1.0059005874256575,
      1.0161203672502657, 1.009616654972687, 1.0219984888160691,
      0.9924390534619653, 0.9851797080399168, 0.990489913478815,
      0.9916732722458872, 0.979652447381208, 0.9760247885458007,
      1.019263160557189, 0.9983740154688083, 1.0061999819734264,
      1.0072172274853832, 1.0153299321467757, 0.9964607231267406,
      1.0189219844973803, 1.0043833030275566, 1.0128624914938156,
    ],
    [
      1.0142451135730548, 0.9759574842911412, 0.9926732057567641,
      1.0189827993125489, 0.9829927324002594, 0.9878336766780781,
      0.9927515630141275, 0.9898849653256048, 0.9851886208948807,
      0.9876222413660203, 1.0174586986884, 1.0283368970792426,
      0.9880611663472288, 0.9928461190657125, 0.9938231957371254,
      0.9816728722470349, 1.0044821523271434, 0.9894649746315247,
      0.9742752873487481, 0.9927354126290927, 0.9872419951611848,
      0.9889038407909049, 1.0226576934452318, 1.0420256412442208,
      1.0093916069027014, 0.9989774394284615, 0.9928057088052076,
      0.99478716958795, 0.9722644177791469, 0.9824078211936338,
      1.0061024042632911, 1.003019665706084, 0.9448832420698039,
      1.0104456995972653, 0.9928188496044725, 0.9677119552082223,
      1.0177167210411202, 0.9846563130541075, 0.9908782568229951,
      0.9876623371012632, 0.9884856640009707, 0.9982724194368124,
      0.9694091739152788, 0.985617604493894, 0.9798320214120622,
      0.9737133241506086, 0.9580276738668165, 1.0075474394397932,
      0.9938715170037042, 0.9405980715766102, 1.0067564547500538,
      0.970670422153351, 0.9792382485807107, 0.9906705168977068,
      1.0286324970362581, 0.9953754659892331, 0.9916730985798525,
      1.0360192567818611, 0.9734319288533232, 1.0008843425329024,
      1.0132510242444528, 0.995785241949265, 0.99152583138186,
      1.0171279539402884, 0.9979624389525397, 0.9880370272527816,
      0.9802522690616042, 0.9599190423173043, 0.9814663686978348,
      0.9903009227026267, 1.0273751575190053, 1.0101328146277948,
      1.0123641297748396, 1.007940951935839, 0.96612021233187,
      1.0070310141560719, 1.0282406851485417, 1.0045998437445525,
      1.0029847575682875, 0.9769058144137273, 1.0056696141710617,
      1.0198590654163224, 1.0033329077188995, 0.9996757959774067,
      0.9828436768441484, 0.9992997411313385, 1.0157434131706056,
      1.0002456962372865, 1.0095528791467587, 0.9969463765264934,
      1.006667097176869, 0.9972968621125247, 1.0162734217553187,
      0.9983360006653965, 0.9958096315031786, 0.9876938811356284,
      0.989022283762445, 1.0119105260645573, 0.9969620402201891,
      0.9829364981227736, 1.002137653753928, 0.9876702457501414,
      1.0062764491915175, 0.9834484784269819, 0.9950569340697688,
      1.0030356311854824, 1.0418187862549528, 1.0123993806364082,
      1.0243683005149955, 1.0483900637943495, 1.0147996327113784,
      0.9984516567211392, 1.001489941721181, 0.9933307402813991,
      1.0039673602260124, 1.0059075978659524, 1.0021294254720794,
      0.9947440344665114, 0.9824447383180271, 1.0060556850596234,
    ],
  ],
  W: [
    [
      0.002517141095504033, -0.006079128167542997, -0.005431692009546024,
      0.009023379846542653, -0.0010527734657911599, -0.00541950036553915,
      0.001587101059970513, -0.0015861727597753865, 0.006976744330477199,
      -0.005140209811512629, -0.003150305526823562, 0.009564192979630843,
      -0.00778887354808981, 0.003736437632838976, -0.005238993464758988,
      8.362898677458799e-4, -9.393419270770545e-4, 0.005380779478606745,
      -0.002984034626963009, -0.0011618168361583834, -0.0012482100778953395,
      9.330404643601068e-4, -0.0011943126798306574, 0.006263771536928065,
      -0.0031908807716860142, -4.921635943972232e-4, -8.757417674834357e-5,
      0.001828439423687394, -0.001760151978601212, 0.0011585074097235613,
      0.0025158578039393047, 0.008042910310522724, 0.004953294022941703,
      0.0034482805300117773, -0.0023527600486975913, 8.32845854614898e-4,
      -0.0015463430295863524, -0.00586281696285277, -0.0025269819838124753,
      -3.626624117634072e-4, -0.007036016479488032, -0.001351248882661201,
      4.755415508391728e-5, -0.0017409530594705135, 0.004257677240359717,
      0.0012887143463073346, -4.0438133007079816e-4, 2.46330487797889e-4,
      0.0030495018966396562, 0.002817074008616679, -0.0015467713499034866,
      0.0018826516252951936, -4.6475837028386033e-4, 5.13053406343305e-4,
      0.0019136579087415924, -0.0036164260740948534, 0.009853736613977454,
      -0.0013682743607798078, 6.434518776396392e-4, 0.002876799636666568,
      -1.2959815408008997e-4, -0.004907288363559716, -0.002537695893528977,
      -4.294688516552472e-4, -0.0010782880530222543, 0.0025875781366725367,
      -1.5314413313820163e-4, -0.002889016933435711, -5.619941987761152e-4,
      -0.005412776044048281, 0.003935696831889698, 0.007790173959295306,
      5.983682489504878e-4, -0.0019103597895140166, -0.0012361637191982606,
      -0.004542519283291643, -0.006813097999437558, 0.005108126923533307,
      -9.490285594038271e-4, 0.006178559655717244, 3.127103960309934e-4,
      -0.0015630448718484773, -0.0015195841648248728, 0.004792640501440156,
      -0.0018279966610086824, -0.0012696912749392462, 0.0041657625304746294,
      -0.002392142994553487, 1.1573990350757885e-4, 0.0035101458098826563,
      -0.0020337862278873103, 0.0016623888760737386, -0.004103479748644708,
      -9.092398135433447e-4, 0.0021345918088605657, -0.0013085425562335278,
      0.0060701352491508405, -0.0026356281701089496, 0.0017371545517527876,
      -0.0014168406151539021, -0.001586350840432713, -0.0014168818732700648,
      0.0012940033125002665, 5.275875315846094e-4, 6.579067733686651e-4,
      -3.383646982071186e-4, -0.0014669963939952482, 0.0028089453172902195,
      -7.361568082386229e-4, -0.0020756410328213815, -0.0038159510176332782,
      0.002442025080397167, -0.0038882486960050175, 0.0020016239182479093,
      -3.076488357310849e-4, 8.761008440763882e-4, 0.005869580796439622,
      0.0011236975858160462, 0.009047763282984152, -4.201720726228568e-4,
    ],
    [
      0.0029536505261280096, -0.0014169820483213385, 0.0027542125523535785,
      7.66350370341251e-4, 3.8460070173855347e-4, 2.8250717360298576e-4,
      0.0036136515288669553, -0.0027019045340543535, 0.0027137979047321195,
      -0.0012643805950866061, -0.0010312451443835894, -0.002720606913181031,
      0.00375516504591021, 0.0012220909387262698, -0.00290962531527915,
      -0.0016072649183947684, -0.004498297114193795, 0.001290923398458205,
      -0.0021764053794191673, 0.0016051035587464168, 0.004711528580770324,
      -0.004548454814996852, -0.0033701903865784124, -7.004892570276859e-4,
      -0.008299776796569645, -0.0018430560002660958, -0.005179388269909172,
      -0.008786703798610147, -0.001414174890668279, 0.0012648426625044167,
      0.007434259974902472, 0.0010980284759109103, 0.0049727626189297,
      -0.006983449761923904, 0.00908767932077265, 9.463244483527103e-4,
      0.005554167344144511, -0.005766263242180049, -0.0022134279328080613,
      0.0027769862487629117, 0.001985257843155159, 0.0024319455734245965,
      -0.0011934091818299958, 0.005962989001853721, -0.004376645146436235,
      0.002941980578542415, 0.005495510756664686, 5.152014082647257e-4,
      0.001796992174172802, -0.007271801046780621, 0.0032269865369330417,
      -0.008429349443633886, 0.0010371039767769477, -0.0037227516863886636,
      0.008372283156167191, 0.0038040181334252953, 0.0022377717531242387,
      4.025670991729956e-4, -0.0021715592974079385, 0.00972132102201725,
      -0.0018923754653743115, -0.001274491711882569, -0.013357729624159513,
      -2.8978759900623033e-5, -0.0030373724880802134, -0.004862205187425693,
      5.190603553794369e-4, 0.001192491115548996, 0.004104024215360525,
      0.003961503221022786, 0.005281222844592257, -0.006514708880342385,
      0.0011252104569673136, 4.1959031700558147e-4, 0.0023590093485193297,
      -0.00772331745581252, 0.0025025154929506016, -5.191349404276835e-4,
      0.006245220455548239, 3.7386604118062156e-4, -0.0024404891719301356,
      0.006290492450488452, -0.004329269889794148, 0.005561162750241743,
      0.00230822437092989, 0.001738602362392467, 0.0018447816755237391,
      0.005498664503507675, 0.0010548756132570008, 0.0032521504564891534,
      -0.003157727806687019, 0.0027763733757190873, -5.387519985678191e-4,
      -4.0566251901297636e-4, -0.0017102468428052357, 0.0027501393636253472,
      0.007335087895052581, 0.0020627560747000797, -0.001118268215655729,
      0.0024399070806054137, 0.0019858901505586442, 9.904168262999417e-4,
      -7.583831256672728e-4, -5.314069035628568e-4, 0.0031704255459481193,
      -0.0017294946870137773, -0.002983690184169917, -0.004444168782487877,
      -0.0016465245189321552, 9.759718295161114e-4, -0.0022139105109499953,
      -0.006120066130947148, -0.005501298197199503, -0.007968599288951007,
      -0.008680988748187159, -0.001710828148008864, -0.0033377945487938205,
      -8.655075373065606e-4, 6.731046804395975e-5, -0.0013075246451417397,
    ],
    [
      -0.002011806693525336, 0.0038157856666759605, -0.0013367452036868596,
      -0.0015764704260072494, -0.0012588119544477395, -0.003038395877244059,
      0.002225005864915983, 0.0025276956235273126, -0.004929411520201552,
      0.0010835607220094864, -5.139781422267236e-4, -0.0021441237936838655,
      -0.0035152662709675713, 0.005582335680351258, -0.0044323285331347145,
      0.0035421155569471965, 0.0073537948762803255, 6.56741152592515e-4,
      0.004086245403299598, 0.00402009231140607, 0.0014389027255791412,
      0.0036209680343451045, -1.741380585393803e-4, -0.0011467801495994704,
      0.005507102469966622, -4.2381347864091243e-4, 0.003376145411570741,
      0.001177599097122992, -0.0011602341969928695, -0.0025156633373659342,
      -0.0023318540694843874, -0.002821090961517234, 0.0016529187155868174,
      0.00693220181596723, -0.006102550465540089, 0.004225137276533137,
      0.0010874073497179851, 0.0024674903283788087, -0.002602271411867714,
      2.613185055688192e-4, 1.5959915490614463e-5, 0.0035376502304422628,
      0.007137690952467232, -0.0020130813223852, 0.0019292654329454775,
      -8.349279916471246e-4, 0.0012455567354278337, -0.0019685711379939415,
      6.130050231708192e-4, -7.92741188071902e-4, -0.003471995705439946,
      -0.004068556292547588, 0.008932587837113284, 0.00431522533357637,
      -0.001979970045493723, 3.1257560040387836e-4, 0.0024468852379913187,
      -0.001802052352321559, -0.004108839921793441, -1.61364454409705e-4,
      -0.001926292169153518, 0.0045572459435408155, 0.0011023946691623404,
      0.0011687232559099538, -0.0038898292667043665, 0.0024629079273889094,
      -0.010099184160016083, 0.004127439239854376, -0.002457811026107073,
      0.008432823373320856, -0.002840722778208404, 0.0035440154850765234,
      0.003426270544489512, 0.0018366473700596078, -0.002835719001138181,
      9.938433316933316e-4, 0.005438820511679637, -0.001970652504308612,
      0.001667828559972111, -0.0036515331725423517, 4.0358914441493724e-4,
      0.004507669666713708, 0.0020908019569903266, -0.00746822088150053,
      -0.0029731434556067374, -5.068789389357254e-4, -0.001262396894703595,
      -0.00799998540895553, 0.003695069999213339, 0.0017720194761063758,
      9.90966890270931e-4, 0.002871739144247458, 0.003657642661292375,
      0.0017762815165308678, 0.002624584729266431, 0.0012537584145021975,
      0.002772788777661017, -6.669714555909772e-4, 0.002869322767351221,
      0.010032501151494965, -0.006715250517051961, 4.302778240555645e-4,
      -0.003889086154331498, -0.0018958512401769632, 2.0077010276891677e-4,
      -0.0039914182862523274, 9.584644154406515e-4, 0.0021219083042438593,
      2.904448824532895e-4, -0.002382368504873384, 0.006578046768030962,
      1.44503304454019e-4, -0.005270822824951331, 5.264063730049559e-4,
      -6.410593387663981e-4, -0.002947379020978921, 0.0028739457170917984,
      -0.002252335452333263, 0.0015318938642713026, -0.0036428284922237295,
    ],
    [
      -0.008380437082439967, 0.002393895378728081, 0.0011328870889945658,
      2.7281653922314975e-4, 1.5271068088388022e-4, -0.003821614609792229,
      -7.388334577935803e-4, 0.001988495551928971, 0.0061433191940493065,
      0.007323356748680502, 0.0010668409654150082, 6.537775245691728e-4,
      -0.0011882040800085844, 0.003540180643556623, -0.004199946591139949,
      0.0058642490844778485, 0.0014426047107697776, 0.0014801744624456214,
      0.002876107782339294, -0.006979606567738749, 0.005504966287533849,
      -4.4556639817161595e-4, 0.0014615850415379233, -0.005540864846086565,
      0.00275852793197868, -0.002816060845641061, 0.004277708665234053,
      8.523369771554274e-4, -0.004366921570686071, 4.118289847577864e-4,
      -0.002089697542449911, 0.001487586186064716, -0.0012325860512388597,
      -0.007109432994746227, 0.0021821421845079157, -0.003680005851318602,
      0.0030007633095905633, -7.857216633276594e-4, -8.842652899656764e-4,
      -0.001575262576287643, 0.0033307439404254412, 0.0037318367689308913,
      0.0020962418193287277, -5.84820113362109e-4, -0.0011477330352128706,
      8.369488417154199e-5, 6.0152467244518e-4, 0.003944547085562358,
      -0.0015412018819530653, -0.002804120266172131, -0.003074037667550092,
      4.5880163897850834e-4, 0.004343022903710898, 0.0023198702187679017,
      0.0023033791570798187, 0.0026769596627594103, 0.006146300326569466,
      -0.0040967021413886445, 0.0027770826018821077, 0.004714319711157069,
      5.370715633638188e-4, 0.0025310994162648214, 7.68317432389338e-4,
      0.0022751960626944015, 0.009551100427612364, 0.005830393355234662,
      0.0029230905023956665, 6.997542903709606e-4, -7.052764007695945e-4,
      0.00432390636091772, -0.001476895483492488, -0.0029470577033610832,
      -0.004326739223471305, -0.002795401479636762, -0.002248175432910913,
      -0.0021239349277252066, 4.5567688307219053e-4, 0.0016162101840733378,
      0.010704585599224695, 7.137803162679598e-4, 0.0014752791605719038,
      0.003972229586441927, -0.001442011160842747, 0.00256298397695233,
      0.008051619533745075, 0.0026436890175110615, 1.2914593985328664e-4,
      -0.0013254899897039546, -0.0025615681214409044, -0.00426155550673347,
      0.0016580841831171807, -0.0017895454062058329, 0.0037906944451603876,
      -0.0010372599887545784, 0.004097111559388795, 0.0022866841747826877,
      1.1897199147184451e-4, -5.409695393233697e-4, -3.435004961478303e-4,
      3.3748474578678317e-4, -0.0017873382720915059, -8.000036362913095e-4,
      5.517013330081804e-4, -2.005043240570368e-4, 8.164321435020452e-4,
      0.0029115220327986507, 8.275944826323061e-5, 0.002990393383278854,
      0.002007147012684793, -2.476764431823666e-4, -0.0010841542228948097,
      -0.004057788764518717, -0.0012787610344127606, 0.004639767077435746,
      3.1897306509961207e-4, 0.0025814788721264964, 9.093037716049072e-5,
      -2.8233467016955654e-4, 7.770521913666955e-4, 0.001150647727775574,
    ],
    [
      0.003489514539518024, 0.002762836511978794, -0.002157131435198539,
      -0.002407526440433105, -0.001443274766621639, -0.002304505004804313,
      0.0035447630227493487, 0.002447083925640657, 0.0029123412172237127,
      -0.0010441924536814166, 0.0024483757425701955, 0.0016020398071262209,
      -0.00698139911164194, 0.009317732470662888, -5.613496437434709e-4,
      -0.0020981275820253233, 0.0014304301378271313, 0.004916274922050252,
      8.978690445726468e-4, -0.002017480386156973, 3.276678773915677e-4,
      0.003077365556462241, 0.00497039682897152, 0.0012776660912389992,
      0.0013095881991408874, 0.0032050444085950773, -4.7929836906480886e-5,
      5.2795124635847214e-5, -4.522055325788094e-4, 0.0020686574832733213,
      -0.00423660739952709, 0.0010239909782519259, 0.0021294578744233345,
      0.003185696047670375, 0.00398802669365215, -0.0035149175938975702,
      0.005939543497202904, -0.001223833813237539, 0.009542976971389915,
      0.008568687063244502, 0.0016519038923762334, 0.002125296500988364,
      0.010918426193739447, 0.0035249842757414437, 0.007906953974543475,
      -2.5546137573056343e-4, -0.0015960414589238367, -0.002321366704402172,
      3.287042170735942e-4, -0.002407615343077145, 0.005418561898446637,
      -3.072238703643927e-4, -0.0011478644551550662, 0.005823729537366533,
      0.005507737052630842, 0.003906985326515772, -0.0030086298720942025,
      0.001080999455924546, -8.03309393598595e-4, -0.001521837139362946,
      -0.004013135633304093, -5.404961276025857e-4, 0.004974879233379866,
      9.474699659944737e-4, -0.0031194071709598724, -0.0020846848821831432,
      5.80886168922938e-5, 0.004768028433298054, -0.00122016386232301,
      -0.0011275315383061182, 5.55696245408485e-4, 0.002377102253238994,
      -0.001898848731038984, 0.0010836935990185743, -0.003276911813322902,
      -0.0015737428380946702, -0.00135312093186343, -2.8934513483828354e-4,
      -0.0026603549642250394, -0.0015481796036877432, 0.001795723069002156,
      -3.124177949924082e-4, 0.0038373168478220403, 0.0022604089905666792,
      0.0016609894619823804, -0.0010308239782671833, -0.0012610599770456258,
      -0.0037896272886295385, -0.006326811262317447, -0.002778448644715344,
      0.0051120898273628035, -7.948769044070078e-4, -0.002197494733740918,
      -0.0015178106633235073, -3.124673797774225e-4, 5.71219997102274e-4,
      0.0064461424886432025, 0.0015992240018092721, -1.2276947873086154e-4,
      -8.171685717679026e-4, 0.0020245757610043735, 0.002355095308968659,
      3.647154623372086e-4, -0.0015944912188558618, 0.002560725982891205,
      -0.002238084371271726, -2.4257035580509985e-4, -0.008989069331442369,
      0.0012808480462757827, 0.004366606642527693, 0.0029883704252530304,
      -9.320607430970984e-4, -1.897507688930775e-4, -9.1663674014443e-4,
      -0.004120351380701864, -4.751275620061833e-4, -0.0017500689806873291,
      0.00254456547761929, -0.003796526621086842, 0.00160106070421836,
    ],
    [
      -2.479636838163304e-4, -0.004069582399799371, 0.0010856811066557353,
      -0.00551556055865313, -0.0033377375854203354, 4.6285725464585443e-4,
      0.004055762081196494, 0.005863978157957947, -0.004306240591052223,
      -4.6701881860497995e-4, 0.0035770813080213126, 0.0018333741505288303,
      1.3959335771727513e-4, -0.002907625808271177, -0.009242329925099433,
      1.5287991109934145e-4, 4.421839491242975e-4, -8.302906773834931e-4,
      -0.0014281344506170274, 0.003244444582543176, 3.2926371600441927e-4,
      -0.004424944132417656, 4.945013457426114e-4, 0.001051859114040069,
      -0.002110288984617391, 3.4402591277833085e-5, 7.213308204838227e-4,
      8.327656107010566e-4, -9.938463580735435e-4, 8.153716657005438e-4,
      0.0022487826716760767, 0.00291089988042188, -0.004828625922557655,
      -5.716095567004154e-4, 7.127237772218582e-4, 0.0012355676876999787,
      1.3910390002802237e-4, 7.434925084677453e-4, 5.871491165263831e-4,
      -0.008261814906837375, -4.0970676824460717e-4, 0.0010834181204582953,
      0.003525326270584949, 0.002451453694687177, -1.6240245848705108e-4,
      0.0049419438455399235, 3.35179596726008e-5, -0.006766273726943848,
      0.0038262432081607465, 0.003124315578918781, 0.0014907769048920477,
      -0.003216300665012342, 0.006790232245254532, 0.005428042514994428,
      -0.002630473788106416, -0.0039172193758132585, 0.0015081381326034018,
      -4.4687018704771904e-4, 0.0036412121609471675, 7.24375998290024e-4,
      -8.274338507933157e-4, -0.002841425008835801, 2.4808556713361394e-4,
      8.548596316156865e-4, -2.9302906039361447e-4, 4.874996421748217e-4,
      0.0058967629637289645, -5.481267624919881e-4, 0.007032152346678763,
      0.004440704784033501, 9.143939679394019e-4, 0.00538930955506192,
      0.0019161148654910747, -6.820221126956291e-4, -0.0013049463505291669,
      1.747570316975197e-5, 0.00421954632653447, 0.0027087102706392723,
      0.0025740389463662825, 0.00258030884970418, -0.0038856753640328268,
      3.8520005629103725e-4, -0.002540604021764456, -0.002664589938405488,
      0.005065267270446587, -9.349846593815597e-4, 2.227844847319336e-4,
      0.005033779363886459, -0.0025177691588423308, -0.0042087817438420105,
      -0.002701770656633625, 0.005665230499662974, 6.570351065742101e-4,
      0.0010099166156112794, 0.009345332013442648, -0.0012353768374829051,
      -0.003152877872085813, -0.0036865846823536147, 2.6286901738786794e-4,
      2.2281695735412428e-4, -0.001688208438626031, 0.006862472357740254,
      0.0037702701882556537, 2.5179493491665165e-4, 0.0015570684130148294,
      -0.002446237814276594, -6.163442656859304e-4, -0.002931934942052476,
      2.7050181800294465e-4, 0.0025186676434963183, -1.3385584117585684e-5,
      0.0024347599587785265, 0.0021889366747285073, -1.8243233858199805e-4,
      0.0019192075785277468, -0.0034752568594848023, 0.0054079761801722566,
      0.002865114787420172, 0.0016725613109826094, 1.245751165763431e-4,
    ],
    [
      -0.003926261270151546, 0.003121826265019603, 0.0014297717033006291,
      -0.001868855556824786, -0.003971168935301037, 0.007052261098307793,
      0.00217352862767884, -0.0017536468572931284, 0.005678397133558043,
      9.14011918900418e-4, 8.547727585245986e-5, 0.0028241783366778762,
      0.0062593092568739035, -0.008862762941174388, 9.899547726545626e-4,
      0.002803825131529646, -0.0017724016645688043, -0.0039809930404159834,
      -0.0049755630108229785, -0.0013128867413059395, -0.00330418666799376,
      0.0020155711586479384, -3.5229996111997715e-4, -5.73311947733772e-4,
      0.005374413536639203, 0.0017722903498842756, -0.0010755282509604266,
      0.006280097212790891, -0.005371264801628018, 0.002525301919254275,
      7.831476580239872e-4, 0.001674610770437001, -4.695616086485071e-4,
      0.008090189931686073, 0.001008928094447964, 1.7927847381263138e-4,
      -0.005958178303628993, 0.002259911092937091, 0.003639414742063355,
      8.304765163753136e-5, -0.0014702138280132788, 0.0015123085597103041,
      4.774241675626059e-5, -0.003417861435713804, -0.0015488670679620667,
      0.0036439531002269553, -0.005595935345249247, -0.0030689216333373674,
      0.0014824387900674117, 0.0034976341841395277, 0.001162338176853483,
      0.005904939996508666, 0.0011172751047480822, 0.002065550943341966,
      -0.002173647234298767, 0.004912057140040234, 0.0019394799599445052,
      -0.0037701749641512227, 0.0026753384918279557, -0.004393864264810664,
      -0.00197508438577435, -0.0011604666993724278, 0.0020366489269640024,
      -4.0352479837168706e-4, 0.003961994328994413, 1.9579552437813932e-4,
      0.0036006711904467688, 0.0029675299235410084, -0.004456458472954138,
      -0.007402676848941716, -0.001641265912654428, -0.0016508118829453868,
      -0.0010217657298021315, 0.00398652885496437, 0.0022934524110062677,
      0.00462480828288221, 7.072591973135257e-5, -7.317961496059986e-4,
      8.244794673746173e-5, -0.0018020717760429891, 0.0024032806479667695,
      -3.531773568788759e-4, 0.002496796695376724, 0.0030649702171223333,
      0.0030037746575436743, -0.004398194380094728, 7.149502044765057e-4,
      -4.985636628123224e-4, 2.4174790553380635e-5, -0.004553224183749736,
      -0.0014712860466987278, 0.0020518885174518452, 0.0027331902697215224,
      0.008007207182897644, 4.017203380712885e-5, 0.0021930632385754583,
      -0.001369595098806297, 0.0025916494215356633, 0.0013618027624151588,
      0.0034189287954779907, 0.0033883803058677347, 0.0013687689796907133,
      0.0028671405402389038, -0.005114395506289633, -0.0019747980855849105,
      7.66331622539875e-4, 0.0018584439147427603, 0.0018313402927086113,
      -0.001768381063515172, 0.0010472757515553955, -0.0011812634995640027,
      -0.003746095920564783, 0.0024650695531964213, -2.6275391842615523e-4,
      2.8649531343299657e-4, -3.0077203520547876e-4, -0.0014333363689284563,
      -0.0072971028430894105, -0.0020604484418602223, -0.004905952757916027,
    ],
    [
      -3.65459531468937e-4, -0.0030608471210705965, 0.001454732745367149,
      -0.0014904122478181844, 0.0026029451962899398, -3.3456969048864136e-4,
      8.294765565280675e-5, 0.0048855320304559725, -6.474656258400272e-4,
      0.0027080565168116095, -0.005367633086341625, 0.002701082958832055,
      -7.733213236487602e-4, -0.003158782655801348, -0.006109279943181188,
      -7.57241928236537e-4, 0.002712226639603461, -0.002176452353402439,
      0.0057540210874822565, 0.002630553446827213, -5.25514052994793e-4,
      -3.981586379533078e-4, 0.0055035560064788155, -0.003433790144971038,
      -0.006090806783849965, -0.0012448339069269518, -7.606550679290631e-4,
      -0.0017105911666084467, -3.8081891697837013e-4, -3.074375834165733e-5,
      0.001972757941147667, 0.005434335744991289, 0.004246333506814661,
      -1.2804201050832205e-4, 4.1408144999888837e-4, -0.002822449566395957,
      0.002354096363018041, 0.0017301422342957786, -9.2121049865595e-4,
      0.003529732310472866, 0.0022823214498611816, 7.83759997765943e-4,
      -0.003462619283817758, -0.0012882152491718035, 0.0021186064129903874,
      0.002004692829872744, -0.004911385954057526, 0.0010772362963422399,
      -8.665834754884328e-4, -0.0019472023701070109, -0.001689028081512078,
      -0.00234924931126312, -0.0025628655404913176, -0.0021344683348331383,
      2.045252702073033e-4, -0.006457034963571997, -0.003953812877366047,
      0.002337109628216815, -0.003858050162122693, 0.004815206111153523,
      0.0036645186478526134, -9.05848271673563e-4, 9.264078954889765e-5,
      0.0017833051477981418, -0.006318162467208696, 0.002756223081660513,
      0.002790603018932512, -0.005404864162763999, 0.0017374924409775381,
      -5.038487602713259e-4, -9.256338201435231e-4, -4.7946238241549814e-4,
      0.0021474241730081835, -9.913990065686907e-4, 0.006464010336944872,
      0.002456950096838515, -0.009132671724311862, 2.4637914729231823e-4,
      0.0044194427984386945, -0.005001153007770755, 0.0012944522225100505,
      0.002729956951702486, -0.004818733465784238, -0.004176252096632921,
      0.00205569938729019, 0.0015733578316106732, -9.886618664452442e-4,
      0.0013535620455503044, -7.411416093127302e-4, 5.40177827962062e-5,
      -0.0034664605169876257, -9.435892895300027e-4, 0.0021402911630923355,
      -0.0015307547297257653, 0.0031823552100141046, 0.001009650315726042,
      0.0020002583810056398, -0.0019312282902320876, 0.002909017393036726,
      0.0023764607084082465, 0.00215568745079615, 6.97759404272206e-4,
      2.641882799257622e-4, -0.005418968767456711, 4.7154085241920696e-5,
      -5.671840420740425e-4, -0.00431862296596448, 4.17783096065628e-4,
      6.480264837429497e-4, 0.0033611636148845134, -1.7686312015555982e-4,
      -0.0027180929808106693, -0.0011629403709175453, 0.002941663657254643,
      0.0016212172487808305, -0.0018830322891824941, 0.009907302853028382,
      0.007226162151828615, -0.009803203260679778, 2.3751660654077524e-4,
    ],
    [
      -4.6082929966967043e-4, -0.001634148709348552, -0.0072594956603456605,
      0.002283845360839383, -3.6377650105587556e-4, 3.726914566491205e-4,
      -0.001187210177986531, 0.008201326954796945, 9.792091563969694e-4,
      -0.0034624981140500563, -5.463619754832774e-4, -7.102129942471182e-4,
      -0.0033555482044086114, -0.002229038358031005, 6.250783544665018e-4,
      -0.0032544010801154677, -0.0010544907987250149, -0.003090096846657218,
      8.490454853908873e-4, 0.002214962925849719, -2.1627531319824247e-4,
      0.008881208774914547, -0.009242952242710645, 0.005286517826823663,
      -0.005460223521467385, 0.007521041130063575, 0.002316756660721594,
      -0.0018022296357653273, 8.048287396874882e-4, -0.011464222177889153,
      0.0016434850687399413, 0.00326439372012074, -3.829862510739976e-4,
      -0.0016620795193160898, 9.549975385794798e-4, -0.0037663840643099374,
      -0.0011040179287088625, 0.0013820684409962844, -3.864038883349272e-4,
      0.00960605773737704, -0.002445967650536635, -8.485479639845797e-4,
      0.004565405109520733, -3.128708129081577e-4, 0.0013674326559888858,
      -0.0015881712280159657, 8.028545155784178e-4, -0.001712888856951444,
      2.793731942514547e-4, -0.00392109384577132, -0.0021608863820251744,
      3.791505359691257e-4, 0.006273055347155997, 5.92642891614821e-4,
      6.143100284630983e-4, -0.007772878145679411, -0.002224818037578334,
      3.7448823219252147e-4, -9.81133986079802e-4, -0.003939847909319448,
      -0.003801402295389841, 0.00288748067947717, -5.712361734578795e-4,
      1.5056454159425553e-4, 0.0011601313398204368, 0.0026159113612322007,
      -0.0012234893589263083, -0.004938900219331502, 0.001694510974554394,
      0.005935224309266062, -0.0029892914080133356, -0.0021515088515066273,
      0.0035567436575849746, -0.001116016551223832, 0.002872243226258996,
      -0.005892690389276268, -0.0023886719073644425, -0.0032467815448438855,
      -0.0011602856779745017, 0.0010145475639106796, -0.004740933299999906,
      -0.0017420026876156633, -0.0033407685180448284, -9.221554732198202e-4,
      -2.554288164193549e-4, 0.0028815819458023837, -0.003285860412563867,
      -0.0022194104180097377, -0.0013412603347135608, -0.00193481715102059,
      -4.651897657380054e-4, 6.263322998836852e-4, 0.006141343381527214,
      0.001450817086446024, -4.122631699642916e-4, 9.212166366283234e-4,
      9.154013817096997e-4, 0.005086466488855553, 6.715037559760743e-4,
      -0.0030376723057999678, 9.861255146238726e-4, 0.001456382045235516,
      -0.0028678867619696532, 0.002024747983110346, 5.552494322126366e-4,
      0.0020252156794127282, 0.004326140021877253, 0.005012923386935422,
      0.002902593041392746, -4.10277945152353e-5, -6.601636297192612e-4,
      0.0026432141794458623, 0.0028973097142701147, -0.0025848844967583688,
      -3.2572181014806076e-6, -0.0036718254494180706, -0.0035241062778705616,
      0.0019342314231130385, 0.0018835914678799078, -5.71726553779827e-4,
    ],
    [
      -0.0017960120528333616, 0.0016529868775910167, -0.006204750421453525,
      0.0018245830512925824, 0.0013927663498185519, 0.0038590870452523476,
      -0.0025195112170752747, -0.0024365286885498104, 0.004192946143537271,
      0.0013051746155724752, -0.00406109251924377, -0.005849595747128379,
      -0.003575079840140262, -0.004196120660622308, -1.0376806719948702e-4,
      0.002433129911876597, -0.011102716524519293, 0.01070852698042914,
      -0.0037802306902910802, -0.00275686078077917, 0.0022790603001060306,
      -0.003505576191835752, -0.004572464566029684, -0.0028869198382475295,
      0.0017348103000210298, 1.3092950872129996e-4, 0.0036877676610610178,
      -7.352036967393067e-4, 0.001986675516060573, -0.001152322334867369,
      2.9426130616488843e-5, -0.0017227847731354643, -1.0444832804521485e-4,
      0.003685092655921599, -1.7199077632518503e-4, -0.001622558421040385,
      0.002633063184266981, -0.0028415849445744676, 0.0043191508479706086,
      0.002043690655229441, 0.010466696998082229, 0.0022688618174746696,
      0.002185246055712591, 3.2191686269341063e-4, 5.150241854064721e-4,
      0.0024643677767784934, -0.0028690593916747445, -0.00216202279873688,
      -0.0012747553775395923, 0.005747056083605746, -0.003135369525120813,
      -0.003344021934873735, 2.0728297519893654e-4, -0.003118702624202483,
      -0.006503037015704314, -4.663110797675921e-4, -0.0021153016059134054,
      -0.004653242001221962, 3.1087085404155724e-4, 0.004264299611672158,
      -0.005294420116460135, -3.7773820355903114e-4, -5.847436642851074e-4,
      5.306237792401833e-4, 0.004627881828176904, 1.8554936452211821e-4,
      5.054524157831186e-4, 0.005176833454515615, -6.715507888497195e-4,
      0.002078945367603705, 0.0036210498258435796, -0.002706285840142239,
      -0.003138734491403068, 6.398626818279328e-4, -0.0010541176380804308,
      0.002573088318411357, -0.007397637329053581, 2.632733346314596e-4,
      0.0021967660562520652, 0.002993455790712368, 0.0021411490518721712,
      0.0022829390900719632, -0.003109907990445678, 9.073178899151855e-4,
      -0.006856236619976327, -0.005002683596286284, 1.49539609998291e-4,
      -9.012929310054195e-4, 0.002186644055399526, 0.001668923015915179,
      0.0030770344155868308, 0.001714818077953738, 0.006619469101834353,
      0.002173997051610575, -0.0024520337471388006, -0.0014998225774381553,
      -1.9791459703600754e-4, 0.0015447359498595272, 9.778522374265081e-4,
      6.421892733322299e-4, -0.0024006095128963675, -0.0017691167182683006,
      0.004135507271458955, -0.0010882559389592374, -0.004147860319099667,
      9.842512453100167e-4, 8.893438031163752e-4, -0.0023253836621494545,
      0.005576677613581086, -0.003382209496403702, 0.0020159419551966005,
      0.0036948546655000297, -3.1192247788988474e-4, -0.007475897227922428,
      4.6346543103558107e-4, -0.0038028929468351255, -0.005142873403009335,
      -4.0839834559006777e-4, 0.0024658664722366835, -0.001995877655192521,
    ],
    [
      0.00792897961880193, 0.004486984021757017, -0.002740287704382369,
      -0.00633502509768254, 2.326095371296443e-4, -0.004809777311786058,
      0.003992660870736414, -0.001703128115452441, 0.007312688631805071,
      -5.382134315441297e-4, 0.004340810183280534, 0.007951290764279783,
      0.0030673741719184724, -4.478280826497896e-4, 0.0021835043566919795,
      0.007175367038867966, -0.0021134700440424304, 0.0011742732656219233,
      -0.0013933228283690515, -0.0011315256137169822, -0.0012615447074867114,
      -0.0019757134076389266, -0.0015693419690550499, 7.883358033255727e-4,
      7.279862742754595e-4, -7.955685777022011e-5, 0.001222466206770608,
      0.002850601469762215, 9.624472830564336e-5, 6.773580612389526e-5,
      0.0022496594972197673, -2.1292249143755096e-4, -6.255913492338779e-4,
      -0.005091185905907795, 0.005958390847515732, -0.006957535949077625,
      -0.008286905276350367, -3.778436219022245e-4, 0.0023696436438596607,
      8.718074179899702e-4, -0.0035514678438602057, -0.0023915801371385994,
      -0.00214196765946232, 0.0026883263177774923, 0.005639150996967704,
      0.0033883190260476214, -0.0040387000042380605, -9.471374765702097e-4,
      -0.0015123132809397029, 0.004039249462350567, -0.0016800347675653216,
      0.0017795508338848828, -0.003930869677052872, 5.241380409132724e-5,
      -0.00468958312793178, -0.0021999001493289037, 0.0011958352652269406,
      -0.0010898731255854083, -0.0031112745872233626, 8.926438266926809e-4,
      -8.451572066670603e-4, 0.004388461284758716, 0.005388170125105439,
      -0.0016671662731781887, -0.002442949728878906, 0.005331888420523965,
      0.0019741245264906014, -0.001422514819832047, -0.002698457585822804,
      0.00331118243490584, 0.0049139695292915175, -1.3291050894101281e-4,
      0.002523621259073649, -0.0031738949677734074, -0.0021306334715000185,
      0.004410627287842712, 0.0029456728977849955, 0.003409909027595028,
      -5.083522854058156e-4, -0.001846270412507105, -0.0013304389572157294,
      -8.080525538343207e-4, -0.0016814032272872191, -0.0019209686704989158,
      -0.006312807959343137, 0.0017946318106422101, -0.0034281227707372943,
      -1.6382896517824769e-4, -0.002158633901020993, -0.0010640925151730075,
      -0.0031502272713485376, 0.002839180117697652, -0.0022446716781412447,
      0.0017282909738855915, 3.6882863785921984e-4, -8.701617318513965e-5,
      0.002177679115433804, -0.003399541320024396, -3.95441137763545e-4,
      -0.0015586803696722327, 0.001381424031317289, 0.001556366051174977,
      -0.002265827951450502, -0.0021363387593817677, -0.0015865671479949502,
      -0.00406528064817946, -0.005822420645561399, 5.994698474745904e-4,
      -0.0019897727317352938, 0.0013452469158504892, 0.001772255152359716,
      0.004400370122195489, 0.007156740878711598, -0.0027501002979487986,
      0.0022880723534997593, 0.0014928864178406, 0.001413460235947991,
      -0.005855606792330688, -0.0028839829350260937, -4.734748680276674e-4,
    ],
    [
      0.00416666342076042, -0.0018548054912674952, -0.003275859863658442,
      -0.0021503588102945387, 8.270854923441808e-4, -0.0015153442351371845,
      0.0010008638362494445, 0.007259493300742192, -6.829212185401729e-4,
      1.5942416044124527e-4, 0.006571275193925219, -0.0054005986934776585,
      -0.004382783614362679, 0.005479504314637528, -0.0012338084010792578,
      -0.0012788905272682265, 5.076804181423306e-4, 0.002597011511043719,
      -0.00913041478964918, 0.003641843419634267, -0.003346091665673525,
      0.0027387049005261725, 6.748967485906317e-4, 7.190140718017382e-4,
      -0.002046295034972242, -8.467033549763264e-4, -0.007401909684967312,
      -0.005324597198511754, 6.669723084435774e-4, 2.533603882868459e-4,
      0.003153924425268014, -0.002104807726262379, -6.584092911204938e-4,
      -0.002887428123289535, -0.0027358737634654703, -9.398068929209501e-4,
      0.003571794951402249, -0.003464697245753514, -0.0011103414978251413,
      -0.00368230789799401, -0.0023344135512975914, -0.0024481701573386867,
      -0.002171139331744549, -2.575676706309253e-7, -0.001426909881900413,
      0.0030084875463351666, -4.8365045033893565e-4, -0.00288178118957545,
      0.003028397941350407, -0.001721147387434349, -0.004050573187290791,
      0.004332383384451218, 2.7464555039863163e-4, -0.0035038678789618347,
      0.006797159910193976, -4.0027976853102074e-4, -0.0014145307131620372,
      0.004435276110512995, 0.0016690306033232535, 3.344077460599828e-4,
      0.0012482109249548406, 0.0019391976944042794, 0.005665534219901358,
      0.004896174795509518, 5.022331949689799e-4, -0.0069164505847239405,
      -0.002481680541763424, -0.0018429490408771467, -0.0036608643272653812,
      -0.002009928155600916, -0.0028980769843291645, 0.005095830459178788,
      -0.002121577105989696, -0.001246402210732116, -0.0038802418991658776,
      0.009714291695462882, -7.727126295539647e-4, 4.7638220367348446e-4,
      -6.223368413178323e-4, -0.0015924023786183664, 0.006004058725725158,
      4.11731560400748e-5, 3.65932532768585e-4, -6.986537818906679e-4,
      0.0038361295226364543, -0.002836440795933749, -0.00100955199113346,
      0.0011597078576917243, -0.0035258174327079048, 0.0021981131350324885,
      0.004070674395223024, -0.00438317474748633, -0.0017460952841009004,
      0.0034461580307574655, -0.0028799054390081435, 0.001212016623295972,
      6.622898744829697e-4, 0.0013946933089300376, 0.0010613454658757217,
      -0.0023570675013939012, 0.0034423124536438537, -4.7780102478536856e-4,
      -0.002907326949535294, 0.001031143797618146, 0.004018758708908857,
      -0.0014862841191698054, -2.040150814001426e-4, 0.0014106848887809056,
      5.469738669840183e-5, 0.004830227207046076, 0.002962060525883093,
      0.0033755110307881354, -2.676429869817862e-4, -0.004247717479436135,
      -9.587048661985136e-4, 0.007045170152844769, -0.005317617148704212,
      4.329410963482893e-4, -0.001479727796763287, -5.610250795681252e-4,
    ],
    [
      -0.006572179567930365, 8.432546779133143e-4, -0.002383398799618449,
      -0.004326859514661576, -0.002715696227465673, 0.006528103543774208,
      0.0030856047464964927, 0.003091515117177341, -0.0027017805076415364,
      -0.007900237340817151, 0.00463069527377452, -8.265932379937084e-4,
      -0.0025483685373493617, 0.0020492848325185764, -1.0601093371638467e-4,
      -0.002569013348575829, 3.113374273575885e-4, 0.005765652450611732,
      -0.005151007752504324, 0.0012542291983234455, 0.0027565014217832903,
      -0.0014580186898634634, 0.004606282887795826, 0.00242045881920466,
      0.0014678822044935924, -0.0015998924962357186, 0.0033741010498104847,
      4.1802263979626717e-4, -0.006358309326490532, -0.002102489098797403,
      -0.0024969160194790426, -6.6014818682965e-4, 9.296048920853144e-4,
      0.005887130405814964, -0.002125987311796064, -0.001294360634850375,
      -0.003465646074092306, -0.001169358399809652, 0.0026685372812628528,
      -0.001502243592457183, -0.002444477939634207, 0.0023991231335975883,
      -0.004526525415927166, 3.554623495852158e-4, -3.3501099914315467e-4,
      0.004924949441442799, -6.509664721673345e-4, -0.004812887267866632,
      -0.008776664183221608, -0.003311072874418978, 0.0013206316270235983,
      -2.8983984921988404e-4, 0.0032828547938649703, 1.1330438890154397e-4,
      0.0024682524971386764, -0.002024792246836443, -0.0011497646860403237,
      -0.0014232868758263825, 0.004124351683990081, -0.0032340527059481453,
      -0.001050878198237229, -3.8334699765026477e-4, 0.0049082040988130465,
      9.956577249466637e-5, -1.2333403521028812e-4, -0.003247226692548166,
      -0.007561896310383289, 0.004566055747553911, 0.0031953169365669803,
      -0.006988053408894749, 0.0010807633479825362, -0.006606056235508447,
      -0.0028867691504653858, 0.00604570974510074, -0.003586038902489367,
      4.860900928842798e-4, 0.0012827295428338172, 0.006846265472434549,
      -0.003038939275564887, 0.004277873137678467, 0.0018277566105925993,
      0.0010357277880950907, 9.023287533117388e-4, 0.0014304661560382169,
      0.0022986116330973158, -0.004515977666147121, -4.654777397415173e-4,
      -0.001392677356985076, 0.00216630614151859, -7.01183911865931e-4,
      0.0010236061293647284, -0.0018965410381014825, -0.0028187002615166646,
      -0.004102182374472452, -7.798572079912951e-4, -7.901882446878637e-4,
      1.2880018739076291e-4, 0.0012960268254580782, -0.006871927020370619,
      0.001289632371186975, -0.001932563785287012, -0.001630298029214728,
      -0.0030306998112159455, 0.004880944281140165, -4.6934242478994566e-4,
      -1.0525821506170869e-4, -0.00157499826449562, 6.692428670507028e-4,
      -0.0025338031199197883, 0.005075065436328368, -6.842117509300867e-4,
      -0.0052487524237882725, 8.989708158411652e-4, -0.003714262069551566,
      0.001993714777585626, 3.880428124464956e-4, 0.003695357625944795,
      0.0028462800078738627, -0.0015901391455417143, 0.0020797034542901095,
    ],
    [
      1.4403224767534093e-5, 9.031783004206607e-4, -0.0019759151518699675,
      -1.2467282342741674e-5, 0.0030142905356219975, -0.005550481241357534,
      -0.0026720672031109944, -0.0017141508952140234, -2.223557922006524e-4,
      0.0013899523128976447, -8.025979945434733e-4, -0.007138813363420343,
      0.0062391271768342796, -0.0031066998617732414, 0.010352641230286539,
      -5.905122619232868e-4, 0.0023019417564924664, 9.490534501464901e-4,
      0.005417300129804714, 0.004042420318246786, 0.0067515625009358995,
      -0.0029225487000534628, 0.004270679546841058, -0.0010807152185179148,
      -0.0033415575983518483, 0.0010489700048652555, -0.0037724141076740376,
      0.007020468556191039, -0.004722906608654955, 0.0017733337085484662,
      -3.794032389217555e-4, -0.0015700685735951184, 0.004684583752524266,
      -6.126844279142815e-4, 0.0018037509048448334, -0.0018322453268693358,
      -0.0019133953229905887, 0.004768106265417864, 0.006518211576572082,
      0.00948877662276933, 0.0021536322586573385, -0.0028537542310791255,
      0.0024418193482523345, -4.678130526247382e-4, -0.0010229193010796406,
      -0.006218261668749935, -0.0022467411025076725, -0.001425726591907,
      -0.003318050333887864, 0.0013513693892590575, 6.199835117395514e-4,
      0.005914955714477508, -0.00345831788869084, -0.004044309889120752,
      0.004695439535829906, -0.0038867987659473077, -0.002429095584118403,
      0.004486773227221605, 0.002145959606718182, -0.003610552927640275,
      -6.905255741669642e-4, 0.004938449966518809, 3.381153594623636e-5,
      3.1239328954730063e-4, -0.0025490698721135994, 0.002546222166047974,
      -0.005975176525179716, 0.004412042801840488, -0.009426644684759265,
      0.0022996782071593542, -0.006031494468910758, 0.0019683452817094178,
      -0.0030659819310166186, 0.0017815149143432139, -0.004100104728881837,
      -0.0019301527891832798, -0.004494460448792325, -0.004725946430845131,
      8.322743834022633e-4, -0.006330843432605971, 2.8559880878580946e-4,
      0.004912909812911429, 0.004572335393462259, -0.0022827651052182447,
      -0.007754350951638122, 0.0013432553356023023, -0.0010770582968470902,
      2.884388454893537e-4, -0.002635415301538868, -0.003206829931150787,
      -0.0023245552088595432, 0.002093333124762493, 0.002601095339019731,
      0.002793585719374504, 0.0074851789247745906, -0.0014769506779630371,
      0.001922653546414758, -0.0010389842459752946, -8.679151187896039e-4,
      0.002910246622009647, -0.0011488907018157213, 0.0012947109488109596,
      0.001220064452333777, -0.003155989961050266, -0.0016931532280683149,
      0.003181530769302666, 0.0037115900627411906, 0.006705296474950814,
      -0.005103226644693982, 0.0031691609968496504, 0.002360988044269141,
      -0.0022691009727948356, -0.0017915597343273267, 0.0016335362190914343,
      2.2377421967261892e-4, 3.1120382981483823e-4, 9.596564848944862e-4,
      0.0031687839165434333, 0.0026172242854243122, -1.1051216994946436e-4,
    ],
    [
      -0.0014236336079971692, 0.0018756726131559276, -0.0017267803259440918,
      -0.0052456140879668005, 0.004428613824737511, 0.002507720691825169,
      -0.0015942045776877378, -0.0013820811793544769, 0.0030389482832500142,
      0.002724289449371076, 1.735354754333674e-4, 0.002049221627197503,
      -5.596010665429221e-4, -0.004838930026129796, 0.0028387831411217095,
      -0.002049705874325968, -9.916254827291422e-5, -0.001346064653537701,
      -2.0113814224753578e-5, -2.0715707867543148e-4, -1.7108153522190172e-4,
      -9.002067829619045e-4, -0.002391605641361218, -0.004391040325336318,
      0.0010408087546791892, 0.001839714268210427, -0.007838724610360854,
      -0.002331741112494371, -0.002367415002097181, -0.00349712278165126,
      0.005536965182562822, -0.002973335646254894, 0.0011596182609742807,
      0.007275561487970874, 0.00340431317645586, 7.227955805201642e-4,
      0.003056964042831062, 0.0070899991145235205, 0.003276761304103661,
      0.0080694235888005, 5.644407356620536e-4, 0.0016188758278950603,
      -0.0031025508186344536, 0.002833082366787118, 6.717131541934154e-4,
      0.0018166427011877071, -0.003928972784996582, -8.069881026658611e-4,
      -5.25905977810228e-4, -2.827557341549212e-4, -0.0016876709817581945,
      -0.007330131668497776, 0.0018228051203539476, 0.0027090232654421852,
      -1.975817713928414e-4, -0.010665231921410275, 0.001997044022148821,
      0.0017412652096431768, -0.008537363280836218, -8.758714449468763e-6,
      -3.2578290710630754e-4, 0.006221722411366565, 0.0014040943527306995,
      -8.85614552774265e-4, 8.739091236865122e-4, 0.0029651093583791567,
      0.004636405576246255, 0.0025048440594534352, 0.004285683699604266,
      2.234262698326239e-5, 0.0021902325057316574, 0.004091820536249667,
      -0.002046981970102109, 6.089654758680194e-4, 2.8925687688074564e-4,
      -0.002423914011397322, 0.007538206385011053, -0.002904520480606388,
      -0.00453068778923306, 0.0025052200120645046, -5.705344133429598e-4,
      0.0013377938058154201, -0.003154486147773811, -1.6071358872950985e-4,
      -0.0020269812129577207, -0.008993374070559838, -0.001154789109561117,
      0.007059990058529132, -0.0011447597613619613, -2.7256662140647317e-4,
      2.993611076024503e-4, 2.962762411410059e-4, 0.002019573313967245,
      0.0037166279408664613, 0.0010750079056869822, 0.002750926285337344,
      0.003834803247660701, 6.875420739499965e-4, -0.004526005493622602,
      -0.004284572092388592, 1.233495397654459e-4, -0.003394563116354897,
      -0.0016721665361304297, -0.0036397986159459467, -0.0018352845014422562,
      0.003716752138390437, 0.0015243549833997261, 4.548220678305637e-4,
      -3.70826001285877e-4, -0.005351206747038531, -0.004644714743387896,
      0.005530579482727828, 0.0010396354775164952, -0.003174300339035414,
      0.001006329936727529, -8.047798521794323e-4, -8.679952126795557e-4,
      -0.0020269631860684216, 3.023948806568783e-4, 0.005811685332888328,
    ],
    [
      -0.004003679870141929, -0.0015369806689748003, -0.0019250944313497784,
      4.889273392805467e-4, -0.001199190581066151, 0.0018778322467534747,
      -7.116533816755033e-4, 0.004042509174591948, 2.612961728415702e-4,
      0.0020929762988455324, 0.0016838768364634221, -3.225841820215928e-4,
      0.0013323174046674575, -9.115959137601564e-4, 3.488145924556487e-4,
      0.0071153948067192024, 0.0028424755188998546, -6.272494378575602e-4,
      6.450777271078225e-4, -0.0035505454727368774, -5.987927356796986e-4,
      -0.0039102913833269, 0.005189495345390747, -8.288259435799584e-4,
      -0.0026583418347306672, 0.002010821571721113, -0.001465136805197641,
      0.0036837139899902884, -0.0012291266409121615, -0.0014178545117410097,
      0.005289109656822442, -8.826865841694943e-4, 0.0056310877554481,
      0.0017372752890305386, -0.00132806606973088, -0.0033687574764912367,
      -0.0034108501357626676, 7.894558596670312e-4, 0.0019122344209895668,
      -0.0010507766087284956, 0.00818683697438979, -0.0010422842104197516,
      -0.0010226881082251267, -0.0052094268665218504, 3.80132985392183e-4,
      -0.005863185523293233, 0.0023631025125390387, 0.0018559348866462825,
      0.00513545716433257, -0.003952136171793353, 0.0018542344850405665,
      0.002511893539382667, 0.00310961315154606, -0.007202571342679818,
      -0.001422827030723078, 0.002197141135674889, -8.680979307365707e-4,
      0.009375986476367466, -1.6506104776956845e-4, 0.005436023551961236,
      -0.0019131838831687351, -6.547777914867178e-4, 0.003838899943497209,
      -7.748311648845164e-4, 6.280015493518402e-4, -0.00605003120385047,
      -6.784349908533958e-4, 0.004266877930727987, 0.0018925940060531977,
      -0.0039422874514082554, -0.004404006913882753, 0.005184114066025259,
      0.008500541660539455, 0.005397275446712266, 0.0022148346199933394,
      -0.0011595283387818476, -7.012714067249515e-4, -0.0027127856234157454,
      -0.0027155147889093103, 5.971689200640988e-4, 0.003768824429185062,
      -0.0064779666223435395, -0.0025202883392449584, -0.0019273742070406695,
      0.005607047424243887, -0.002571157253570473, -6.218324765498353e-4,
      0.002522221373841554, 3.233291968613659e-4, -4.023316683729847e-4,
      -0.0019346818446616975, -0.0021802803741074765, 0.004184965522911331,
      0.0016166814292728937, 0.0031365028170535075, -3.756016615778497e-5,
      -0.0029397109904263116, -0.004867782348639953, 0.006705386189443831,
      -0.004152371014055963, 8.648978003708251e-4, 6.225316821427457e-4,
      -0.004294855168414557, 3.88652289671951e-4, 0.0034783327036164245,
      0.004175166947378434, 0.00462111570904722, -0.0013856003128997103,
      -9.284231865152434e-4, -7.723471390307828e-4, -0.008620461566423217,
      -0.0071425883692202465, 0.006068257437584068, 0.002997320683950334,
      -0.005998973946261926, -0.0022315013458699753, -0.001397980264831036,
      -0.005255505387529026, -0.0014020087192070795, 0.0029004465185488923,
    ],
    [
      0.0022548830942384296, -0.0037111874661678243, 0.0012422067658426036,
      0.0021858614090725067, -0.0013833295101421958, -0.004232876051605848,
      0.0017536651220589044, -0.008691606933718624, 0.01245713236026949,
      7.373089703768609e-5, -0.0012964634094177027, 2.8846898827841565e-4,
      -6.733636934002825e-4, -0.0022888323974153426, 0.0015881583518962848,
      1.1610538873643199e-4, -0.002456782887380985, 0.0011846149082589774,
      0.0024810989344191572, 0.003528403481845084, 4.976319298656376e-4,
      2.662373157301312e-4, -4.125530466517989e-4, 1.0193531998093592e-4,
      -4.769928256772072e-5, -1.1596654018027563e-4, -3.2126886179095965e-4,
      -0.002320315096942873, -0.003170066020382031, -0.0013887304295023225,
      1.3079650154351554e-5, 0.005917371492029673, 4.797730979810706e-4,
      3.479630107355906e-4, -0.0037191268287440245, 0.006321683666778258,
      0.011135234370267255, -0.006939721830127954, -0.001006728141442021,
      -0.001382615038174314, -0.0020251909462294672, -0.0016494175756064625,
      -3.6942135021581726e-6, 0.0029953927592078025, 1.1026920822132066e-4,
      -0.0028153161344011473, -0.0019000330159049793, -8.634508196115708e-4,
      0.001088743654633541, -7.684131983730779e-4, -9.333731688540365e-4,
      0.002594139553022644, -0.00582093850294456, -0.0024514316849574215,
      4.2722550345355526e-4, 0.0012583020807426363, -0.001874545440949661,
      0.004717104618856949, 3.9354821260877224e-4, 3.7696092672013354e-4,
      0.00423450175290988, 9.783518554457578e-4, -0.004658386968533174,
      0.002807789848553386, -0.00691428444189406, 0.002822422042394447,
      0.004234530792474361, 0.0028554195011140002, 0.008125500843795999,
      3.736461440000621e-4, -0.0033369850916103766, 0.0016786200685353723,
      0.005432864494213293, -2.8008822940272875e-4, 0.0025620989962988824,
      -0.003177219939005856, 0.006212320190805877, 0.0018195608716631633,
      -0.0013161535098700778, -0.005884529893571902, -0.001467795631293233,
      -0.0011291043529036671, -0.003103998441066302, 0.005181090266921281,
      0.0021418083904600413, 0.006459930912521284, -2.543191988785054e-4,
      -0.001480972302689915, -7.731032571408774e-4, -0.0018727572911656227,
      -7.740767929992688e-5, 0.00918366051435597, 0.00279597350161518,
      -0.006454154232008258, 9.758628615840707e-4, 0.0035564492078682857,
      -1.0252580632244157e-4, 0.001806236321842199, -3.83244829157516e-4,
      -0.003985494386925714, 0.004491741407883056, -0.0040436617395308745,
      0.0018784736661373747, 0.001288617634908552, 0.00172824968535163,
      -0.0024871593142781535, 0.0016908274927768829, 8.611223046581247e-4,
      0.0020584906117397353, 0.0016691977313946418, 0.0060835164284405874,
      -0.0038623698765447084, 9.957872403787318e-5, -1.4247270431598402e-4,
      -0.007778934114657895, 0.013180750438032046, 0.006836172295550856,
      0.001206210859063096, 0.004296418673970726, -0.0011250863206041899,
    ],
    [
      -5.075962660058443e-4, -0.0074743678807157096, -0.0024940695483109104,
      -8.566903829650468e-4, -1.5257509073851317e-4, -4.917601825758296e-4,
      -0.001929030842950209, -7.36011202528116e-4, 0.0028541898027159137,
      -0.0024249140311607934, -0.004289138141350789, -0.0013514753433383783,
      -0.0021942735400245603, 0.005795603361860803, 8.278001913149851e-5,
      0.003178737510674327, 0.003967815292553977, -1.223512683234188e-4,
      -0.00208170047983624, 0.0028130909047184944, -0.002393639890696815,
      0.004994005365104678, -0.003335982159148796, 0.0024464348591143577,
      4.617870539738022e-4, -0.004230489923040176, -0.0010873149150728114,
      1.1395857915272133e-4, 0.005852051789665153, 0.003074109622491898,
      -0.0023781853330779815, 0.004167650463947766, -0.006582002965465025,
      -0.003262921637146559, -1.5970490702522616e-5, -0.0018394029895396412,
      -0.006226430780998668, -0.001176040730539116, 0.0017186925270451583,
      -4.476083930243406e-4, -0.003119082878540685, 0.0026825699562999118,
      0.00205424040347867, 0.0047352915905288, 0.0024949237481710906,
      8.447358731086623e-4, 0.0010013248442367925, 0.006775991785921875,
      -0.003971033340768637, 0.001918417833361392, -0.0014046015197018867,
      -4.7686123131821217e-4, -0.006712272708634949, 0.004233939921951819,
      -0.0010095014780974639, -4.908506185231458e-4, 7.678250445515192e-4,
      -0.0033425197843615266, -9.545158271976303e-4, -0.004510782430219568,
      -0.002317646394573084, 0.003755931899001855, 0.0027297957868374642,
      0.0017684964526844798, -0.001967277506322987, 0.005858614185780995,
      0.002954899178057937, -2.874024504744427e-4, -0.001515761399857039,
      -0.0015318352722312025, 6.712565911175963e-4, -0.005134891971968099,
      -0.004419943577648527, -0.006244920429380508, 0.005253433498342081,
      -0.0018961677099485433, -0.0016314910674781091, -0.0037941008872086897,
      0.0011179408415730805, -0.005645829769601837, -0.0028473768076397628,
      -0.0038064036468494645, -0.0058317783076842, -0.0031019998007473485,
      -8.490471986564241e-4, -0.004283098265433812, -0.009764827228937718,
      -0.002192233853684308, -2.227177114039086e-4, -0.0031395815570226535,
      -0.0027224316946320034, -0.002495612553386483, 9.791117091457915e-4,
      -6.772724347777561e-4, -0.0016967779870584677, -0.0035390692935394472,
      0.004498074909992649, 0.0015019845135255023, -0.0014308458961514912,
      -0.0030884629000885403, -5.481782260824519e-4, -6.910463111247205e-4,
      -0.0063245437235556964, -0.002546459169281102, 0.0017939420888443806,
      0.003184944235279253, 0.004638642413039226, -2.224068599210742e-4,
      7.71133152757718e-4, 3.2798289218681866e-4, -0.003807796501187854,
      2.859321103130678e-4, -0.004871475843530955, -0.002246924026014818,
      6.097300278712419e-4, -0.002398426834302907, -5.302785136393395e-5,
      0.006961922159631094, -6.91165501088362e-4, -0.00307520625329304,
    ],
    [
      0.002027722946074722, 0.0014255205961724174, -0.008232000363113383,
      4.632560386604462e-5, -0.0010620820953789216, -7.571566935073933e-5,
      0.0038337071851988333, 0.0030660063835553594, -2.9515937576508612e-5,
      -1.2696110872470747e-4, -0.0020611079406323064, -0.004351291217285651,
      3.646776405401451e-4, 6.481620040069329e-4, 0.00827312274677406,
      -0.0034662803095472944, 9.706159044756105e-4, 0.005322004283248934,
      -0.004120545197944631, 0.003318375758637406, -0.004753923008255426,
      0.004601709127942396, 0.0011349460485894482, -0.0032431585102565465,
      -0.0010854324493766907, -0.0016398270150235444, -0.003586713882906047,
      -0.002202703789076207, 0.0015367711793043267, -0.0021981855040313345,
      0.0021854727959664966, -0.002250117585605409, -7.026903712476001e-4,
      9.551135799891204e-4, 0.0022984179672793465, 0.007398473752894623,
      -0.008630584230769678, -0.0029169693996306135, -0.006732744117163479,
      -0.0012367946101525464, 0.006731509961103685, -2.2454907270287814e-4,
      0.0013720094598231635, -0.0018179090917076653, 0.001583029512638944,
      -0.002080026434812722, -0.005173123596229744, -0.007821604949800198,
      -0.003734631226511645, 3.8230162803869704e-4, -0.002994366324659025,
      0.008164250343823018, 0.002184107546674242, -0.0025219524264537265,
      -0.006979333982216465, -0.0010458401422547533, -0.002023153736877506,
      0.005120307861668588, -0.0016493110571159364, -7.131841624926293e-4,
      -4.677449692368791e-4, -8.58673484613185e-4, -0.001232600292328635,
      0.001118448143248877, 0.0024383790203862292, -0.003108475680818058,
      5.181673668792468e-4, 0.0038060218302315265, -0.00244243982044368,
      0.0011614585241978336, -6.280135846669892e-4, 0.003605370455347557,
      0.0012831814833849237, 0.0012663047546424186, -0.0013965255670430104,
      0.0030683604113009764, 4.1844278065695134e-4, 9.40235542319589e-4,
      -2.144957622375162e-4, -4.736760736345802e-4, -0.0016420404437129262,
      -0.004215951248011592, -0.005777436583658384, -6.38355262242559e-5,
      9.619658585358696e-4, -1.3403242304538124e-4, 9.191066572340654e-4,
      -0.0016946470929823949, -0.005288642299799959, 6.348710161373221e-5,
      0.0015939750917971867, 8.295201988224864e-4, -0.003037165758653108,
      -5.11703759177769e-4, 0.003966734211067004, 8.242770644723628e-4,
      -0.0011784173435050835, -0.002649420533512084, -6.692408439877393e-4,
      2.988402579045345e-4, 8.864915698273842e-4, 0.0014205297006916959,
      0.0015640356305367488, 0.0033992851750486164, -7.70578150201408e-4,
      -0.0026158329313877415, -0.004457055858178821, -1.1452361456672206e-4,
      -0.0037404232287530266, 0.0013486604655265477, -0.0016503378201913736,
      -8.588924790011575e-4, -0.001407485949665436, -0.001055930968915631,
      0.004948065912093543, -0.0016161421118940558, 3.502993883341956e-4,
      0.0031766484873843375, 0.006825032986516882, -0.0046436082738510786,
    ],
    [
      0.002414527434563168, 0.005120818030093144, -0.0029413886938680885,
      0.001631476568583301, 0.001257207328517692, 0.008232117101997744,
      0.0038528497702416805, 0.005144487765438181, 0.002809245939411154,
      -7.437287642478063e-4, -0.0016655379891512368, -0.004465088027835381,
      -0.003979286754830556, 0.00393006411642008, 0.0010698937047342034,
      -5.369538699530523e-4, -3.8744025874484675e-4, -0.0013512430496852058,
      4.1034900036430636e-4, -7.104576314702778e-4, 0.0026031090230646957,
      -0.002167120639211235, -0.005801656025690462, -5.82214873151584e-4,
      -4.213213499692158e-4, 0.002737171773795, 0.0018623872333956887,
      0.0011588585433972586, 0.002039166287627404, -0.004402125050601086,
      -0.0023313496539267625, 0.0033055043759549546, -0.003476463521552886,
      -0.002877843248747108, 0.004561063666619892, -2.8245014118103025e-4,
      -0.002593478927881859, -0.002877907955732821, 0.003394045335477702,
      -3.8114879105277156e-4, 0.00917928043990229, -1.5276692851481767e-4,
      0.003227773247614263, -9.054133243086847e-4, 0.00150005424398793,
      0.007309447593500074, 0.002445164701089384, 0.0056748324943724305,
      -0.0016360541727388476, 0.006367407563616293, -0.0033227697226537716,
      0.0011706520006250201, 0.0020390719187156083, -0.005082282095919836,
      5.795647009413146e-4, -0.0036292517756691786, 9.55996222876302e-5,
      -0.0015780083879038218, 9.519915159869948e-4, 0.0019085299105599661,
      0.004298242486657803, 1.0038113291061391e-4, 0.005888637759054373,
      0.0012749982816458178, 0.004608392362155507, -0.0024097260982139123,
      -0.0032796903249583944, -0.0018437322133885185, -0.003530018839340849,
      -0.002776546567390088, 0.0026102704537714627, 0.006967732808854016,
      -0.001659074359680884, 0.004042944392214715, -0.0012545635449928928,
      -0.0019534732327136373, -6.133723610242258e-4, 0.001518809404903364,
      -0.00120073935630394, 0.003196882429409845, 0.001528824993990068,
      -0.012131666361660732, 3.694749728693514e-4, -7.825578551882502e-4,
      0.00136826242554239, -0.0022656828557893697, -0.005683918676396217,
      0.0053048301423374526, -0.004136896657638929, 5.721687171431273e-4,
      0.001610497522163939, 0.005192840027412692, -0.0020358804252275688,
      0.0023215029656777617, -0.0025240778339801723, -0.0018934151638900006,
      -0.004959420182110851, 0.005208369878528657, 0.0014235908425035745,
      0.002139517243198853, -0.001094526145782956, 0.002777319931779602,
      1.5371515776428703e-4, -4.309148719674414e-4, 7.281977032013179e-4,
      0.0021604651870221316, -0.0011567270887532259, 0.004848602357093096,
      -6.826897674410432e-4, -0.00405321106103963, -2.0578847241254475e-4,
      0.0022983957486282817, -0.0010246010265288694, -0.00607516246575762,
      -0.006514347547765244, 7.731511136141584e-5, 0.004831154185398722,
      -0.006841994302106689, 0.0011446399523598449, 0.0024212284204549756,
    ],
    [
      -0.003116113936351671, 0.003501373389746677, 0.0013312711234056214,
      -0.0031857264047282655, 0.0024391563394152533, 1.932002267792764e-4,
      0.0012824128980267915, 0.0013830828325387301, -0.002157630425199444,
      -0.0011362967992796674, -8.491814982349332e-4, -0.004004722148183109,
      -7.334229746247516e-4, 6.200226195272971e-4, 5.907581971474039e-4,
      0.001834919158877375, -0.005876459916492794, -0.00259550143175985,
      0.008120294996557437, 5.919319141471758e-4, -0.009248579497693782,
      0.0037271649266921564, 6.234432061023854e-4, 0.0010366725354466445,
      -0.002242270771275529, 0.0020685746951541115, -0.0018740536171013111,
      -0.00392778307448807, -0.0082994555569045, 3.610611960122692e-4,
      -0.004475634340209715, 3.1786746790484215e-4, 1.0940742860999445e-4,
      -0.004685105438902162, -0.004340888675662884, -0.002064261602638155,
      0.0020347926865266763, -0.0010789607932779636, -8.678090283199661e-4,
      0.0013521084698046699, -0.0040440569966603, -0.005358141749236554,
      -1.5556327767102422e-4, -4.2192521946323065e-4, -1.57810458597857e-4,
      0.0012919326296011602, 4.952299226954908e-4, 0.0057951551948298265,
      0.0052922041620395415, -0.0024817083968601437, -0.0023287399706113177,
      0.002181574817146253, -0.004007022064330656, -6.709468788754479e-4,
      -0.0010632488892235335, -0.0015856179496935088, 6.621840208368382e-4,
      0.0045379228520456465, 0.0013235235638258702, -0.001513494845734994,
      -0.002147383577520037, 0.003142119526845807, 5.852312767952595e-4,
      -0.0021578422774537207, -0.0016872059985799056, -3.074062618470412e-4,
      7.167071708938672e-4, 0.008171791090455328, 0.0017266126644341028,
      -3.3312217287582226e-4, 0.0012597968942995772, 2.728568050662516e-4,
      -0.006344950568182721, 6.421284728346506e-5, -0.00298421840955017,
      0.001826998157187815, -8.083910866697922e-4, 0.0014449127612181695,
      3.4001371198635595e-4, 4.28214602636313e-4, 0.0016249241578822663,
      -0.0024195089914021825, 0.006741729027353503, 0.0010566144131189288,
      -0.008486190408508305, -0.0029471069689458924, -0.002745626664070862,
      -2.1852918789144217e-4, 0.0010605595958471095, -0.0034313931036783147,
      -7.667411225013349e-4, -0.00479275344384487, -6.064385001201135e-4,
      3.234200321886011e-4, -0.001644284463617604, 0.00225616230586204,
      0.001533819496106465, 0.004022307773146806, 9.153936784902508e-4,
      -0.0014384126780449917, -0.001139509352611921, 0.00441184084426786,
      0.008257761366122541, 0.0012220608867332505, 0.0030997296636677755,
      0.00376579194402671, 9.833047337162453e-4, 0.0011363631693231966,
      -4.70840700952566e-4, 0.0042882226806190745, -0.0017582850409391358,
      -3.4320721071099395e-4, -0.003390701814848601, 0.004000256158180872,
      0.0099805317696451, 0.00215150159254485, 4.806693089092856e-4,
      -3.2546031168919015e-4, 1.673102138884661e-4, -2.0675743199945158e-4,
    ],
    [
      0.005106155895591182, -0.005335336929790867, -0.0016413901736617978,
      -0.004849239542861712, 0.0014242133761448882, 0.0030396114215674825,
      0.004689180054511372, -0.001996572521503976, 0.004124703685415185,
      -0.003554635173452639, -0.0022724806124683073, -0.0018926671500574038,
      -0.004429216512069917, 0.0014302137292547747, -1.00928570224104e-6,
      -0.0018988163370374576, 8.948833843958832e-5, 0.0019898070242556987,
      -0.004615519433439547, 0.008007420697474864, -0.004067607584593078,
      -0.002457548281575542, -0.002103677023998836, 0.0032827618639221443,
      -0.0031341886258839896, 0.0033340381147016953, 0.00896907986288457,
      -0.0038490103604334144, 7.100328419997932e-4, -0.0019030286321281358,
      0.0011632674262228209, 0.001409444365039568, 0.0013540436188667845,
      -0.0028218611379348633, 0.0019756355529407625, -0.001090543650392927,
      -8.968601042650189e-4, 1.9308718840253182e-4, -0.0014094872696115792,
      0.00543043145871922, -0.009062087454104886, 0.002394844625491426,
      0.0071870588075788455, -0.0013305748311935183, -0.001120811277862485,
      -0.0019239556964309938, 0.00299651694974719, 0.006220555825457908,
      0.0034869973335869015, 0.0015716733516060398, 0.0011812542708885304,
      -0.002077102125667657, 0.005584949182652384, -0.0027427618302738543,
      -0.0013365575748831595, -0.004168862328297735, 0.0012500836404398979,
      0.004411348828716505, -0.0017957236292452075, -7.973090327788113e-4,
      0.0012388715346830612, 0.0031810466056242867, -0.00579106032990993,
      -6.541716866706071e-4, 2.0884900412974499e-4, -0.0027546116582634872,
      4.950052193544711e-4, 1.699107647828294e-4, -0.003091486208065898,
      0.004068131874597881, 0.00262136435481726, 0.0025306236923504674,
      -0.002138727877760499, -0.00541840742760741, -8.390164003782904e-4,
      0.004802183871301114, -0.002394467493748275, -0.00423573721463737,
      -0.0034934594333297713, 0.0013002813451913429, 8.000250948940045e-4,
      0.00253562810542887, 0.0029304716804733396, 4.247019049962728e-4,
      0.0028571707767150864, 0.0021473579450750693, -0.0014103171622394024,
      0.004284546629957061, -0.00201802348511829, 2.536072309378443e-4,
      7.619797304682235e-4, 0.001733018244024741, 8.337212755138802e-4,
      -0.004509604848641749, -3.587970261333441e-4, 0.004908698826165869,
      -3.197198411976613e-4, -0.006949854859083341, -6.716223874860021e-5,
      3.57329491921134e-4, 0.0012821460029294905, 9.188602032752787e-4,
      -0.0034031134059017932, 0.0022541138701240825, -0.003515330419020413,
      0.0031294199828552334, -0.003448304765366411, 0.004232229314503591,
      0.003078032738923899, -0.004648785244430529, 0.0044217963088248125,
      -2.4198639874014626e-4, -0.002893115235875666, 0.0019151718023956573,
      -0.0027378259417472884, -0.005357698646451286, 0.002385874808999184,
      4.8186229605299066e-4, 0.0017173589644950017, -0.0038313082192926794,
    ],
    [
      0.002007721777970952, 0.002068321302766572, -0.004890724686852017,
      0.006280981077643038, -8.664516602914141e-4, -0.0016854049573766798,
      -4.257243865235497e-5, 0.0036082195343084553, -9.824065111016355e-4,
      0.001378996271124585, 0.0038274503230061774, -0.0043279333328747754,
      -0.0012492171564064394, -0.004907322242292319, 0.0011765598390483115,
      -0.002752746434940419, 0.0024941850522907623, 0.006794828312791292,
      0.0032144479820999925, 0.0018987417148669512, 0.001753682744427612,
      -0.005888544284844066, 7.750551605989746e-4, -0.0013739189501860722,
      1.075396411985057e-4, -6.368638020327168e-4, 0.004547582653148538,
      8.988044073217541e-4, -3.9143052827810313e-4, -0.003553367966421163,
      0.00153980886788831, 0.0014158332736047274, -0.0031561858687506545,
      0.0035104764330335892, 2.9876079330931366e-4, 0.002594009946989653,
      0.008584271071015323, 8.080008061233308e-4, -0.0021210642789567537,
      -1.0591342089108122e-4, 0.0021324026757942875, -0.009722555305926518,
      0.007968670980339626, -0.005567953736753754, 0.002544368019308873,
      -0.0024829427453225135, 0.002717866577356836, 0.007952955482746524,
      -0.0010103438598931262, 9.545910108661656e-4, 0.005519950895888799,
      0.003115088566396948, -6.873925144864001e-4, -6.118022231513082e-4,
      6.83696438186094e-4, 6.73502625180512e-4, -0.0011182591125824311,
      -7.687479775976677e-4, 0.004826916246720221, 0.005738195814241137,
      -0.0013886077944906827, 0.004321734009034158, 0.0019588614883949826,
      1.300188363565057e-4, -0.003734393665994833, 0.004655115096624281,
      7.301312995067269e-4, 0.0037466927049741756, -0.001859013428107786,
      -0.005028958393204204, -0.0013984537793823183, -0.0014138907374779274,
      0.0010667194784477032, 0.0018495582036942974, -6.068942729606192e-4,
      -0.005696050796435349, -0.005345628996862973, 0.0028706488306804996,
      -0.006652652215943793, -0.0027701077149818857, 0.004180129290251964,
      -7.360446920789473e-4, 0.006481065233102732, -0.0028464705890564133,
      0.003009208485842559, 2.499633457370045e-4, -0.0048492110282185915,
      -0.0035961323534679165, -7.691388017713056e-4, 1.321151307867634e-4,
      7.187853634364897e-4, 0.004880736013669687, 0.0010174735577366055,
      0.0033764950008436613, 0.001460201020591669, 3.7892362739150656e-5,
      0.005267207539940524, 0.004691539547635605, 5.532418835573324e-4,
      -0.0035695946360120388, -0.0036876966281960944, -0.002604065527864124,
      5.556047951775001e-4, -0.006450598999046651, 0.0013680724957720078,
      0.006029035469738502, 0.0021363215722441853, -0.002251532043689339,
      9.811946195578732e-4, 0.00394553029343063, -0.0032710894570393825,
      0.006026810810685339, -0.002551882353534675, 6.249115125838908e-4,
      8.168436290885558e-4, 0.002911994616627754, -5.6307998266108e-4,
      7.521820542200548e-4, 5.378353899765011e-4, -0.0010178189493681045,
    ],
    [
      -0.00437047291853703, 0.0033004911052815274, 3.5020366065582247e-4,
      -0.0025020108458033603, -0.001663685104509276, -1.80393043015098e-5,
      -0.010014219272493408, 0.0010460538511302515, -1.9665477037262565e-4,
      4.64774090558509e-4, -0.0011877182409143704, 0.002951591353412171,
      7.87151574658544e-4, 0.001039303787376727, -0.0017946443458675217,
      -5.08584886586914e-6, 0.001394627764707495, -0.0023923884388790733,
      3.491375039801226e-4, -5.002646645899616e-4, -0.006203473012918969,
      0.00574549001208347, 0.0020839433862478215, -0.0037643635604707962,
      0.002915571247622123, 0.004185895651568659, 0.0018535497687948658,
      0.003731215361199896, 2.1845360004526493e-5, 7.217860372708545e-5,
      -0.0035584692542560735, -0.0030273480242646086, -3.9508815128876047e-4,
      -0.0016954338601669902, 0.004092007140061079, 0.007184211025099934,
      -0.007022887796541536, -0.004035915886215038, -0.002216999818558562,
      -0.004192765486045094, -0.005244629051760605, 0.004663705785352211,
      -0.0024951513752985315, -0.002019740070291397, 0.004717254683194743,
      -4.866606879837743e-4, 0.008519752539273784, 0.005962200009483252,
      0.0012520876754503513, -1.388719672405125e-4, 0.0024995890028072093,
      -0.004595049908453488, -0.001504983297546521, 0.0016796229615208052,
      3.9822001441644164e-4, 0.0021773875287622218, 9.410612719349884e-4,
      -0.003161640810883634, -0.0036016569543065938, -0.004831929652963797,
      -0.0013584897410146189, -0.00793702205217195, -0.0016176453242274479,
      1.5135714185783954e-4, -4.8785624827695623e-4, -4.585890430134338e-4,
      -0.003945019327059762, 0.0043836980331833295, 0.005792098671958153,
      -0.002527578995101188, -0.004741937716333501, -0.001765707692750908,
      0.0011258082608358805, 1.8718815571752e-4, 0.001823819735952308,
      -0.0018096690825372308, 0.002624896030224932, 0.004143824087755858,
      0.006935439070636276, 0.005824507921795202, -0.004349289761806727,
      -1.3437371926617824e-4, -0.0019619217843929422, 0.01319862649742771,
      -0.001363338275755163, -0.002556881148099097, -8.168435677333627e-4,
      0.00402535013859494, 0.004511276071464971, -0.0075976424638477345,
      -0.0025175181836615615, -0.006243234171066972, -0.0034370026411988464,
      5.437640104305097e-4, -0.0035812138967523974, 0.0013073057617695304,
      1.0366432837402986e-5, 3.2041084632879034e-4, 0.0020226635317704324,
      0.0035986058910512197, 0.0017226224997862884, 9.682177302012705e-4,
      8.64803331647438e-4, 0.007806747633707854, -0.0026944424970525528,
      -0.0035944500916930775, 5.213066936196431e-4, 0.0023319136309289347,
      0.0018601191931112097, 0.0026044863530689886, -3.636121379472926e-4,
      4.276805020628032e-4, -0.0050270871902898945, 4.464078247295682e-4,
      0.0013736138404987792, -5.056746014339917e-4, 0.003706584149353468,
      -0.0015000039685391273, 0.001178243854168337, 0.0019096921243348382,
    ],
  ],
  W0: [
    0.00333856661057908, 0.003207232609107209, -0.0024935306081396227,
    5.498766436414668e-4, 0.0031470064425165355, -0.01009653021572058,
    5.946689229307271e-4, -0.005123672934368688, -0.0021646948914589023,
    0.009219835769084559, -0.0010210484784291172, 0.0014003101942471614,
    -7.635760485508903e-4, 0.005616852460323235, 0.001461122795308079,
    -0.003088522123785212, 4.697408503121762e-4, -0.0026503336833822713,
    -0.004223587372650522, -9.910543084306936e-4, -4.7004796065997145e-5,
    -7.722149407771362e-5, 0.0068703135576472455, 0.003745373705643134,
    -0.005480742029119455, -0.006560688267195726, 0.0011463963607668726,
    -0.003330646614234733, 0.0012534132009923951, 0.006048530496247018,
    -0.0013002635993724296, -0.0019413102820068908, -0.00202658898294464,
    8.522604804395169e-4, -0.0021751756350440327, 3.8491753022294273e-4,
    -0.0020569441162199744, -2.2875720438661278e-4, 0.0011319426154952445,
    -0.0011475443243234682, -0.002995644751734255, 0.0014133601939990232,
    0.0011462452001641615, -0.004730482828604625, 1.3185289027805723e-4,
    -9.88818175560291e-4, -0.0038978667998544954, -8.620367211023446e-4,
    -0.0023456425522985526, -8.762409774615689e-4, 0.002976301039224167,
    -0.003567622689201847, -0.004918127360901175, 0.0013768993256334683,
    -0.006444704237236674, 0.005651279234926393, -3.680454496977829e-4,
    -0.0028866436094879488, 0.0015853966573357741, 2.1968018278260777e-4,
    2.2917317492462675e-4, -0.0026619899890031364, 0.002160669853371402,
    -1.0557314509964926e-4, -0.0010991626922681737, -0.006772781570710349,
    -0.008010223084334939, 2.6338714567497113e-4, -0.00471730257258167,
    0.005369147640661152, -0.0033755976047202775, 0.003121288178889563,
    0.005317170757780298, 2.2758926602580094e-4, 0.0021404388049217037,
    -9.353079893619734e-4, -0.0021061086873695564, -1.1494811497362213e-4,
    0.007282659687494407, -0.0017779393418458364, -0.0017636943689928845,
    2.131462269468916e-4, -0.004485351147446832, -0.0024809955593083333,
    6.56176910519277e-4, 2.1893783614881872e-4, -0.00503407289572912,
    6.974795925923827e-4, 0.002473691623924326, 0.00488060509946416,
    -0.0014922178286240098, 0.0013821192786097002, -0.0024208139376170838,
    0.0013946688208035342, 0.0015603676038311361, 4.755443249066597e-4,
    -0.0024521557925288482, -0.0020821481716741643, -0.0062214924730493205,
    -0.002366609624903055, 0.008585748538289478, 0.002840106003810356,
    -0.0046982069134902694, 0.0020423259501214995, -0.0013163209944368768,
    -1.671859020550941e-5, -0.0017353872132739822, 5.065573867367755e-4,
    0.002251314212217131, -0.0027522910648037887, -2.3269654486442355e-4,
    6.0149574757868e-4, 0.0026360793644197612, -0.004080253429311329,
    -0.010028800198127037, -0.003468775956004334, -0.006443469467988615,
    0.002925960393310756, -0.0011568489828190588, -0.003917107000124038,
  ],
  W_AI: [
    0.0029174669884564986, -4.3291048633493435e-4, 0.01092513891819393,
    0.005049385093303488, 0.002575770346578184, -0.010557645757611417,
    -0.0044509590392981715, -5.922058730621137e-4, 0.015905275600431555,
    -0.001984699695627891, 0.005821394304239997, 0.00332645427294971,
  ],
};

export default MediumAIPlayer_data;
