import LocaleStringConsts from "./LocaleStringConsts";

import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { Stage, Layer, Text, Group, Arrow, Circle, Rect } from "react-konva";
import Checker from "./Checker";
import {
  GameBoardBackGround,
  getIndexesFromPosition,
  getPositionFromIndex,
  sceneWidth,
  sceneHeight,
  radius,
} from "./GameBoardBackGround";
import { Player } from "../nardy/player";
import { FIELD_SIZE, FIELD_HALF } from "../nardy/nardy";
import { BoardState } from "./BoardState";
import { DicePanel } from "./DicePanel";
import { FullBoardMessage } from "./FullBoardMessage";

const GameBoardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
`;

const GameBoard = ({
  currentGameState,
  doMoves,
  choosePlayersSide,
  aiTimeOut,
  changeAIMoveTime,
}) => {
  const divRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: sceneWidth,
    height: sceneHeight,
    scale: 1.0,
  });

  const updateSize = () => {
    const checkSize = () => {
      let w = divRef.current.offsetWidth;
      let h = divRef.current.offsetHeight;
      let scale = 1.0;

      if (w / sceneWidth > h / sceneHeight) {
        scale = h / sceneHeight;
      } else {
        scale = w / sceneWidth;
      }

      setDimensions({
        width: sceneWidth * scale,
        height: sceneHeight * scale,
        scale: scale,
      });
    };

    checkSize();
    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  };

  useEffect(updateSize, []);

  const initBoardState = useMemo(() => {
    return BoardState.createBoardStateFromGameState(currentGameState);
  }, [currentGameState]);

  const [boardState, setBoardState] = useState(initBoardState);

  useEffect(() => {
    setBoardState(initBoardState);
  }, [initBoardState]);

  const [selectedChecker, setSelectedChecker] = useState(null);

  const createUpdatePositionCallback = useCallback(
    (checker, pos) => {
      setSelectedChecker(null);

      let indexes = getIndexesFromPosition(
        pos.x,
        pos.y,
        radius,
        checker.posIndex,
        checker.posCount,
        boardState.put_places,
        Object.keys(checker.targets).map((v) => Number(v)),
        Object.keys(checker.backwards).map((v) => Number(v)),
        Player.WHITE_PLAYER === boardState.current_player
      );

      let start = checker.posIndex;
      let end = indexes.positionIndex;

      if (Player.BLACK_PLAYER === boardState.current_player) {
        start = (start + FIELD_HALF) % FIELD_SIZE;
        if (end < FIELD_SIZE) end = (end + FIELD_HALF) % FIELD_SIZE;
      }

      if (checker.posIndex >= FIELD_SIZE) {
        start = FIELD_SIZE;
      }

      if (indexes.positionCount !== -1 && indexes.positionIndex !== -1) {
        if (start < end && indexes.positionIndex in checker.targets) {
          setBoardState(
            BoardState.createNewBoardStateByPrevStateAndFrontMoves(
              boardState,
              checker.targets[indexes.positionIndex]
            )
          );
        }
        if (start > end && indexes.positionIndex in checker.backwards) {
          setBoardState(
            BoardState.createNewBoardStateByPrevStateAndBackMoves(
              boardState,
              checker.backwards[indexes.positionIndex]
            )
          );
        }
      }
    },
    [boardState]
  );

  const doMovesCallback = useCallback(() => {
    doMoves(boardState.done_moves);
  }, [doMoves, boardState]);

  const doChoosePlayersSide = useCallback(() => {
    choosePlayersSide(boardState.done_moves);
  }, [choosePlayersSide, boardState]);

  const checkerSelected = useCallback((checker) => {
    setSelectedChecker(checker);
    // boardState.selected_checker = checker;
  }, []);

  useEffect(() => {
    setSelectedChecker(null);
  }, [boardState]);

  let fontSize = 18;
  let fontColor = "white";

  const drawMoveTips = () => {
    let front_moves = [];
    let back_moves = [];

    Object.keys(selectedChecker.targets)
      .map((v) => Number(v))
      .forEach((target_index) => {
        let coord = getPositionFromIndex(
          target_index,
          boardState.put_places[target_index],
          selectedChecker.radius,
          Player.WHITE_PLAYER === boardState.current_player
        );

        front_moves.push({
          x: coord.x,
          y: coord.y,
          fill: "green",
        });
      });

    Object.keys(selectedChecker.backwards)
      .map((v) => Number(v))
      .forEach((target_index) => {
        let coord = getPositionFromIndex(
          target_index,
          boardState.put_places[target_index],
          selectedChecker.radius,
          Player.WHITE_PLAYER === boardState.current_player
        );

        front_moves.push({
          x: coord.x,
          y: coord.y,
          fill: "red",
        });
      });

    return (
      <Group>
        {
          <Circle
            x={selectedChecker.coord.x}
            y={selectedChecker.coord.y}
            radius={selectedChecker.radius}
            stroke={"black"}
            strokeWidth={3}
            opacity={0.8}
          />
        }
        {front_moves.concat(back_moves).map((target) => (
          <Circle
            x={target.x}
            y={target.y}
            radius={selectedChecker.radius}
            fill={target.fill}
            opacity={0.3}
          />
        ))}
        {front_moves.concat(back_moves).map((target) => (
          <Arrow
            points={[
              selectedChecker.coord.x,
              selectedChecker.coord.y,
              (selectedChecker.coord.x + target.x) / 2,
              (selectedChecker.coord.y + target.y) / 2 +
                (selectedChecker.posIndex < 12
                  ? selectedChecker.radius
                  : -selectedChecker.radius),
              target.x,
              target.y,
            ]}
            tension={0.6}
            pointerLength={10}
            pointerWidth={10}
            fill={target.fill}
            stroke={target.fill}
            strokeWidth={3}
            opacity={0.8}
          />
        ))}
      </Group>
    );
  };

  const groupRef = useRef(null);
  useEffect(() => {
    groupRef.current.cache({ pixelRatio: 2, imageSmoothingEnabled: false });
  });

  return (
    <GameBoardContainer ref={divRef}>
      <Stage
        width={dimensions.width}
        height={dimensions.height}
        scaleX={dimensions.scale}
        scaleY={dimensions.scale}
      >
        <Layer ref={groupRef}>
          <GameBoardBackGround
            sceneWidth={sceneWidth}
            sceneHeight={sceneHeight}
          />
          <Group>
            <Text
              text={
                LocaleStringConsts.LEFT_TO_END_STRING +
                boardState.gameState.field.black_data.sum_to_end +
                LocaleStringConsts.DICE_SUM_STRING +
                boardState.gameState.dice_sum_for_black +
                LocaleStringConsts.STEPS_DONE_STRING +
                boardState.gameState.steps_done_for_black +
                LocaleStringConsts.STEPS_LOST_STRING +
                boardState.gameState.steps_lost_for_black
              }
              x={65}
              y={38}
              fontSize={fontSize - 4}
              fill={fontColor}
            />
            <Text
              text={
                LocaleStringConsts.BLACK_STRING +
                boardState.gameState.players[1].name
              }
              x={65}
              y={15}
              fontSize={fontSize}
              fill={fontColor}
            />
            <Text
              text={
                LocaleStringConsts.LEFT_TO_END_STRING +
                boardState.gameState.field.white_data.sum_to_end +
                LocaleStringConsts.DICE_SUM_STRING +
                boardState.gameState.dice_sum_for_white +
                LocaleStringConsts.STEPS_DONE_STRING +
                boardState.gameState.steps_done_for_white +
                LocaleStringConsts.STEPS_LOST_STRING +
                boardState.gameState.steps_lost_for_white
              }
              x={475}
              y={38}
              fontSize={fontSize - 4}
              fill={fontColor}
            />
            <Text
              text={
                LocaleStringConsts.WHITE_STRING +
                boardState.gameState.players[0].name
              }
              x={475}
              y={15}
              fontSize={fontSize}
              fill={fontColor}
            />
            {!boardState.gameState.game_over &&
            boardState.gameState.current_dice !== null ? (
              <DicePanel
                right={Player.WHITE_PLAYER === boardState.current_player}
                dice={boardState.gameState.current_dice}
                dice_needed_steps={boardState.dice_needed_steps}
                available_needed_steps={boardState.available_needed_steps}
                needed_steps={boardState.needed_steps}
                current_player_is_ai={boardState.gameState.current_player_is_ai}
                doMovesCallback={doMovesCallback}
              ></DicePanel>
            ) : null}

            <Text
              x={220}
              y={762}
              text={LocaleStringConsts.AI_TIME_STRING}
              fontSize={18}
              fill={"white"}
              width={130}
              height={10}
              align={"center"}
              verticalAlign={"middle"}
            />

            <Text
              x={100}
              y={762}
              text={aiTimeOut}
              fontSize={18}
              fill={"white"}
              width={60}
              height={10}
              align={"center"}
              verticalAlign={"middle"}
            />

            <Group
              onClick={() => {
                changeAIMoveTime(-100);
              }}
              onTap={() => {
                changeAIMoveTime(-100);
              }}
            >
              <Rect
                x={65}
                y={750}
                width={35}
                height={35}
                fill={"black"}
                opacity={0.7}
                cornerRadius={10}
              />
              <Text
                x={65}
                y={750}
                width={35}
                height={35}
                text={"-"}
                fontSize={18}
                fill={"white"}
                align={"center"}
                verticalAlign={"middle"}
              />
            </Group>
            <Group
              onClick={() => {
                changeAIMoveTime(100);
              }}
              onTap={() => {
                changeAIMoveTime(100);
              }}
            >
              <Rect
                x={165}
                y={750}
                width={35}
                height={35}
                fill={"black"}
                opacity={0.7}
                cornerRadius={10}
              />
              <Text
                x={165}
                y={750}
                width={35}
                height={35}
                text={"+"}
                fontSize={18}
                fill={"white"}
                align={"center"}
                verticalAlign={"middle"}
              />
            </Group>
          </Group>
        </Layer>
        <Layer>
          {boardState.checkers_list.map((checker, index) => (
            <Checker
              data={checker}
              dimensions={dimensions}
              updatePosition={createUpdatePositionCallback}
              checkerSelected={checkerSelected}
            />
          ))}
          {selectedChecker ? drawMoveTips() : null}
        </Layer>
        <Layer>
          {!boardState.gameState.game_over &&
          boardState.gameState.current_dice === null ? (
            <FullBoardMessage
              message={LocaleStringConsts.START_GAME_STRING}
              onClick={doChoosePlayersSide}
              onTap={doChoosePlayersSide}
            ></FullBoardMessage>
          ) : null}

          {boardState.gameState.current_player_is_ai &&
          !boardState.gameState.game_over ? (
            <FullBoardMessage
              message={LocaleStringConsts.AI_MOVE_STRING}
            ></FullBoardMessage>
          ) : null}

          {boardState.gameState.game_over ? (
            <FullBoardMessage
              message={LocaleStringConsts.GAME_OVER_STRING}
            ></FullBoardMessage>
          ) : null}
        </Layer>
      </Stage>
    </GameBoardContainer>
  );
};

export default GameBoard;
