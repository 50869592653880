import React, { useEffect, useRef } from "react";
import { Group, Rect, Line } from "react-konva";

export var SEPARATE_CHECKERS_AMOUNT = 3;
export const sceneWidth = 870;
export const sceneHeight = 800;
export const radius = 25;

export function getPositionFromIndex(
  posIndex,
  posCount,
  radius,
  is_white_player
) {
  if (posIndex === 24) {
    if (is_white_player) {
      return { x: 837.5, y: 400 };
    } else {
      return { x: 32.5, y: 400 };
    }
  }

  var ret = { x: 0, y: 0 };

  var D = 2 * radius;
  var margin = 1.1;
  var mD = margin * D;

  var cornerShift = (1.3 + 0.55) * D;
  var barShift = 1.6 * D;
  var stackShift = 0.072;
  var opositeShift = 14.15 * D;

  if (posIndex < 12) {
    if (posIndex < 6) {
      ret.x = cornerShift + barShift + (11 - posIndex) * mD;
    } else {
      ret.x = cornerShift + (11 - posIndex) * mD;
    }
    if (posCount < SEPARATE_CHECKERS_AMOUNT) {
      ret.y = cornerShift + posCount * mD;
    } else {
      ret.y =
        cornerShift +
        (SEPARATE_CHECKERS_AMOUNT -
          stackShift * (posCount - SEPARATE_CHECKERS_AMOUNT)) *
          mD;
    }
  } else {
    if (posIndex >= 18) {
      ret.x = cornerShift + barShift + (posIndex - 12) * mD;
    } else {
      ret.x = cornerShift + (posIndex - 12) * mD;
    }
    if (posCount < SEPARATE_CHECKERS_AMOUNT) {
      ret.y = opositeShift - posCount * mD;
    } else {
      ret.y =
        opositeShift -
        (SEPARATE_CHECKERS_AMOUNT +
          stackShift * (posCount - SEPARATE_CHECKERS_AMOUNT)) *
          mD;
    }
  }

  return ret;
}

export function getIndexesFromPosition(
  x,
  y,
  radius,
  posIndex,
  posCount,
  put_places,
  targets,
  backwards,
  is_white_player
) {
  let ret = { positionIndex: -1, positionCount: -1 };
  let min_dist = Number.MAX_VALUE;
  [].concat(targets, backwards).forEach((pos) => {
    if (pos in put_places || pos >= 24) {
      let place = put_places[pos];
      if (pos >= 24) place = 24;

      let target = getPositionFromIndex(pos, place, radius, is_white_player);
      let dist =
        (x - target.x) * (x - target.x) + (y - target.y) * (y - target.y);
      if (dist < min_dist) {
        min_dist = dist;
        ret.positionIndex = pos;
        ret.positionCount = place;
      }
    }
  });

  if (ret.positionCount !== -1 && ret.positionCount !== -1) {
    let target = getPositionFromIndex(posIndex, ret.positionCount, radius);
    let dist =
      (x - target.x) * (x - target.x) + (y - target.y) * (y - target.y);
    if (dist < min_dist) {
      min_dist = dist;
      ret.positionIndex = posIndex;
      ret.positionCount = posCount;
    }
  }
  return ret;
}

// const backgroundColor = "Sienna";
const halfColor = "Wheat";
const trG = "Gold";
const trM = "Moccasin";
const trL = "LightSalmon";
const trD = "DarkRed";

const backgroundColor = "#808080";
// const halfColor = "#EFEFEF";
// const trG = "#D9D9D9";
// const trM = "#4D4D4D";
// const trL = "#8C8C8C";
// const trD = "#606060";

export const GameBoardBackGround = ({ sceneWidth, sceneHeight }) => {
  const groupRef = useRef(null);

  useEffect(() => {
    groupRef.current.cache({ pixelRatio: 2, imageSmoothingEnabled: false });
  });
  return (
    <Group ref={groupRef}>
      <Rect
        x={0}
        y={0}
        width={sceneWidth}
        height={sceneHeight}
        fill={backgroundColor}
      />
      <Rect
        x={65}
        y={65}
        width={330}
        height={670}
        fill={halfColor}
        shadowColor="black"
        shadowBlur={10}
        shadowOpacity={1.0}
        shadowOffsetX={0}
        shadowOffsetY={0}
      ></Rect>
      <Rect
        x={475}
        y={65}
        width={330}
        height={670}
        fill={halfColor}
        shadowColor="black"
        shadowBlur={10}
        shadowOpacity={1.0}
        shadowOffsetX={0}
        shadowOffsetY={0}
      ></Rect>
      {[...Array(6)].map((_, i) => (
        <Line
          x={65 + i * 55}
          y={65}
          points={[0, 0, 55, 0, 27.5, 300, 0, 0]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -400 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 1000 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 === 0 ? trG : trM,
            1,
            i % 2 === 0 ? trL : trD,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={65 + i * 55}
          y={65}
          points={[10, 0, 45, 0, 27.5, 200, 10, 0]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -400 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 1000 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 !== 0 ? trG : trM,
            1,
            i % 2 !== 0 ? trL : trD,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={475 + i * 55}
          y={65}
          points={[0, 0, 55, 0, 27.5, 300, 0, 0]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -400 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 1000 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 === 0 ? trG : trM,
            1,
            i % 2 === 0 ? trL : trD,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={475 + i * 55}
          y={65}
          points={[10, 0, 45, 0, 27.5, 200, 10, 0]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -400 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 1000 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 !== 0 ? trG : trM,
            1,
            i % 2 !== 0 ? trL : trD,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={65 + i * 55}
          y={435}
          points={[0, 300, 55, 300, 27.5, 0, 0, 300]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -700 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 700 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 === 0 ? trD : trL,
            1,
            i % 2 === 0 ? trM : trG,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={65 + i * 55}
          y={535}
          points={[10, 200, 45, 200, 27.5, 10, 10, 200]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -400 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 1000 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 === 0 ? trG : trM,
            1,
            i % 2 === 0 ? trL : trD,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={475 + i * 55}
          y={435}
          points={[0, 300, 55, 300, 27.5, 0, 0, 300]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -700 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 700 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 === 0 ? trD : trL,
            1,
            i % 2 === 0 ? trM : trG,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
      {[...Array(6)].map((_, i) => (
        <Line
          x={475 + i * 55}
          y={535}
          points={[10, 200, 45, 200, 27.5, 10, 10, 200]}
          closed
          stroke="black"
          strokeWidth={0.5}
          fillLinearGradientStartPoint={{ x: 27.5, y: -400 }}
          fillLinearGradientEndPoint={{ x: 27.5, y: 1000 }}
          fillLinearGradientColorStops={[
            0,
            i % 2 === 0 ? trG : trM,
            1,
            i % 2 === 0 ? trL : trD,
          ]}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.5}
          shadowOffsetX={0}
          shadowOffsetY={0}
        ></Line>
      ))}
    </Group>
  );
};
export default GameBoardBackGround;
