import {
  getPositionFromIndex,
  SEPARATE_CHECKERS_AMOUNT,
  radius,
} from "./GameBoardBackGround";
import { Player } from "../nardy/player";
import { FieldState, GameUtils, FIELD_SIZE, FIELD_HALF } from "../nardy/nardy";

export class BoardState {
  constructor(gameState, field, current_player, first_step) {
    this.gameState = gameState;
    this.checkers_list = [];
    this.checkers_map = {};
    this.put_places = {};

    this.can_take_head = true;
    this.first_step = first_step;
    this.current_player = current_player;

    this.movable_front_chekers = {};
    this.movable_back_chekers = {};
    this.possible_moves = [];

    this.dice_needed_steps = [];
    this.available_needed_steps = [];

    this.needed_steps = [];

    this.field = field;
    this.done_moves = [];
    this.undo_moves = [];

    this.selected_checker = null;
  }

  generatePossibleMoves() {
    let field_raw_data = this.field.getDataForPlayer(this.current_player);

    for (let posIndex = 0; posIndex < FIELD_SIZE; posIndex++) {
      if (field_raw_data[posIndex] <= 0) continue;

      const add_possible_moves = (nm) => {
        this.possible_moves.push(
          ...GameUtils.generateAllPossibleMovesForNeededStepsFromOrigin(
            this.current_player,
            this.field,
            posIndex,
            nm,
            this.can_take_head,
            this.first_step
          )
        );
      };

      switch (this.needed_steps.length) {
        case 1:
          add_possible_moves(this.needed_steps);
          break;
        /*************************************/
        case 2:
          if (this.needed_steps[0] !== this.needed_steps[1]) {
            add_possible_moves(this.needed_steps);
            add_possible_moves([this.needed_steps[1], this.needed_steps[0]]);
            add_possible_moves([this.needed_steps[0]]);
            add_possible_moves([this.needed_steps[1]]);

            /*************************************/
          } else {
            /*************************************/
            add_possible_moves(this.needed_steps);
            add_possible_moves([this.needed_steps[0]]);
          }
          break;
        /*************************************/
        case 3:
          add_possible_moves(this.needed_steps);
          add_possible_moves(this.needed_steps.slice(1));
          add_possible_moves(this.needed_steps.slice(2));
          break;
        /*************************************/
        case 4:
          add_possible_moves(this.needed_steps);
          add_possible_moves(this.needed_steps.slice(1));
          add_possible_moves(this.needed_steps.slice(2));
          add_possible_moves(this.needed_steps.slice(3));
          break;
        /*************************************/
        default:
          break;
        /*************************************/
      }
    }
  }

  generateData() {
    this.generatePossibleMoves();

    this.possible_moves.forEach((pm) => {
      for (let end = 0; end < pm.moves.length; end++) {
        let m = pm.moves[end];
        let origin = pm.moves[0].origin;
        let target = m.target;

        if (Player.BLACK_PLAYER === this.current_player) {
          origin = (origin + FIELD_HALF) % FIELD_SIZE;
          if (target < FIELD_SIZE) target = (target + FIELD_HALF) % FIELD_SIZE;
        }

        if (target > FIELD_SIZE) target = FIELD_SIZE;

        if (!(origin in this.movable_front_chekers)) {
          this.movable_front_chekers[origin] = {};
        }

        this.movable_front_chekers[origin][target] = pm.moves.slice(0, end + 1);
      }
    });

    this.undo_moves.forEach((um) => {
      for (let end = 0; end < um.length; end++) {
        let m = um[end];
        let origin = um[0].origin;
        let target = m.target;

        if (Player.BLACK_PLAYER === this.current_player) {
          if (origin < FIELD_SIZE) origin = (origin + FIELD_HALF) % FIELD_SIZE;
          target = (target + FIELD_HALF) % FIELD_SIZE;
        }
        if (origin > FIELD_SIZE) origin = FIELD_SIZE;

        if (!(origin in this.movable_back_chekers)) {
          this.movable_back_chekers[origin] = {};
        }

        this.movable_back_chekers[origin][target] = um.slice(0, end + 1);
      }
    });

    if (this.field.canDoOutStep(this.current_player))
      this.put_places[FIELD_SIZE] = 0;

    this.field
      .getDataForPlayer(Player.WHITE_PLAYER)
      .forEach((count, posIndex) => {
        const white = count > 0;
        const color = white ? "#F0F0F0" : "#484848";
        const absCount = Math.abs(count);
        this.put_places[posIndex] = absCount;

        for (let posCount = 0; posCount < absCount; posCount++) {
          let last_checker = posCount === absCount - 1;
          let draggable =
            last_checker &&
            (posIndex in this.movable_front_chekers ||
              posIndex in this.movable_back_chekers);

          let checker = {
            id: `${color}-${posIndex}-${posCount}`,
            color: color,
            white: white,
            posIndex: posIndex,
            posCount: posCount,
            draggable: draggable, // true,
            selected: false,
            coord: getPositionFromIndex(posIndex, posCount, radius),
            radius: radius,
            targets:
              posIndex in this.movable_front_chekers
                ? this.movable_front_chekers[posIndex]
                : {},
            backwards:
              posIndex in this.movable_back_chekers
                ? this.movable_back_chekers[posIndex]
                : {},
            count:
              last_checker && absCount - SEPARATE_CHECKERS_AMOUNT > 1
                ? absCount - SEPARATE_CHECKERS_AMOUNT
                : 0,
          };

          this.checkers_list.push(checker);
        }
      });

    if (
      this.field.getOutBoardChipsCounterForPlayer(Player.WHITE_PLAYER) !== 0
    ) {
      const count = this.field.getOutBoardChipsCounterForPlayer(
        Player.WHITE_PLAYER
      );
      const color = "#F0F0F0";
      let draggable =
        Player.WHITE_PLAYER === this.current_player &&
        (FIELD_SIZE in this.movable_front_chekers ||
          FIELD_SIZE in this.movable_back_chekers);
      let checker = {
        id: `${color}-${FIELD_SIZE}-${count}`,
        color: color,
        white: true,
        posIndex: FIELD_SIZE,
        posCount: 0,
        draggable: draggable, // true,
        selected: false,
        coord: getPositionFromIndex(FIELD_SIZE, 0, radius, true),
        radius: radius,
        targets:
          FIELD_SIZE in this.movable_front_chekers
            ? this.movable_front_chekers[FIELD_SIZE]
            : {},
        backwards:
          FIELD_SIZE in this.movable_back_chekers
            ? this.movable_back_chekers[FIELD_SIZE]
            : {},
        count: count,
      };

      this.checkers_list.push(checker);
    }

    if (
      this.field.getOutBoardChipsCounterForPlayer(Player.BLACK_PLAYER) !== 0
    ) {
      const count = this.field.getOutBoardChipsCounterForPlayer(
        Player.BLACK_PLAYER
      );
      const color = "#484848";
      let draggable =
        Player.BLACK_PLAYER === this.current_player &&
        (FIELD_SIZE in this.movable_front_chekers ||
          FIELD_SIZE in this.movable_back_chekers);
      let checker = {
        id: `${color}-${FIELD_SIZE}-${count}`,
        color: color,
        white: false,
        posIndex: FIELD_SIZE,
        posCount: 0,
        draggable: draggable, // true,
        selected: false,
        coord: getPositionFromIndex(FIELD_SIZE, 0, radius, false),
        radius: radius,
        targets:
          FIELD_SIZE in this.movable_front_chekers
            ? this.movable_front_chekers[FIELD_SIZE]
            : {},
        backwards:
          FIELD_SIZE in this.movable_back_chekers
            ? this.movable_back_chekers[FIELD_SIZE]
            : {},
        count: count,
      };

      this.checkers_list.push(checker);
    }
  }

  static createBoardStateFromGameState(gameState) {
    const ret = new BoardState(
      gameState,
      FieldState.copy(gameState.field),
      gameState.current_player,
      gameState.move_counter < 2
    );

    ret.dice_needed_steps = GameUtils.getNeededStepsFromDice(
      gameState.current_dice
    );
    ret.available_needed_steps = GameUtils.getNeededStepsFromPossibleMoves(
      gameState.current_possible_moves
    );

    ret.needed_steps = [...ret.available_needed_steps];

    ret.generateData();

    return ret;
  }

  static createNewBoardStateByPrevStateAndFrontMoves(prev, moves) {
    const ret = new BoardState(
      prev.gameState,
      FieldState.copy(prev.field),
      prev.current_player,
      prev.first_step
    );

    ret.done_moves.push(...prev.done_moves);

    ret.dice_needed_steps = [...prev.dice_needed_steps];
    ret.available_needed_steps = [...prev.available_needed_steps];

    ret.needed_steps = [...prev.needed_steps];
    moves.forEach((move) => {
      ret.needed_steps.splice(ret.needed_steps.indexOf(move.step), 1);
    });

    ret.field.doMoves(moves);
    ret.done_moves.push(...moves);

    let step_from_head_counter = 0;
    ret.done_moves.forEach((move) => {
      if (move.origin === 0) step_from_head_counter++;
    });

    ret.can_take_head = prev.can_take_head;
    if (step_from_head_counter > 0) {
      if (
        step_from_head_counter < 2 &&
        ret.gameState.move_counter < 2 &&
        ret.gameState.current_dice.one === ret.gameState.current_dice.two &&
        (ret.gameState.current_dice.one === 3 ||
          ret.gameState.current_dice.one === 4 ||
          ret.gameState.current_dice.one === 6)
      ) {
      } else {
        ret.can_take_head = false;
      }
    }

    let undo_moves = [];
    undo_moves.push(
      ...[...moves].reverse().map((m) => {
        return m.undoMove();
      })
    );

    let undo_target = moves[0].origin;
    let attached = false;
    prev.undo_moves.forEach((umb) => {
      if (!attached && umb[0].origin === undo_target) {
        undo_moves.push(...umb);
        ret.undo_moves.push(undo_moves);
        attached = true;
      } else {
        ret.undo_moves.push(umb);
      }
    });
    if (!attached) ret.undo_moves.push(undo_moves);

    // ret.undo_moves.push(...prev.undo_moves);

    ret.generateData();

    return ret;
  }

  static createNewBoardStateByPrevStateAndBackMoves(prev, moves) {
    const ret = new BoardState(
      prev.gameState,
      FieldState.copy(prev.field),
      prev.current_player,
      prev.first_step
    );

    ret.done_moves.push(...prev.done_moves);
    ret.undo_moves.push(...prev.undo_moves);

    ret.can_take_head = prev.can_take_head;

    ret.dice_needed_steps = [...prev.dice_needed_steps];
    ret.available_needed_steps = [...prev.available_needed_steps];

    ret.needed_steps = [...prev.needed_steps];
    moves.forEach((move) => {
      ret.needed_steps.push(move.step);

      if (move.target === 0) ret.can_take_head = true;

      let indexes_for_remove = [];
      let index_was_found = false;
      ret.done_moves.forEach((dm, index) => {
        if (
          !index_was_found &&
          move.step === dm.step &&
          move.origin === dm.target &&
          move.target === dm.origin
        ) {
          indexes_for_remove.push(index);
          index_was_found = true;
        }
      });
      indexes_for_remove.forEach((index) => {
        ret.done_moves.splice(index, 1);
      });

      let batch_indexes_for_remove = [];
      index_was_found = false;
      ret.undo_moves.forEach((umb, batch_index) => {
        indexes_for_remove.length = 0;
        umb.forEach((dm, index) => {
          if (
            !index_was_found &&
            move.step === dm.step &&
            move.origin === dm.origin &&
            move.target === dm.target
          ) {
            indexes_for_remove.push(index);
            index_was_found = true;
          }
        });
        indexes_for_remove.forEach((index) => {
          umb.splice(index, 1);
        });
        if (umb.length === 0) batch_indexes_for_remove.push(batch_index);
      });
      batch_indexes_for_remove.forEach((index) => {
        ret.undo_moves.splice(index, 1);
      });
    });

    // prev.undo_moves.forEach((umb) => {
    //   if (umb[0].origin === undo_target) {
    //     undo_moves.push(...umb);
    //     ret.undo_moves.push(undo_moves);
    //     attached = true;
    //   } else {
    //     ret.undo_moves.push(umb);
    //   }
    // });

    ret.field.doMoves(moves);

    ret.generateData();

    return ret;
  }
}

export default BoardState;
