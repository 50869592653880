import { Player } from "./player";
/*****************************************************************************/
export const FIELD_SIZE = 24;
export const FIELD_HALF = FIELD_SIZE / 2;
export const CAN_OUT_FROM = FIELD_SIZE - FIELD_SIZE / 4;
export const BLOCK_SIZE = 6;
export const MAX_CHIPS_NUMBER = 15;
export const START_CHIPS_NUMBER = 15;
export const START_WHITE_POSITION = 0;
export const START_BLACK_POSITION = 12;
export const BLACK_HOUSE_START = 6;
export const BLACK_HOUSE_END = 12;
/*****************************************************************************/
export class Move {
  constructor(player, origin, target, step) {
    this.player = player;
    this.origin = origin;
    this.target = target;
    this.step = step;
  }

  getPlayer() {
    return this.player;
  }

  getOrigin() {
    return this.origin;
  }

  getStep() {
    return this.step;
  }

  getTarget() {
    return this.target;
  }

  undoMove() {
    return new Move(this.player, this.target, this.origin, this.step);
  }

  toString() {
    return `${this.player} [${this.step}] ${this.origin} -> ${this.target}`;
  }

  toShortString() {
    return `[${this.step}: ${this.origin} -> ${this.target} ]`;
  }
}
/*****************************************************************************/
export function getBlocks(field_raw_data) {
  let blocks = null;
  let max_white_line = 0;
  for (let i = 0; i < field_raw_data.length * 2; i++) {
    let index = i % field_raw_data.length;
    if (field_raw_data[index] > 0) {
      max_white_line++;
    } else {
      if (max_white_line >= BLOCK_SIZE) {
        if (blocks == null) {
          blocks = {};
        }
        let start = index - max_white_line;
        if (start < 0) {
          start += FIELD_SIZE;
        }
        if (index in blocks) {
          delete blocks[index];
        }
        blocks[start] = index;
      }
      max_white_line = 0;
    }
  }
  return blocks;
}
export function predicateBlackInHouse(field_raw_data) {
  for (let i = BLACK_HOUSE_START; i < BLACK_HOUSE_END; i++) {
    if (field_raw_data[i] < 0) {
      return true;
    }
  }
  return false;
}
export function predicateBlackAfterWhiteBlock(field_raw_data, start, end) {
  if (start === BLACK_HOUSE_START) {
    return false;
  }
  if (predicateBlackInHouse(field_raw_data)) {
    return true;
  }
  let was_black_alter_block = false;
  if (start >= BLACK_HOUSE_END) {
    if (start < end) {
      for (let i = end; i < field_raw_data.length; i++) {
        if (field_raw_data[i] < 0) {
          was_black_alter_block = true;
          break;
        }
      }
    }

    for (let i = 0; i < BLACK_HOUSE_START; i++) {
      if (field_raw_data[i] < 0) {
        was_black_alter_block = true;
        break;
      }
    }
  }
  return was_black_alter_block;
}
/*****************************************************************************/
export class FieldData {
  constructor() {
    this.data = [];
    for (let i = 0; i < FIELD_SIZE; i++) this.data.push(0);
    this.chips_counter = 0;
    this.sum_to_end = 0;
    this.min_pos = 0;
    this.max_pos = 0;
    this.max_dist = 0;
    this.max_line = 0;
    this.has_block = 0;
    this.block_start_1 = 0;
    this.block_end_1 = 0;
    this.block_start_2 = 0;
    this.block_end_2 = 0;
    this.can_out = false;
  }

  static copy(base) {
    let ret = new FieldData();
    for (let i = 0; i < FIELD_SIZE; i++) ret.data[i] = base.data[i];
    ret.chips_counter = base.chips_counter;
    ret.sum_to_end = base.sum_to_end;
    ret.min_pos = base.min_pos;
    ret.max_pos = base.max_pos;
    ret.max_dist = base.max_dist;
    ret.max_line = base.max_line;
    ret.has_block = base.has_block;
    ret.block_start_1 = base.block_start_1;
    ret.block_end_1 = base.block_end_1;
    ret.block_start_2 = base.block_start_2;
    ret.block_end_2 = base.block_end_2;
    ret.can_out = base.can_out;

    return ret;
  }

  doMove(move, enemy_data) {
    if (move.origin < this.data.length) {
      this.data[move.origin] -= Player.WHITE_PLAYER.value;
      enemy_data.data[(move.origin + FIELD_HALF) % FIELD_SIZE] -=
        Player.BLACK_PLAYER.value;
    } else {
      this.chips_counter++;
      this.sum_to_end += FIELD_SIZE - move.origin;
    }

    if (move.target < this.data.length) {
      this.data[move.target] += Player.WHITE_PLAYER.value;
      enemy_data.data[(move.target + FIELD_HALF) % FIELD_SIZE] +=
        Player.BLACK_PLAYER.value;
      this.sum_to_end -= move.step;
    } else {
      this.chips_counter--;
      this.sum_to_end -= FIELD_SIZE - move.origin;
    }

    let last_self = -1;
    this.max_line = 0;
    let temp_max_line = 0;

    this.max_dist = 0;
    this.min_pos = FIELD_SIZE;
    this.max_pos = 0;
    for (let index = 0; index < this.data.length; index++) {
      if (this.data[index] > 0) {
        temp_max_line++;
        this.min_pos = Math.min(index, this.min_pos);
        this.max_pos = Math.max(index, this.max_pos);
        if (last_self === -1) {
          last_self = index;
        } else {
          this.max_dist = Math.max(index - last_self, this.max_dist);
          last_self = index;
        }
      } else {
        this.max_line = Math.max(this.max_line, temp_max_line);
        temp_max_line = 0;
      }
    }
    if (temp_max_line !== 0) {
      for (let index = 0; index < this.data.length; index++) {
        if (this.data[index] > 0) {
          temp_max_line++;
        } else {
          this.max_line = Math.max(this.max_line, temp_max_line);
          temp_max_line = 0;
        }
      }
    }
    this.max_line = Math.max(this.max_line, temp_max_line);

    this.can_out = this.min_pos >= CAN_OUT_FROM;

    if (this.max_line >= BLOCK_SIZE) {
      let self_blocks = getBlocks(this.data);
      if (self_blocks != null) {
        this.has_block = 1;

        let entries = Object.entries(self_blocks);

        this.block_start_1 = entries[0][0];
        this.block_end_1 = entries[0][1];

        if (entries.length > 1) {
          this.block_start_2 = entries[1][0];
          this.block_end_2 = entries[1][1];
        } else {
          this.block_start_2 = 0;
          this.block_end_2 = 0;
        }
      } else {
        // System.err.println("Strange situtaion with blocks " + this.toString());
      }
    } else {
      if (this.has_block === 1) {
        this.has_block = 0;
        this.block_start_1 = 0;
        this.block_end_1 = 0;
        this.block_start_2 = 0;
        this.block_end_2 = 0;
      }
    }
  }
}
export function FieldDataComparator(o1, o2) {
  for (let i = 0; i < o1.data.length; i++) {
    if (o1.data[i] > o2.data[i]) {
      return 1;
    }
    if (o1.data[i] < o2.data[i]) {
      return -1;
    }
  }
  return 0;
}
/*****************************************************************************/
export function predicateNewCheckAllWhiteBlocks(field_data) {
  if (field_data.has_block === 0) {
    return true;
  } else {
    let was_black_alter_all_blocks = true;

    let was_black_alter_block = predicateBlackAfterWhiteBlock(
      field_data.data,
      field_data.block_start_1,
      field_data.block_end_1
    );

    if (!was_black_alter_block) {
      return false;
    }

    if (field_data.block_start_2 !== field_data.block_end_2) {
      was_black_alter_block = predicateBlackAfterWhiteBlock(
        field_data.data,
        field_data.block_start_2,
        field_data.block_end_2
      );

      if (!was_black_alter_block) {
        return false;
      }
    }

    was_black_alter_all_blocks &&= was_black_alter_block;

    return was_black_alter_all_blocks;
  }
}
/*****************************************************************************/
export function createStartFieldData() {
  let white_data = new FieldData();
  white_data.data[START_WHITE_POSITION] =
    START_CHIPS_NUMBER * Player.WHITE_PLAYER.value;
  white_data.data[START_BLACK_POSITION] =
    START_CHIPS_NUMBER * Player.BLACK_PLAYER.value;

  white_data.chips_counter = START_CHIPS_NUMBER;
  white_data.sum_to_end = START_CHIPS_NUMBER * FIELD_SIZE;

  return white_data;
}
/*****************************************************************************/
export class PossibleMoves {
  constructor(moves, field) {
    this.moves = moves;
    this.field = field;
  }

  getField() {
    return this.field;
  }

  getMoves() {
    return this.moves;
  }

  toString() {
    let ret = "";
    for (let m of this.moves) {
      ret += m.toShortString();
    }
    return ret;
  }
}
/*****************************************************************************/
export class FieldState {
  constructor() {
    this.white_data = createStartFieldData();
    this.black_data = createStartFieldData();
  }

  static copy(base) {
    let ret = new FieldState();
    ret.white_data = FieldData.copy(base.white_data);
    ret.black_data = FieldData.copy(base.black_data);
    return ret;
  }

  getDataForPlayer(player) {
    if (Player.WHITE_PLAYER === player) {
      return this.white_data.data;
    }
    if (Player.BLACK_PLAYER === player) {
      return this.black_data.data;
    }
    return null;
  }

  getFieldDataForPlayer(player) {
    if (Player.WHITE_PLAYER === player) {
      return this.white_data;
    }
    if (Player.BLACK_PLAYER === player) {
      return this.black_data;
    }
    return null;
  }

  getChipsCounterForPlayer(player) {
    if (Player.WHITE_PLAYER === player) {
      return this.white_data.chips_counter;
    }
    if (Player.BLACK_PLAYER === player) {
      return this.black_data.chips_counter;
    }
    return 0;
  }

  getOutBoardChipsCounterForPlayer(player) {
    if (Player.WHITE_PLAYER === player) {
      return MAX_CHIPS_NUMBER - this.white_data.chips_counter;
    }
    if (Player.BLACK_PLAYER === player) {
      return MAX_CHIPS_NUMBER - this.black_data.chips_counter;
    }
    return 0;
  }

  canDoOutStep(player) {
    if (Player.WHITE_PLAYER === player) {
      return this.white_data.can_out;
    }
    if (Player.BLACK_PLAYER === player) {
      return this.black_data.can_out;
    }
    return false;
  }

  doMove(move) {
    if (Player.WHITE_PLAYER === move.player) {
      this.white_data.doMove(move, this.black_data);
    }
    if (Player.BLACK_PLAYER === move.player) {
      this.black_data.doMove(move, this.white_data);
    }
  }

  doMoves(moves) {
    for (let move of moves) {
      this.doMove(move);
    }
  }

  toString() {
    let ret = "";
    ret += "|\t-\t-\t-\t-\t-\t-\t-\t-\t-\t-\t-\t-\t|\n";
    for (let i = FIELD_SIZE - 1; i > FIELD_HALF - 1; i--) {
      ret += "\t" + this.white_data.data[i];
    }
    ret += "\n";
    for (let i = 0; i < FIELD_HALF; i++) {
      ret += "\t" + this.white_data.data[i];
    }
    ret.append("\n|\t-\t-\t-\t-\t-\t-\t-\t-\t-\t-\t-\t-\t|");
    return ret;
  }

  checkFieldState() {
    let white_chips_counter = 0;
    let white_sum_to_end = 0;

    let black_chips_counter = 0;
    let black_sum_to_end = 0;

    for (let i = 0; i < this.white_data.data.length; i++) {
      if (this.white_data.data[i] > 0) {
        white_chips_counter += this.white_data.data[i];
        white_sum_to_end +=
          this.white_data.data[i] * (this.white_data.data.length - i);
      }
    }

    for (let i = 0; i < this.black_data.data.length; i++) {
      if (this.black_data.data[i] > 0) {
        black_chips_counter += this.black_data.data[i];
        black_sum_to_end +=
          this.black_data.data[i] * (this.black_data.data.length - i);
      }
    }

    if (
      white_sum_to_end !== this.white_data.sum_to_end ||
      black_sum_to_end !== this.black_data.sum_to_end
    ) {
      throw Error("Wrong field state " + this.toString());
    }

    if (
      white_chips_counter !== this.white_data.chips_counter ||
      black_chips_counter !== this.black_data.chips_counter
    ) {
      throw new Error("Wrong field state " + this.toString());
    }

    for (let i = 0; i < this.white_data.data.length; i++) {
      if (
        this.white_data.data[i] !==
        -this.black_data.data[(i + FIELD_HALF) % FIELD_SIZE]
      ) {
        throw new Error("Wrong field state " + this.toString());
      }
    }
  }
}

export function FieldStateComparator(o1, o2) {
  for (let i = 0; i < o1.white_data.data.length; i++) {
    if (o1.white_data.data[i] > o2.white_data.data[i]) {
      return 1;
    }
    if (o1.white_data.data[i] < o2.white_data.data[i]) {
      return -1;
    }
  }
  return 0;
}
/*****************************************************************************/
export class GameUtils {
  static generateAllPossibleMovesForNeededStepsFromOrigin(
    player,
    field,
    origin,
    needed_steps,
    can_take_head,
    first_step
  ) {
    let results = [];
    let needed_step = needed_steps[0];
    let possible_origins = [];
    let started_out = field.canDoOutStep(player);
    let enemy_started_out = field.canDoOutStep(Player.getNextPlayer(player));

    let data = field.getDataForPlayer(player);

    for (let i = can_take_head ? 0 : 1; i < data.length; i++) {
      if (data[i] > 0) {
        if (i !== origin) continue;
        if (i + needed_step < data.length) {
          if (data[i + needed_step] >= 0) {
            possible_origins.push(i);
          }
        } else {
          if (started_out) {
            if (i + needed_step === data.length) {
              possible_origins.push(i);
            } else {
              let was_high_place = false;
              for (let j = 18; j < i; j++) {
                was_high_place ||= data[j] > 0;
              }
              if (!was_high_place) {
                possible_origins.push(i);
              }
            }
          }
        }
      }
    }
    for (let possible_origin of possible_origins) {
      let move = new Move(
        player,
        possible_origin,
        possible_origin + needed_step,
        needed_step
      );
      let temp_field = FieldState.copy(field);
      temp_field.doMove(move);

      if (
        !enemy_started_out &&
        !predicateNewCheckAllWhiteBlocks(
          temp_field.getFieldDataForPlayer(player)
        )
      ) {
        continue;
      }

      if (needed_steps.length === 1) {
        results.push(new PossibleMoves([move], temp_field));
      } else {
        let temp_needed_steps = needed_steps.slice(1, needed_steps.length);
        let temp_can_take_head = possible_origin !== 0 && can_take_head;
        if (first_step) {
          temp_can_take_head ||=
            needed_steps.length === 4 &&
            (needed_step === 3 || needed_step === 4);
          temp_can_take_head ||=
            needed_steps.length === 2 &&
            needed_steps[0] === 6 &&
            needed_steps[1] === 6;
        }
        let sub_result =
          GameUtils.generateAllPossibleMovesForNeededStepsFromOrigin(
            player,
            temp_field,
            move.target,
            temp_needed_steps,
            temp_can_take_head,
            first_step
          );
        for (let possible_move of sub_result) {
          // possible_move.moves.unshift(move);
          // possible_move.moves = [move, ...possible_move.moves];
          possible_move.moves = [move].concat(possible_move.moves);
          results.push(possible_move);
        }
      }
    }
    return results;
  }

  static generateAllPossibleMovesForNeededSteps(
    player,
    field,
    needed_steps,
    can_take_head,
    first_step
  ) {
    let results = [];
    let needed_step = needed_steps[0];
    let possible_origins = [];
    let started_out = field.canDoOutStep(player);
    let enemy_started_out = field.canDoOutStep(Player.getNextPlayer(player));

    let data = field.getDataForPlayer(player);

    for (let i = can_take_head ? 0 : 1; i < data.length; i++) {
      if (data[i] > 0) {
        if (i + needed_step < data.length) {
          if (data[i + needed_step] >= 0) {
            possible_origins.push(i);
          }
        } else {
          if (started_out) {
            if (i + needed_step === data.length) {
              possible_origins.push(i);
            } else {
              let was_high_place = false;
              for (let j = 18; j < i; j++) {
                was_high_place ||= data[j] > 0;
              }
              if (!was_high_place) {
                possible_origins.push(i);
              }
            }
          }
        }
      }
    }
    for (let possible_origin of possible_origins) {
      let move = new Move(
        player,
        possible_origin,
        possible_origin + needed_step,
        needed_step
      );
      let temp_field = FieldState.copy(field);
      temp_field.doMove(move);

      if (
        !enemy_started_out &&
        !predicateNewCheckAllWhiteBlocks(
          temp_field.getFieldDataForPlayer(player)
        )
      ) {
        continue;
      }

      if (needed_steps.length === 1) {
        results.push(new PossibleMoves([move], temp_field));
      } else {
        let temp_needed_steps = needed_steps.slice(1, needed_steps.length);
        let temp_can_take_head = possible_origin !== 0 && can_take_head;
        if (first_step) {
          temp_can_take_head ||=
            needed_steps.length === 4 &&
            (needed_step === 3 || needed_step === 4);
          temp_can_take_head ||=
            needed_steps.length === 2 &&
            needed_steps[0] === 6 &&
            needed_steps[1] === 6;
        }
        let sub_result = GameUtils.generateAllPossibleMovesForNeededSteps(
          player,
          temp_field,
          temp_needed_steps,
          temp_can_take_head,
          first_step
        );
        for (let possible_move of sub_result) {
          // possible_move.moves.unshift(move);
          // possible_move.moves = [move, ...possible_move.moves];
          possible_move.moves = [move].concat(possible_move.moves);
          results.push(possible_move);
        }
      }
    }
    return results;
  }

  static getNeededStepsFromDice(dice) {
    let needed_steps = [];
    if (dice !== null) {
      if (dice.one === dice.two) {
        needed_steps.push(dice.one);
        needed_steps.push(dice.one);
        needed_steps.push(dice.one);
        needed_steps.push(dice.one);
      } else {
        needed_steps.push(dice.one);
        needed_steps.push(dice.two);
      }
    }
    return needed_steps;
  }

  static getNeededStepsFromPossibleMoves(possible_moves) {
    let needed_steps = [];
    if (possible_moves.length > 0)
      possible_moves[0].moves.forEach((m) => {
        needed_steps.push(m.step);
      });
    return needed_steps;
  }

  static generateAllPossibleMovesForDice(
    player,
    field,
    dice,
    can_take_head,
    first_step
  ) {
    let needed_steps = GameUtils.getNeededStepsFromDice(dice);
    let temp_field = FieldState.copy(field);

    let possible_moves = [];
    if (needed_steps.length === 2) {
      possible_moves.push(
        ...GameUtils.generateAllPossibleMovesForNeededSteps(
          player,
          temp_field,
          [needed_steps[0], needed_steps[1]],
          can_take_head,
          first_step
        )
      );
      possible_moves.push(
        ...GameUtils.generateAllPossibleMovesForNeededSteps(
          player,
          temp_field,
          [needed_steps[1], needed_steps[0]],
          can_take_head,
          first_step
        )
      );
      if (possible_moves.length === 0) {
        //System.err.println("NO POSSIBLE MOVES TRY TO MAKE BIGGER STEP " + needed_moves.get(0));
        possible_moves.push(
          ...GameUtils.generateAllPossibleMovesForNeededSteps(
            player,
            temp_field,
            [Math.max(...needed_steps)],
            can_take_head,
            first_step
          )
        );
        if (possible_moves.length === 0) {
          //System.err.println("NO POSSIBLE MOVES TRY TO MAKE SMALLER STEP " + needed_moves.get(1));
          possible_moves.push(
            ...GameUtils.generateAllPossibleMovesForNeededSteps(
              player,
              temp_field,
              [Math.min(...needed_steps)],
              can_take_head,
              first_step
            )
          );
        }
      }
    }
    if (needed_steps.length === 4) {
      possible_moves.push(
        ...GameUtils.generateAllPossibleMovesForNeededSteps(
          player,
          temp_field,
          needed_steps,
          can_take_head,
          first_step
        )
      );
      if (possible_moves.length === 0) {
        //System.err.println("NO POSSIBLE MOVE IN DUBBLE TRY TO MAKE 3 STEP " + needed_moves.get(0));
        possible_moves.push(
          ...GameUtils.generateAllPossibleMovesForNeededSteps(
            player,
            temp_field,
            needed_steps.slice(0, 3),
            can_take_head,
            first_step
          )
        );
        if (possible_moves.length === 0) {
          //System.err.println("NO POSSIBLE MOVE IN DUBBLE TRY TO MAKE 2 STEP " + needed_moves.get(0));
          possible_moves.push(
            ...GameUtils.generateAllPossibleMovesForNeededSteps(
              player,
              temp_field,
              needed_steps.slice(0, 2),
              can_take_head,
              first_step
            )
          );
          if (possible_moves.length === 0) {
            //System.err.println("NO POSSIBLE MOVE IN DUBBLE TRY TO MAKE 1 STEP " + needed_moves.get(0));
            possible_moves.push(
              ...GameUtils.generateAllPossibleMovesForNeededSteps(
                player,
                temp_field,
                needed_steps.slice(0, 1),
                can_take_head,
                first_step
              )
            );
          }
        }
      }
    }
    return possible_moves;
  }

  static generateUniquePossibleMovesForDice(
    player,
    field,
    dice,
    can_take_head,
    first_step
  ) {
    let possible_moves = GameUtils.generateAllPossibleMovesForDice(
      player,
      field,
      dice,
      can_take_head,
      first_step
    );
    let unique_moves = {};

    possible_moves.forEach((pm) => {
      unique_moves[JSON.stringify(pm.field.white_data.data)] = pm;
    });

    return Object.values(unique_moves);
  }
}
/*****************************************************************************/
export default GameUtils;
/*****************************************************************************/
