import Long from "long";
/*****************************************************************************/
export class RepeattableRandomFactory {
  constructor(seed = 137) {
    this.next = Long.fromNumber(seed, false);
    this.S = Long.fromNumber(16, false);
    this.M = Long.fromNumber(1103515245, false);
    this.A = Long.fromNumber(12345, false);
    this.MAX = Long.fromNumber(32767 + 1, false);
  }

  rand(min = 0, max = 32768) {
    this.next = this.next.mul(this.M).add(this.A);

    if (this.next.lessThan(0)) {
      this.next = this.next.negate();
    }

    let ret = Number(this.next.shiftRight(this.S).modulo(this.MAX));

    return (ret % (max - min + 1)) + min;
  }
}
/*****************************************************************************/
export class DiceThrow {
  constructor(one, two) {
    if (one > two) {
      this.one = one;
      this.two = two;
    } else {
      this.one = two;
      this.two = one;
    }
  }

  getSum() {
    if (this.one === this.two) {
      return this.one * 4;
    } else {
      return this.one + this.two;
    }
  }

  toString() {
    return `[ ${this.one} , ${this.two} ]`;
  }
}
/*****************************************************************************/
export class RepeattableDiceFactory {
  constructor(seed) {
    this.rf = new RepeattableRandomFactory(seed);
    this.counter = 0;
  }

  getDiceThrow() {
    return new DiceThrow(this.rf.rand(1, 6), this.rf.rand(1, 6));

    // let d = this.rf.rand(1, 6);
    // return new DiceThrow(d, d);

    // if (this.counter++ === 0) return new DiceThrow(5, 5);
    // else return new DiceThrow(4, 4);

    // return new DiceThrow(1, 1);
    // return new DiceThrow(2, 2);
    // return new DiceThrow(3, 3);
    // return new DiceThrow(4, 4);
    // return new DiceThrow(5, 5);
    // return new DiceThrow(6, 6);
  }

  flipACoin() {
    return Math.round(Math.random());
  }
}
/*****************************************************************************/
export default RepeattableDiceFactory;
