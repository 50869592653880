import { RepeattableDiceFactory } from "./dicefactory";
import { Player } from "./player";
import { FieldState, GameUtils, FIELD_SIZE } from "./nardy";
import { NardsPlayers } from "./aiplayer";
/*****************************************************************************/
export class GameMatch {
  constructor(
    seed,
    white_player_name,
    white_player,
    black_player_name,
    black_player
  ) {
    this.df = new RepeattableDiceFactory(seed);
    this.field = new FieldState();
    this.current_dice = null;
    this.current_player = Player.WHITE_PLAYER;
    this.move_counter = 0;
    this.white_player = white_player_name;
    this.black_player = black_player_name;

    this.white_nards_player = white_player;
    this.black_nards_player = black_player;

    this.dice_sum_for_white = 0;
    this.dice_sum_for_black = 0;

    this.steps_lost_for_white = 0;
    this.steps_lost_for_black = 0;

    this.steps_done_for_white = 0;
    this.steps_done_for_black = 0;

    this.ai_automove_enabled = false;

    this.game_over = false;

    this.current_possible_moves = [];
  }

  getNardsPlayer(player) {
    if (Player.WHITE_PLAYER === player) {
      return this.white_nards_player;
    }
    if (Player.BLACK_PLAYER === player) {
      return this.black_nards_player;
    }
    return null;
  }

  static newGame(player1_name, player1, player2_name, player2) {
    return new GameMatch(
      Math.floor(Math.random() * 32000),
      player1_name,
      player1,
      player2_name,
      player2
    );
  }

  choosePlayersSides() {
    if (this.current_dice == null) {
      if (this.df.flipACoin() !== 0) {
        [this.white_player, this.black_player] = [
          this.black_player,
          this.white_player,
        ];
        [this.white_nards_player, this.black_nards_player] = [
          this.black_nards_player,
          this.white_nards_player,
        ];
      }
      this.current_dice = this.df.getDiceThrow();
      this.current_possible_moves =
        GameUtils.generateUniquePossibleMovesForDice(
          this.current_player,
          this.field,
          this.current_dice,
          true,
          this.move_counter < 2
        );
    }
  }

  stayPlayersSides() {
    if (this.current_dice == null) {
      this.current_dice = this.df.getDiceThrow();
      this.current_possible_moves =
        GameUtils.generateUniquePossibleMovesForDice(
          this.current_player,
          this.field,
          this.current_dice,
          true,
          this.move_counter < 2
        );
    }
  }

  turnOnAI() {
    this.ai_automove_enabled = true;
  }

  doMovesByIndex(index) {
    if (this.current_possible_moves.length > 0)
      this.field.doMoves(this.current_possible_moves[index].moves);

    if (Player.WHITE_PLAYER === this.current_player) {
      this.dice_sum_for_white += this.current_dice.getSum();
      let done_steps = 0;
      if (this.current_possible_moves.length > 0)
        this.current_possible_moves[index].moves.forEach((step) => {
          done_steps += Math.min(FIELD_SIZE, step.target) - step.origin;
        });
      this.steps_done_for_white += done_steps;
      this.steps_lost_for_white += this.current_dice.getSum() - done_steps;
    }
    if (Player.BLACK_PLAYER === this.current_player) {
      this.dice_sum_for_black += this.current_dice.getSum();
      let done_steps = 0;
      if (this.current_possible_moves.length > 0)
        this.current_possible_moves[index].moves.forEach((step) => {
          done_steps += Math.min(FIELD_SIZE, step.target) - step.origin;
        });
      this.steps_done_for_black += done_steps;
      this.steps_lost_for_black += this.current_dice.getSum() - done_steps;
    }

    if (
      Player.BLACK_PLAYER === this.current_player &&
      this.field.getChipsCounterForPlayer(this.current_player) === 0
    ) {
      this.game_over = true;
    }

    this.current_dice = this.df.getDiceThrow();
    this.move_counter++;
    this.current_player = Player.getNextPlayer(this.current_player);

    this.current_possible_moves = GameUtils.generateUniquePossibleMovesForDice(
      this.current_player,
      this.field,
      this.current_dice,
      true,
      this.move_counter < 2
    );

    if (
      Player.WHITE_PLAYER === this.current_player &&
      this.field.getChipsCounterForPlayer(this.current_player) === 0
    ) {
      this.game_over = true;
    }
  }

  doMoves(moves) {
    this.field.doMoves(moves);

    if (Player.WHITE_PLAYER === this.current_player) {
      this.dice_sum_for_white += this.current_dice.getSum();
      let done_steps = 0;
      moves.forEach((step) => {
        done_steps += Math.min(FIELD_SIZE, step.target) - step.origin;
      });
      this.steps_done_for_white += done_steps;
      this.steps_lost_for_white += this.current_dice.getSum() - done_steps;
    }
    if (Player.BLACK_PLAYER === this.current_player) {
      this.dice_sum_for_black += this.current_dice.getSum();
      let done_steps = 0;
      moves.forEach((step) => {
        done_steps += Math.min(FIELD_SIZE, step.target) - step.origin;
      });
      this.steps_done_for_black += done_steps;
      this.steps_lost_for_black += this.current_dice.getSum() - done_steps;
    }

    if (
      Player.BLACK_PLAYER === this.current_player &&
      this.field.getChipsCounterForPlayer(this.current_player) === 0
    ) {
      this.game_over = true;
    }

    this.current_dice = this.df.getDiceThrow();
    this.move_counter++;
    this.current_player = Player.getNextPlayer(this.current_player);

    this.current_possible_moves = GameUtils.generateUniquePossibleMovesForDice(
      this.current_player,
      this.field,
      this.current_dice,
      true,
      this.move_counter < 2
    );

    if (
      Player.WHITE_PLAYER === this.current_player &&
      this.field.getChipsCounterForPlayer(this.current_player) === 0
    ) {
      this.game_over = true;
    }
  }

  getData() {
    return {
      players: [{ name: this.white_player }, { name: this.black_player }],
      history: [],
      field: FieldState.copy(this.field),
      field_raw_data: this.field.getDataForPlayer(Player.WHITE_PLAYER),
      current_dice: this.current_dice,
      current_player: this.current_player,
      current_player_is_ai:
        this.getNardsPlayer(this.current_player) !== NardsPlayers.HUMAN &&
        this.ai_automove_enabled,
      current_possible_moves: this.current_possible_moves,
      move_counter: this.move_counter,
      game_over: this.game_over,
      dice_sum_for_white: this.dice_sum_for_white,
      dice_sum_for_black: this.dice_sum_for_black,
      steps_lost_for_white: this.steps_lost_for_white,
      steps_lost_for_black: this.steps_lost_for_black,
      steps_done_for_white: this.steps_done_for_white,
      steps_done_for_black: this.steps_done_for_black,
    };
  }
}

/*****************************************************************************/
export default GameMatch;
/*****************************************************************************/
