import LocaleStringConsts from "./LocaleStringConsts";

import React, { useEffect, useRef } from "react";
import { Circle, Group, Rect, Text } from "react-konva";

const DicePoints = ({ number, dsize }) => {
  let r = 5;
  switch (number) {
    case 1:
      return (
        <Group>
          <Circle radius={r} fill={"black"} x={dsize / 2} y={dsize / 2} />
        </Group>
      );
    case 2:
      return (
        <Group>
          <Circle radius={r} fill={"black"} x={(dsize * 3) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={dsize / 4} y={(dsize * 3) / 4} />
        </Group>
      );
    case 3:
      return (
        <Group>
          <Circle radius={r} fill={"black"} x={dsize / 2} y={dsize / 2} />
          <Circle radius={r} fill={"black"} x={(dsize * 3) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={dsize / 4} y={(dsize * 3) / 4} />
        </Group>
      );
    case 4:
      return (
        <Group>
          <Circle radius={r} fill={"black"} x={(dsize * 3) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={(dsize * 1) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={dsize / 4} y={(dsize * 3) / 4} />
          <Circle
            radius={r}
            fill={"black"}
            x={(dsize * 3) / 4}
            y={(dsize * 3) / 4}
          />
        </Group>
      );
    case 5:
      return (
        <Group>
          <Circle radius={r} fill={"black"} x={dsize / 2} y={dsize / 2} />
          <Circle radius={r} fill={"black"} x={(dsize * 3) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={(dsize * 1) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={dsize / 4} y={(dsize * 3) / 4} />
          <Circle
            radius={r}
            fill={"black"}
            x={(dsize * 3) / 4}
            y={(dsize * 3) / 4}
          />
        </Group>
      );
    case 6:
      return (
        <Group>
          <Circle radius={r} fill={"black"} x={(dsize * 3) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={(dsize * 2) / 4} y={dsize / 4} />
          <Circle radius={r} fill={"black"} x={(dsize * 1) / 4} y={dsize / 4} />
          <Circle
            radius={r}
            fill={"black"}
            x={(dsize * 3) / 4}
            y={(dsize * 3) / 4}
          />
          <Circle
            radius={r}
            fill={"black"}
            x={(dsize * 2) / 4}
            y={(dsize * 3) / 4}
          />
          <Circle
            radius={r}
            fill={"black"}
            x={(dsize * 1) / 4}
            y={(dsize * 3) / 4}
          />
        </Group>
      );
    default:
      return null;
  }
};

const DiceValue = ({
  number,
  x,
  dsize,
  active = true,
  current_active = false,
}) => {
  return (
    <Group x={x + dsize * 0.05} y={dsize * 0.05}>
      <Rect
        width={dsize * 0.9}
        height={dsize * 0.9}
        fill={active ? (current_active ? "white" : "palegreen") : "lightcoral"}
        cornerRadius={10}
        shadowColor="black"
        shadowBlur={5}
        shadowOpacity={0.5}
        shadowOffsetX={0}
        shadowOffsetY={0}
      />
      <DicePoints number={number} dsize={dsize * 0.9} />
    </Group>
  );
};

function diceActivity(dice_needed_steps, available_needed_steps) {
  const to_remove = [...available_needed_steps];

  return dice_needed_steps.reduce((result, item) => {
    const index = to_remove.indexOf(item);
    if (index === -1) {
      result.push(false);
    } else {
      to_remove.splice(index, 1);
      result.push(true);
    }
    return result;
  }, []);
}

export const DicePanel = ({
  right,
  dice,
  dice_needed_steps,
  available_needed_steps,
  needed_steps,
  current_player_is_ai,
  doMovesCallback,
}) => {
  let count = dice_needed_steps.length;
  let dsize = 55;

  let active_dices = diceActivity(dice_needed_steps, available_needed_steps);
  let current_active_dices = diceActivity(dice_needed_steps, needed_steps);

  const groupRef = useRef(null);
  useEffect(() => {
    groupRef.current.clearCache();
    groupRef.current.cache({ pixelRatio: 2, imageSmoothingEnabled: false });
  });

  return (
    <Group x={(right ? 640 : 230) - dsize} y={400 - dsize * 0.5}>
      <Group ref={groupRef}>
        {dice_needed_steps.map((dv, index) => (
          <DiceValue
            number={dv}
            x={dsize * (dice_needed_steps.length - index) - count * dsize * 0.5}
            active={active_dices[index]}
            current_active={current_active_dices[index]}
            dsize={dsize}
          />
        ))}
      </Group>
      {needed_steps.length === 0 && dice !== null && !current_player_is_ai ? (
        <Group onClick={doMovesCallback} onTap={doMovesCallback}>
          <Rect
            x={-60 + (right ? -410 : 410)}
            y={-25}
            width={2 * dsize + 120}
            height={dsize + 50}
            fill={"black"}
            opacity={0.7}
            cornerRadius={10}
          />
          <Text
            x={-60 + (right ? -410 : 410)}
            y={10}
            text={LocaleStringConsts.FINISH_MOVE_STRING}
            fontSize={30}
            fill={"white"}
            width={2 * dsize + 120}
            align={"center"}
            verticalAlign={"middle"}
          />
        </Group>
      ) : null}
    </Group>
  );
};

export default DicePanel;
